import { Asset } from 'stellar-sdk';

// Some General API Things
export interface PagedList<T> {
	items: T[];
	pageNumber: number;
	totalPages: number;
	next?: string;
}

export interface Balance {
	assetId: string; // in the form assetCode:assetIssuer
	amount: number;
}

// Account Resource

export interface Account {
	accountId: string;
}

export enum PositionType {
	Borrow = 'borrow',
	Lend = 'lend',
}

export enum InterestType {
	Fixed = 'fixed',
	Float = 'float',
}

export interface Position {
	balanceId: string;
	assetId: string;
	type: PositionType;
	interestType: InterestType;
	initialBalance: number;
	accruedBalance: number;
	collateralBalance: number;
	timestamp: Date;
}

export interface AccountPositions {
	blockNumber: string;
	accountId: string;
	totalLent: number;
	totalBorrowed: number;
	totalCollateral: number;
	healthFactor: number;
	netApy: number;
	positions: PagedList<Position>;
}

export interface AccountGovernance {
	accountId: string;
	governanceTokens: Balance;
	availableVotes: number;
}

export interface ProposalVote {
	accountId: string;
	voted: boolean;
	amount: number;
	proposalId: string;
}

export interface AccountVotes {
	accountId: string;
	proposalVotes: PagedList<ProposalVote>;
}

export interface AccountFees {
	accountId: string;
	balance: Balance;
	lastUpdatedUtc?: Date;
	lastDepositAmount?: Balance;
	minDeposit: number;
}

// Governance

export enum ProposalType {
	SwapSigner = 'swap_signer',
	FreezeContract = 'freeze_contract',
	Xdr = 'governance',
}

export enum ProposalStatus {
	Active = 'active',
	Accepted = 'accepted',
	Rejected = 'rejected',
}

export interface Votes {
	for: number;
	against: number;
	total: number;
}

export interface Proposal {
	id: string;
	type: ProposalType;
	xdr: string;
	title: string;
	description: string;
	externalUrl: string;
	status: string;
	votes: Votes;
}

export interface Proposals {
	proposals: PagedList<Proposal>;
}

export interface ProposalUpdate {
	signedTx: string;
}

export interface ProposalPostBody extends ProposalUpdate {
	id: string;
	type: ProposalType;
	xdr: string;
	title: string;
	description: string;
	externalUrl: string;
}

export interface ProposalPutBody extends ProposalUpdate {
	title: string;
	description: string;
	externalUrl: string;
}

// Turrets

export interface Turret {
	endpoint: string;
	org: string;
	account: string;
	tier: number;
}

export interface Turrets {
	turrets: PagedList<Turret>;
}

// Contracts

export interface Contract {
	version: string;
	github: string;
	hash: string;
}

// Protocol Events
export interface ProtocolEvent {
	timestamp: Date;
	userPublicKey: string;
	type: ProtocolEventTypes;
}

export enum ProtocolEventTypes {
	Burn = 'TX_BURN',
	Governance = 'TX_GOVERNANCE',
	Liquidate = 'TX_LIQUIDATE',
	Mint = 'TX_MINT',
	Rate = 'TX_RATE',
	Repay = 'TX_REPAY',
	Claim = 'TX_CLAIM',
}

export interface BurnEvent extends ProtocolEvent {
	amount: string;
	poolTokenCode: string;
}

export interface GovernanceEvent extends ProtocolEvent {
	operationType: string;
	//newProposalAccountKeypair?: Keypair;
	proposalSequenceNum?: string;
	proposalXDR?: string;
	proposalAccount?: string;
	addSigner?: string;
	removeSigner?: string;
	Lock_Unlock_txFunction?: string;
}

export interface MintEvent extends ProtocolEvent {
	deposit?: Deposit;
	collateralizations?: Collateralization[]; //needs to come from parsing
	borrow?: Borrow; //needs to come from parsing
}

export interface Collateralization {
	poolAsset: Asset;
	amount: string;
}

export interface Borrow {
	asset: Asset;
	amount: string;
	interestType: InterestType;
}

export interface Deposit {
	claimableBalanceId: string;
	amountCollateral: string;
}

export interface RepayEvent extends ProtocolEvent {
	repayments: Repayment[];
	withdrawals: Withdrawal[];
	repayWithCollateral: boolean;
	acceptableSlippage: string;
}

export interface Repayment {
	asset: Asset;
	amount: string;
}

export interface Withdrawal {
	poolToken: Asset;
	amount: string;
}

export interface ContractResponse {
	accountId: string;
	contract: Contract;
	type: ProtocolEventTypes;
	xdr: string;
	cost: number;
	remainingfeeBalance: Balance;
}

// Market

export interface LendAsset {
	assetId: string;
	interestRate: number;
	tokenYield: number;
}

export interface LendMarket {
	lendAssets: PagedList<LendAsset>;
	blockNumber: string;
}

export interface AssetDetails {
	supply: number;
	borrowed: number;
	utilizationRate: number;
}

export interface LendAssetResponse extends LendAsset {
	details: AssetDetails;
	blockNumber: string;
}

export interface BorrowAsset {
	assetId: string;
	floatInterestRate: number;
	fixedInterestRate: number;
	tokenYield: number;
}

export interface BorrowMarket {
	borrowAssets: PagedList<BorrowAsset>;
	blockNumber: string;
}

export interface BorrowAssetResponse extends BorrowAsset {
	details: AssetDetails;
	blockNumber: string;
}
