/**
 * Utility functions to help build, manage, and display asset data
 */
import { POOL_ACCOUNT_ID } from 'config/constants';
import { Asset } from 'stellar-sdk';
import { PositionType } from 'types';

/**
 * Verify a given AssetId is a pool asset
 * @param assetId - The assetId to check
 * @param type - (OPTIONAL) The type of Pool Asset to match
 * @returns
 */
export function VerifyPoolAssetId(assetId: string, type?: PositionType): boolean {
	let poolAssetRegex = new RegExp(`^(y|l)[a-zA-Z0-9]{3,11}:${POOL_ACCOUNT_ID}$`);
	let passRegex = poolAssetRegex.test(assetId);
	if (type !== undefined) {
		return passRegex && (type === PositionType.Borrow ? assetId[0] === 'l' : assetId[0] === 'y');
	}
	return passRegex;
}

export function GetAssetFromId(assetId: string): Asset {
	if (assetId === 'XLM:native') {
		return Asset.native();
	}
	let assetIdCodes = assetId.split(':');
	return new Asset(assetIdCodes[0], assetIdCodes[1]);
}

export function GetIdFromAsset(asset: Asset): string {
	if (asset.isNative()) {
		return 'XLM:native';
	}
	return `${asset.code}:${asset.issuer}`;
}
