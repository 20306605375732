import {
	ProtocolEvent,
	Turret,
	YbxError
} from 'yieldblox-js';
import { ConfirmTransactionModal, TransactionFailModal, TransactionSuccessModal } from 'components';
import { BuildingTransactionModal } from 'components/modals/BuildingTransactionModal';
import { WalletConnectModal } from 'components/modals/WalletConnectModal';
import { useLocalStorageState } from 'hooks';
import React, { useContext, useEffect, useState } from 'react';
import { TxText, WalletType } from 'types';
import { stellarHelper } from 'utils/stellar';
import { albedo, freighter,} from 'utils/wallet';
import { useYieldBlox } from './yieldblox';
import { getTurretFeePaymentXdr } from 'utils/turret';

export interface IWalletContext {
	connected: boolean;
	walletAddress: Maybe<string>;
	walletType: Maybe<WalletType>;
	token: string;
	txStatus: TxStatus;
	acknowledge: boolean;
	setAcknowledge: (agree: boolean) => void;
	connect: () => void;
	disconnect: () => void;
	signTransaction: (xdr: string, txText: TxText) => Promise<Maybe<string>>;
	runContract: (request: ProtocolEvent, token?: string) => Promise<void>;
	submitTransaction: (xdr: string) => Promise<void>;
	fetchToken: () => Promise<Maybe<string>>;
	runTurretFeePayment: (amount: string, turret: Turret) => Promise<Maybe<number>>;
}

export enum TxStatus {
	None,
	Build,
	Confirm,
	Success,
	Submitted,
	Fail,
}

const WalletContext = React.createContext<Maybe<IWalletContext>>(undefined);

export const WalletProvider = ({ children = null as any }) => {
	const { contractHash, updateLedger, ybxTurretHelper } = useYieldBlox();
	const [showModal, setShowModal] = useState(false);
	const [connected, setConnected] = useState<boolean>(false);
	const [autoConnect, setAutoConnect] = useState(true);
	const [tokenExpire, setTokenExpire] = useState<Maybe<number>>(undefined);

	// wallet state
	const [walletAddress, setWalletAddress] = useState<Maybe<string>>(undefined);
	const [walletType, setWalletType] = useLocalStorageState('walletType');
	const [token, setToken] = useState<string>('');
	const [acknowledge, setAcknowledge] = useLocalStorageState('acknowledgeTos');

	// internal state
	const [txStatus, setTxStatus] = useState<TxStatus>(TxStatus.None);
	const [txHash, setTxHash] = useState<Maybe<string>>(undefined);
	const [xdr, setXdr] = useState<Maybe<string>>(undefined);
	const [errorMessage, setErrorMessage] = useState<Maybe<string>>(undefined);
	const [horizonResponseData, setHorizonResponseData] = useState<any>(undefined);
	const [txText, setTxText] = useState<TxText>(TxText.Default);

	useEffect(() => {
		if (autoConnect) {
			if (acknowledge && walletType) {
				connect(walletType as WalletType);
			}
			setAutoConnect(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [walletType, autoConnect]);

	useEffect(() => {
		if (txStatus === TxStatus.Submitted) {
			updateLedger();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [txStatus]);

	/**
	 * Connects a browser wallet by fetching the public key from the wallet.
	 * @param _walletType - The wallet we are trying to connect
	 * @returns The public key of the wallet
	 */
	async function connect(_walletType: WalletType) {
		try {
			if (!acknowledge) {
				throw new Error('Unable to connect wallet if terms and conditions were not set');
			}
			let address = undefined;
			switch (_walletType) {
				case WalletType.Freighter:
					address = await freighter.connect();
					break;
				case WalletType.Albedo:
					address = await albedo.connect();
					break;

				default:
					throw new Error('Invalid walletType');
			}
			updateLedger();
			setWalletAddress(address);
			setWalletType(_walletType);
			setConnected(true);
		} catch (e) {
			console.error(e);
			disconnect();
			throw e;
		}
	}

	function disconnect() {
		setWalletAddress(undefined);
		setWalletType(undefined);
		setConnected(false);
	}

	async function fetchToken(): Promise<Maybe<string>> {
		try {
			if (walletAddress) {
				console.log('Fetching token...');
				let _token = undefined;
				if (tokenExpire === undefined || tokenExpire < Date.now()) {
					let unsignedToken = stellarHelper.CreateUnsignedTokenXdr(walletAddress, contractHash);
					_token = await signTransaction(unsignedToken, TxText.Token);
					if (_token) {
						setToken(_token); // cache token
						setTokenExpire(Date.now() + (30 * 60 * 1000));
						setTxStatus(TxStatus.Success);
					} else {
						setTxStatus(TxStatus.Fail);
					}
				} else {
					_token = token;
				}
				return _token;
			}
			return undefined;
		} catch (e) {
			console.error(e);
			throw e;
		}
	}

	async function runContract(request: ProtocolEvent, token?: string) {
		try {
			let _token: Maybe<string>;
			if (token) {
				_token = token;
			} else {
				_token = await fetchToken();
			}

			// if no token was found, stop
			if (_token === undefined) {
				return;
			}

			setTxText(TxText.Contract);
			setTxStatus(TxStatus.Build);
			setHorizonResponseData(undefined);

			let contractResponse: Maybe<string>;
			let contractError: string = '';
			try {
				contractResponse = await ybxTurretHelper.runContract(request, _token);
			} catch (err: unknown) {
				contractError = processYbxError(err, 'Failed to execute transaction.');
				contractResponse = undefined;
			}

			if (contractResponse === undefined || contractResponse === '') {
				// no good! Fail!
				setXdr(undefined);
				setErrorMessage(contractError);
				setTxStatus(TxStatus.Fail);
				return;
			}
			setErrorMessage('');

			let signedXdr = await signTransaction(contractResponse, TxText.Contract);
			if (signedXdr) {
				await submitTransaction(signedXdr);
			}
		} catch (e) {
			console.error(e);
			throw e;
		}
	}

	function processHorizonError(err: any) {
		if (err instanceof Error) {
			setErrorMessage(err.message);
			if ('response' in err) {
				let resp = (err as any).response;
				console.log(resp);
				if (resp?.data?.extras?.result_codes) {
					setHorizonResponseData(resp?.data?.extras?.result_codes);
				} else {
					setHorizonResponseData(resp?.data);
				}
			}
		}
	}

	async function runTurretFeePayment(amount: string, turret: Turret): Promise<Maybe<number>> {
		try {
			if (walletAddress) {
				let { xdr: feeXdr, hash: feeHash } = await getTurretFeePaymentXdr(amount, turret, walletAddress);
				let signedXdr = await signTransaction(feeXdr, TxText.Default);
				setTxText(TxText.Default);
				setTxStatus(TxStatus.Build);
				setHorizonResponseData(undefined);
				let result: Maybe<number>;
				if (signedXdr) {
					try {
						result = await ybxTurretHelper.sendFeesToTurret(turret.turretId, walletAddress, signedXdr);
						setTxHash(feeHash);
						setTxStatus(TxStatus.Submitted);
					} catch (e) {
						setErrorMessage(processYbxError(e, 'Failed to submit fees.'));
						setXdr(feeXdr);
						setTxStatus(TxStatus.Fail);
						return result;
					}
				} else {
					setTxStatus(TxStatus.Fail);
				}
				return result;
			}
		} catch (e) {
			setTxStatus(TxStatus.Fail);
			processHorizonError(e);
			throw e;
		}
	}

	async function submitTransaction(xdr: string) {
		try {
			let hash = await stellarHelper.SubmitTransaction(xdr);
			setTxHash(hash);
			setTxStatus(TxStatus.Submitted);
		} catch (e: unknown) {
			setXdr(xdr);
			setTxStatus(TxStatus.Fail);
			processHorizonError(e);
		}
	}

	/**
	 * Signs a transaction with a connected wallet.
	 * @param xdr = The xdr-encoded transaction envelope to sign.
	 * @returns The signed_xdr of the transaction
	 */
	async function signTransaction(xdr: string, txText: TxText): Promise<Maybe<string>> {
		try {
			setTxText(txText);
			setTxStatus(TxStatus.Confirm);
			let res = '';
			switch (walletType) {
				case WalletType.Freighter:
					res = await freighter.sign(xdr);
					break;
				case WalletType.Albedo:
					res = await albedo.sign(xdr);
					break;
			}
			console.log('Fetched signature');
			return res;
		} catch (e: any) {
			console.error(e);
			setXdr(xdr);
			setTxStatus(TxStatus.Fail);
			setErrorMessage(e.message);
			return undefined;
		}
	}

	return (
		<WalletContext.Provider
			value={{
				connected,
				walletAddress,
				walletType,
				token,
				txStatus,
				acknowledge,
				setAcknowledge,
				connect: () => setShowModal(true),
				disconnect,
				signTransaction,
				runContract,
				submitTransaction,
				fetchToken,
				runTurretFeePayment,
			}}
		>
			{children}
			<WalletConnectModal open={showModal} onClose={() => setShowModal(false)} connect={connect} />
			<BuildingTransactionModal open={txStatus === TxStatus.Build} wallet={walletAddress ?? ''} />
			<ConfirmTransactionModal
				open={txStatus === TxStatus.Confirm}
				txText={txText}
				wallet={walletAddress ?? ''}
				onClose={() => setTxStatus(TxStatus.None)}
			/>
			<TransactionSuccessModal
				open={txStatus === TxStatus.Success || txStatus === TxStatus.Submitted}
				txHash={txHash}
				txText={txText}
				onClose={() => setTxStatus(TxStatus.None)}
			/>
			<TransactionFailModal
				open={txStatus === TxStatus.Fail}
				xdr={xdr}
				txText={txText}
				error={errorMessage}
				horizonData={horizonResponseData}
				onClose={() => setTxStatus(TxStatus.None)}
			/>
		</WalletContext.Provider>
	);
};

function processYbxError(err: any, defaultError: string): string {
	let contractError: string = defaultError || '';
	if (err instanceof Error) {
		if ('isYbxError' in err) {
			const ybxError: YbxError = (err as YbxError);
			contractError = 'ERROR: ';
			if (ybxError.responseError) {
				contractError += ybxError.responseError;
			}
			if (ybxError.turretRunResponse?.error) {
				contractError += ' | ' +
					ybxError.turretRunResponse.error;
			}
		} else {
			contractError = err.message;
		}
	}
	return contractError;
}

export const useWallet = () => {
	const context = useContext(WalletContext);

	if (!context) {
		throw new Error('Component rendered outside the provider tree');
	}

	return context;
};
