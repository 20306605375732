import React, { useState } from 'react';
import styled from 'styled-components';
import { BalanceLine, BurnEvent, POOL_ACCOUNT_1_ID } from 'yieldblox-js';
import { AssetIcon, Card, IModalProps, Input, Modal, ModalFooter, ModalHeader } from 'components';
import { TooltipButton } from 'components/common/Button/TooltipButton';
import { GreenText } from 'theme';
import { ConfirmActionModal } from './ConfirmActionModal';
import { PoolAssetData } from 'types';
import { usePrices, useUser, useWallet } from 'contexts';
import { getAdjDisplayBalance, getDisplayBalance, getDisplayHealthFactor, getDisplayRate } from 'utils';
import { mathHelper } from 'utils/protocol';
import { Lend, STELLAR_MIN } from 'yieldblox-js';
import { YBX_TOKEN } from 'config/constants';
import arrowIcon from 'assets/images/icn_arrow_right.svg';

const RowItem = styled.div`
	padding: 14px 40px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey3};
`;

const AssetNameWrapper = styled(RowItem)`
	display: flex;
	align-items: center;
`;

const RowDetail = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
`;

const DetailCard = styled(Card)`
	background: ${({ theme }) => theme.colors.light_grey1};
	padding: 12px 15px;
	display: flex;
	align-items: center;
	font-weight: 500;
	height: 64px;
`;

interface IProps extends IModalProps {
	position: Lend;
	pAssetData: PoolAssetData;
}

export const WithdrawModal: React.FC<IProps> = ({ open, position, pAssetData, onClose }) => {
	const { walletAddress, runContract } = useWallet();
	const { prices } = usePrices();
	const { healthFactor } = useUser();

	const positionValue = position?.balance ?? '0';
	const positionCollateral = position?.uncollateralizedBalance ?? '0';
	const posUnderlyingValue = parseFloat(positionValue) * pAssetData.yTokenRate;
	const posUncollateralValue = parseFloat(position?.uncollateralizedBalance ?? '0') * pAssetData.yTokenRate;
	const posTotalValue = posUnderlyingValue + posUncollateralValue;

	const assetCode = pAssetData.poolAsset.underlyingId.split(':')[0];

	const assetPrice = prices[pAssetData.poolAsset.underlyingId];
	const ybxPrice = prices[YBX_TOKEN];
	const tokenYield = mathHelper.CalculateTokenYield(
		pAssetData.yTokenIssuance,
		pAssetData.yTokenRate,
		assetPrice?.rate,
		ybxPrice?.rate ?? 1
	);
	const loanToValue = pAssetData.poolAsset.data.loanToValue;

	const [showConfirm, setShowConfirm] = useState(false);
	const [amount, setAmount] = useState('');
	const [collateralYTokenDelta, setCollateralYTokenDelta] = useState(0);
	const [collateralHealthDelta, setCollateralHealthDelta] = useState(0);

	const newHealthFactor = mathHelper.CalculateNewHealthFactor(healthFactor, collateralHealthDelta.toString(), '0');

	const handleClose = () => {
		setShowConfirm(false);
		setAmount('');
		if (onClose) {
			onClose();
		}
	};

	const handleMax = () => {
		handleSetAmount(mathHelper.stellarMinTruncate(posTotalValue));
	};

	const handleSetAmount = (amount: string) => {
		setAmount(amount);
		let yAmount = parseFloat(amount) / pAssetData.yTokenRate;

		let withdrawal = yAmount - Number(positionCollateral);
		if (withdrawal > parseFloat(STELLAR_MIN)) {
			setCollateralYTokenDelta(withdrawal);
			setCollateralHealthDelta(
				withdrawal * pAssetData.yTokenRate * pAssetData.poolAsset.data.loanToValue * assetPrice.rate * -1
			);
		} else {
			setCollateralYTokenDelta(0);
			setCollateralHealthDelta(0);
		}
	};

	const handleConfirm = async () => {
		processTransaction();
		handleClose();
	};

	const processTransaction = async () => {
		if (walletAddress) {
			let yAmount = parseFloat(amount) / pAssetData.yTokenRate;
			let withdrawalBLine: Maybe<BalanceLine>;
			if (collateralYTokenDelta) {
				withdrawalBLine = {
					amount: collateralYTokenDelta.toFixed(7),
					assetId: position.assetId,
				};
			}
			let burnBalLine: BalanceLine = {
				amount: yAmount.toFixed(7),
				assetId: position.assetId,
			};
			let burnEvent = new BurnEvent(walletAddress, POOL_ACCOUNT_1_ID, withdrawalBLine, burnBalLine);
			await runContract(burnEvent);
		}
	};

	const isValid = () => {
		if (isNaN(Number(amount)) || Number(amount) <= 0) {
			return false;
		}
		return Number(amount) <= Number(
			mathHelper.stellarMinTruncate(posTotalValue)
		);
	};

	return (
		<>
			<Modal open={open && !showConfirm} onClose={onClose} width={560}>
				<ModalHeader>Withdrawal</ModalHeader>

				<AssetNameWrapper>
					<AssetIcon assetCode={assetCode} />
					<h6 style={{ marginLeft: '1rem' }}>{assetCode}</h6>
				</AssetNameWrapper>

				<RowItem style={{ overflowX: 'auto' }}>
					<RowDetail>
						<DetailCard>
							<div>
								<GreenText>Interest</GreenText>
								<p>Rate</p>
							</div>

							<p style={{ marginLeft: 16 }}>
								{getDisplayRate(pAssetData.lendRate)}
								<GreenText>%</GreenText>
							</p>
						</DetailCard>

						<DetailCard>
							<div>
								<p>Token</p>
								<GreenText>Yield</GreenText>
							</div>

							<p style={{ marginLeft: 16 }}>
								{getDisplayRate(tokenYield)}
								<GreenText>%</GreenText>
							</p>
						</DetailCard>

						<DetailCard>
							<div>
								<GreenText>LTV</GreenText>
							</div>

							<p style={{ marginLeft: 16 }}>
								{getDisplayRate(loanToValue)}
								<GreenText>%</GreenText>
							</p>
						</DetailCard>
					</RowDetail>
				</RowItem>

				<RowItem>
					<RowDetail>
						<b>Current Deposit</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}>
							<b>{getDisplayBalance(posTotalValue, assetCode)}</b>
							<b>${getAdjDisplayBalance(posTotalValue, assetPrice?.rate)}</b>
						</div>
					</RowDetail>

					<RowDetail>
						<b>Amount Collateral</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}>
							<b>{getDisplayBalance(posUnderlyingValue, assetCode)}</b>
							<b>${getAdjDisplayBalance(posUnderlyingValue, assetPrice?.rate)}</b>
						</div>
					</RowDetail>

					<RowDetail>
						<GreenText>Available to withdraw</GreenText>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}>
							<GreenText>{getDisplayBalance(posTotalValue, assetCode)}</GreenText>
						</div>
					</RowDetail>
				</RowItem>

				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Withdrawal Amount</b>
						<Input
							type="number"
							min={0}
							value={amount}
							placeholder="--"
							style={{ width: 120 }}
							onChange={e => handleSetAmount(e.target.value)}
							buttonText="MAX"
							onButtonClick={handleMax}
						/>
					</RowDetail>

					<RowDetail>
						<b>Health Factor</b>
						<b>
							{getDisplayHealthFactor(healthFactor?.value)}
							<img src={arrowIcon} alt="" style={{ margin: '0 10px' }} />
							<GreenText>{getDisplayHealthFactor(newHealthFactor)}</GreenText>
						</b>
					</RowDetail>
				</RowItem>

				<ModalFooter>
					<TooltipButton
						variant="primary"
						onClick={() => setShowConfirm(true)}
						disabled={!isValid()}
						errorMessage="Cannot withdrawal more than total available"
					>
						Withdraw
					</TooltipButton>
				</ModalFooter>
			</Modal>

			<ConfirmActionModal
				type="Withdrawal"
				open={showConfirm}
				onClose={handleClose}
				onBack={() => setShowConfirm(false)}
				onConfirm={handleConfirm}
				assetId={pAssetData.poolAsset.underlyingId}
			>
				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Withdrawal Amount</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
							<b>{getDisplayBalance(Number(amount), assetCode)}</b>
							<p>${getAdjDisplayBalance(Number(amount), assetPrice?.rate)}</p>
						</div>
					</RowDetail>
				</RowItem>
			</ConfirmActionModal>
		</>
	);
};
