import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Card, IModalProps, Modal, ModalFooter, ModalHeader } from 'components';
import { useWallet } from 'contexts';

import faucetIcon from 'assets/images/faucetIcon.svg';

const RowItem = styled.div`
	padding: 14px 40px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey3};
`;

const Conditions = styled.div`
	padding: 14px 40px;
	background: ${({ theme }) => theme.colors.light_grey1};
	border-radius: 15px;
`;

const RowDetail = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
`;

const FaucetCircle = styled.div`
	display: flex;
	width: 30px;
	height 30px;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: rgba(68, 114, 255, 1);
	margin-right: 16px;

	img {
		width: 24px;
		height: 24px;
	}
`;

interface IProps extends IModalProps {}

export const TosModal: React.FC<IProps> = ({ open, onClose }) => {
	const { setAcknowledge } = useWallet();

	const [acknowledgeLocal, setAcknowledgeLocal] = useState(false);

	const handleClose = () => {
		if (acknowledgeLocal && onClose) {
			onClose();
		}
	};

	const handleAcknowledge = (approve: boolean) => {
		if (approve) {
			setAcknowledgeLocal(true);
			setAcknowledge(true);
			handleClose();
		} else {
			//
			setAcknowledgeLocal(false);
			setAcknowledge(false);
			window.open(`https://yieldblox.com`, '_self');
			window.close();
		}
	};

	return (
		<>
			<Modal open={open} onClose={handleClose} width={600}>
				<ModalHeader>YieldBlox Testnet</ModalHeader>

				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>This is the YieldBlox Protocol on a test network.</b>
					</RowDetail>
					{/*<RowDetail>
						<b>You hereby agree, warrant, and represent that:</b>
					</RowDetail>*/}
				</RowItem>

				<RowItem>
					<Conditions>
						<RowDetail>
							<p>
								Please be aware there may be bugs.
							</p>
						</RowDetail>
						<RowDetail>
							<p>
								If you come across a bug, please raise an issue on the YieldBlox Discord and/or GitHub.
							</p>
						</RowDetail>

						<RowDetail style={{marginTop: 30}}>
							<FaucetCircle>
								<img src={faucetIcon} alt="" />
							</FaucetCircle>
							<p style={{width: '90%', display: "inline-block"}}>
								To start, click the Faucet button to create a YieldBlox Testnet account.
							</p>
						</RowDetail>

					</Conditions>
				</RowItem>
				<ModalFooter>
					<Button variant="primary" onClick={() => handleAcknowledge(true)}>
						Okay
					</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};
