import React from 'react';
import styled from 'styled-components';
import { useWallet, useUser } from 'contexts';
import { Button, Card, SectionTitle, EscrowInfo } from 'components';
import { Grid } from '@material-ui/core';
import { GreenText } from 'theme';
import { Tooltip } from '@material-ui/core';

import ybxIcon from 'assets/images/icn_ybx.svg';
import questionIcon from 'assets/images/icn_question.png';
import goodHF from 'assets/images/goodHF.png';
import medHF from 'assets/images/medHF.png';
import badHF from 'assets/images/badHF.png';
import { getDisplayBalance, getDisplayRate, getFormattedNumber, getDisplayHealthFactor } from 'utils';

const NoConnectedCard = styled(Card)`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding-right: 20px;
	padding-right: 20px;
	background: linear-gradient(
		90deg,
		${({ theme }) => theme.colors.light_grey1} 0%,
		${({ theme }) => theme.colors.white} 100%
	);
`;

const NoConnectedDetail = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
	margin-right: 2rem;

	img {
		height: 60px;
		margin-right: 1rem;
	}

	b {
		font-size: 20px;
		line-height: 33px;
		font-weight: 500;
	}
`;

const DetailCard = styled(Card)`
	height: 110px;
	display: flex;
	align-items: center;
	font-size: 18px;
	line-height: 24px;

	h5 {
		margin-right: 5px;
		text-align: left;
		width: 50%;
	}
`;

const HDiv = styled.div`
	background-size: contain;
	background-repeat: no-repeat;
	background-position: right center;
`;

const HealthCard = styled(DetailCard)<{ healthFactor: number }>`
	background: linear-gradient(
		90deg,
		${({ theme, healthFactor }) =>
				healthFactor < 1.2 ? theme.colors.red1 : healthFactor < 1.7 ? theme.colors.yellow1 : theme.colors.green1}
			20%,
		${({ theme }) => theme.colors.white + '80'} 100%
	);

	span {
		color: ${({ theme, healthFactor }) =>
			healthFactor < 1.2 ? theme.colors.red : healthFactor < 1.7 ? theme.colors.black : theme.colors.green};
	}
`;

const HelpIcon = styled.img`
	position: absolute;
	top: 10px;
	right: 10px;
`;

const GridDiv = styled.div``;

const ToolText = styled.text`
	font: DM Sans;
	font-size: 14px;
`;

interface IProps {
	borrow: boolean;
	profileView?: boolean;
}

export const AccountOverview: React.FC<IProps> = ({ borrow, profileView }) => {
	const { connect, connected } = useWallet();
	const { user, healthFactor, netIR, totalLentUSD } = useUser();
	let healthFactorIs = goodHF;
	if (healthFactor.value < 1.2) {
		healthFactorIs = badHF;
	} else if (healthFactor.value < 1.7 && healthFactor.value > 1.2) {
		healthFactorIs = medHF;
	} else if (healthFactor.value > 1.7) {
		healthFactorIs = goodHF;
	}

	return !connected ? (
		<NoConnectedCard>
			<NoConnectedDetail>
				<img src={ybxIcon} alt="" />
				<div>
					<b>No wallet connected!</b>
					<div>Please connect a wallet to use YieldBlox.</div>
				</div>
			</NoConnectedDetail>

			<Button variant="secondary" onClick={connect}>
				Connect Wallet
			</Button>
		</NoConnectedCard>
	) : (
		<>
			<SectionTitle title="Account Overview" />

			<GridDiv>
				<Grid container spacing={2}>
					<Grid item lg={3} md={6} sm={6} xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<HDiv style={{ backgroundImage: `url(${healthFactorIs})` }}>
									<HealthCard healthFactor={healthFactor.value} style={{ display: 'flex', flexWrap: 'wrap' }}>
										<h5>{getDisplayHealthFactor(healthFactor.value)}</h5>
										<div style={{ width: '48%', minWidth: 120 }}>
											<span>Health</span>
											<p>factor</p>
										</div>
										<Tooltip
											title={
												<ToolText style={{ color: 'white' }}>A measure of your collateralization levels.</ToolText>
											}
											arrow
										>
											<HelpIcon src={questionIcon} alt="" />
										</Tooltip>
									</HealthCard>
								</HDiv>
							</Grid>
							<Grid item xs={12}>
								<DetailCard style={{ display: 'flex', flexWrap: 'wrap' }}>
									{
										// TODO: Determine usefulness of calculating this
									}
									<h5>{getFormattedNumber(netIR)}%</h5>
									<div style={{ width: '48%', minWidth: 120 }}>
										<p>Net</p>
										<GreenText>APR</GreenText>
									</div>
								</DetailCard>
							</Grid>
						</Grid>
					</Grid>
					<Grid item lg={3} md={6} sm={6} xs={12}>
						<Grid container spacing={2}>
							<Grid item xs={12}>
								<DetailCard style={{ display: 'flex', flexWrap: 'wrap' }}>
									<h5>${getFormattedNumber(parseFloat(healthFactor.liabilitiesUSD))}</h5>
									<div style={{ width: '48%', minWidth: 120 }}>
										<p>Total value</p>
										<GreenText>borrowed</GreenText>
									</div>
								</DetailCard>
							</Grid>
							<Grid item xs={12}>
								<DetailCard style={{ display: 'flex', flexWrap: 'wrap' }}>
									<h5>${getFormattedNumber(totalLentUSD)}</h5>
									<div style={{ width: '48%', minWidth: 120 }}>
										<div>Total value</div>
										<GreenText>lent</GreenText>
									</div>
								</DetailCard>
							</Grid>
						</Grid>
					</Grid>
					<Grid item lg={6} md={12} xs={12}>
						<EscrowInfo />
					</Grid>
				</Grid>
			</GridDiv>
		</>
	);
};
