import { useUser, useYieldBlox } from 'contexts';
import { PositionType } from 'mocks/models';
import React from 'react';
import styled from 'styled-components';
import { Borrow, Lend } from 'yieldblox-js';
import { BorrowTile } from './BorrowTile';
import { LendTile } from './LendTile';

const Container = styled.div`
	display: flex;
	flex-wrap: wrap;
`;

interface IProps {
	type: Maybe<PositionType>;
}

export const MyPositions: React.FC<IProps> = ({ type }) => {
	const { pool } = useYieldBlox();
	const { user } = useUser();

	let lentPositions: Lend[] = [];
	let borrowPositions: Borrow[] = [];
	if (type === undefined || type === PositionType.Lend) {
		lentPositions = user.positions?.getLentPositionsForPool(pool.id) ?? [];
	}
	if (type === undefined || type === PositionType.Borrow) {
		borrowPositions = user.positions?.getBorrowedPositionsForPool(pool.id) ?? [];
	}
	// order positions by most recently modified
	const poolPositions = [...lentPositions, ...borrowPositions].sort((a, b) =>
		a.lastModifiedTime.getTime() > b.lastModifiedTime.getTime() ? -1 : 1
	);

	return (
		<Container>
			{poolPositions.map(position =>
				position instanceof Lend ? (
					<LendTile position={position} key={position.accountId + position.assetId}></LendTile>
				) : (
					<BorrowTile position={position} key={position.accountId + position.assetId}></BorrowTile>
				)
			)}
		</Container>
	);
};
