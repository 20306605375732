import React from 'react';
import styled, { useTheme } from 'styled-components';
import { ProposalStatus } from 'yieldblox-js';

const Status = styled.div<{ background: string; color: string }>`
	border-radius: 16px;
	background: ${({ background }) => background};
	color: ${({ color }) => color};
	padding: 5px;
	padding-right: 15px;
	padding-left: 15px;
	display: flex;
	align-items: center;

	p {
		padding: 0 10px;
	}

	img {
		height: 20px;
	}
`;

interface IProps extends React.HTMLAttributes<HTMLElement> {
	proposalStatus: ProposalStatus;
}

export const ProposalTag: React.FC<IProps> = ({ proposalStatus }) => {
	const theme = useTheme();

	let color = theme.colors.yellow;
	let background = theme.colors.yellow1;
	let body = 'loading';
	switch (proposalStatus) {
		case ProposalStatus.ACCEPTED:
			color = theme.colors.green;
			background = theme.colors.green1;
			body = 'Accepted';
			break;
		case ProposalStatus.IMPLEMENTING:
			color = theme.colors.yellow;
			background = theme.colors.yellow1;
			body = 'Implementing';
			break;
		case ProposalStatus.IMPLEMENTED:
			color = theme.colors.green;
			background = theme.colors.green1;
			body = 'Implemented';
			break;
		case ProposalStatus.REJECTED:
			color = theme.colors.red;
			background = theme.colors.red1;
			body = 'Rejected';
			break;
		case ProposalStatus.VOTING:
			color = theme.colors.yellow;
			background = theme.colors.yellow1;
			body = 'Voting';
			break;
		default:
			color = theme.colors.red;
			background = theme.colors.red1;
			body = 'Unknown';
	}
	return (
		<Status color={color} background={background}>
			{body}
		</Status>
	);
};
