import freighterApi from '@stellar/freighter-api';
import { HORIZON_NETWORK } from 'config/constants';

export async function connect(): Promise<string> {
	let active = freighterApi.isConnected();
	if (!active) throw new Error('Freighter is not installed');

	return await freighterApi.getPublicKey();
}

export async function sign(xdr: string): Promise<string> {
	let signedTransaction = await freighterApi.signTransaction(xdr, HORIZON_NETWORK);
	return signedTransaction;
}
