import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { BalanceLine, STELLAR_ZERO, VoteEvent } from 'yieldblox-js';
import { ProposalTag, IModalProps, Input, Modal, ModalFooter, ModalHeader, ConfirmActionModal } from 'components';
import { GreenText } from 'theme';
import copy from 'copy-to-clipboard';

import PropAllocIcon from 'assets/images/icn_prop_alloc.png';
import LinkIcon from 'assets/images/icn_link.svg';
import yesIcon from 'assets/images/icn_yes.svg';
import noIcon from 'assets/images/icn_no.svg';
import editIcon from 'assets/images/icn_edit.svg';
import { XdrProposalData } from 'types';
import { getFormattedNumber, getShortenWalletAddress } from 'utils';
import { useUser, useWallet } from 'contexts';
import { TooltipButton } from 'components/common/Button/TooltipButton';
import { toast } from 'react-toastify';

const RowItem = styled.div`
	padding: 14px 40px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey3};
`;

const RowDetail = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 0;
`;

const PropIcon = styled.img`
	width: 30px;
	height: 30px;
`;

const XdrDiv = styled.div`
	border-radius: 7px;
	padding: 10px;
	width: 95%;
	background: ${({ theme }) => theme.colors.light_grey};
	overflow: hidden;

	&:hover {
		opacity: 0.75;
	}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const VoteWrapper = styled.div`
	display: flex;
	align-items: center;

	img {
		height: 14px;
		margin: 4px;
	}
`;

const VoteButton = styled.div<{ disabled?: boolean }>`
	display: flex;
	flex-direction: row;
	cursor: pointer;
	opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};

	&:hover {
		opacity: 0.85;
	}

	img {
		height: 14px;
		margin: 4px;
	}
`;

const VerticalDivider = styled.div`
	width: 1px;
	background: ${({ theme }) => theme.colors.light_grey3};
	height: 20px;
	margin: 0 20px;
`;

const ContentWrapper = styled.div`
	width: 100%;
	background: ${({ theme }) => theme.colors.light_grey1};
	box-shadow: 0px 7px 13px ${({ theme }) => theme.colors.shadow};
	border-radius: 18px;
	padding: 19px 25px 6px 25px;
	overflow: hidden;
`;

interface IProps extends IModalProps {
	proposalData: XdrProposalData;
}

export const ProposalModal: React.FC<IProps> = ({ open, onClose, proposalData }) => {
	const { proposal, yes, no, total } = proposalData;
	const { user, votingPower } = useUser();
	const { walletAddress, runContract } = useWallet();

	const PROP_YES_ASSET = `YES:${proposal.id}`;
	const PROP_NO_ASSET = `NO:${proposal.id}`;

	const [showConfirm, setShowConfirm] = useState<boolean>(false);
	const [userYes, setYesVotes] = useState<number>(0);
	const [userNo, setNoVotes] = useState<number>(0);
	const [newVoteCount, setNewVoteCount] = useState<string>('');
	const [editing, setEditing] = useState<boolean>(false);
	const [currentVoteYes, setCurrentVoteYes] = useState<boolean>(false);
	const [voteYes, setVoteYes] = useState<boolean>(false);

	const remainingVotes = votingPower - Number(newVoteCount);

	const handleSetVoteYes = (voteType: boolean) => {
		if (voteType === voteYes && !editing) {
			handleSetEditing(true);
		}
		setVoteYes(voteType);
	};

	const handleSetEditing = (isEditing: boolean) => {
		if (isEditing) {
			setNewVoteCount((voteYes ? userYes : userNo).toString());
		}
		setEditing(isEditing);
	};

	const processTransaction = async () => {
		if (walletAddress) {
			let newVotes = [];
			if (voteYes && Number(newVoteCount) > 0) {
				newVotes.push({
					assetId: `YES:${proposal.id}`,
					amount: newVoteCount,
				});
			}
			if (!voteYes && Number(newVoteCount) > 0) {
				newVotes.push({
					assetId: `NO:${proposal.id}`,
					amount: newVoteCount,
				});
			}
			let voteEvent = new VoteEvent(walletAddress, proposal.id, newVotes, proposal.type);
			await runContract(voteEvent);
		}
	};

	const isValid = () => {
		return remainingVotes >= 0 && editing;
	};

	const handleClose = () => {
		setEditing(false);
		setCurrentVoteYes(false);
		setVoteYes(false);
		setShowConfirm(false);
		if (onClose) {
			onClose();
		}
	};

	const handleConfirm = async () => {
		processTransaction();
		handleClose();
	};

	const handleCopy = (toCopy: string) => {
		copy(toCopy);
		toast.success('Copied XDR to clipboard');
	};

	useEffect(() => {
		const loadVotes = async () => {
			if (user.id) {
				let votes = await proposal.getVotesForAccount(user.id);
				let yes = Number(votes.find(v => v.assetId === PROP_YES_ASSET)?.amount ?? '0');
				let no = Number(votes.find(v => v.assetId === PROP_NO_ASSET)?.amount ?? '0');
				setYesVotes(yes);
				setNoVotes(no);
				setCurrentVoteYes(yes > no);
				setVoteYes(yes > no);
			}
		};
		loadVotes().catch(console.error);
	}, [open]);

	return (
		<>
			<Modal open={open} onClose={handleClose} width={700}>
				<ModalHeader>
					<div>Xdr Proposal</div>
					<ProposalTag proposalStatus={proposal.status} />
				</ModalHeader>

				<RowItem>
					<RowDetail style={{ justifyContent: 'flex-start' }}>
						<PropIcon src={PropAllocIcon} alt="icon" />
						<b style={{ margin: '0 16px' }}>Account</b>
						<p>{getShortenWalletAddress(proposal.id)}</p>
					</RowDetail>

					<RowDetail style={{ justifyContent: 'flex-start' }}>
						<img src={LinkIcon} alt="" />
						<b style={{ margin: '0 16px' }}>
							<GreenText onClick={() => window.open('https://docs.ybx.script3.io/user-docs/governance', 'gov')}>
								More Information
							</GreenText>
						</b>
					</RowDetail>
				</RowItem>

				<RowItem style={{ padding: 12 }}>
					<ContentWrapper style={{ padding: '10px 20px' }}>
						<RowDetail>
							<h6>{proposal.title}</h6>
							<Column>
								<p>
									<b>Current Votes</b>
								</p>
								<VoteWrapper>
									<p>{getFormattedNumber(yes)}</p>
									<img src={yesIcon} alt="" />
									<VerticalDivider />
									<p>{getFormattedNumber(no)}</p>
									<img src={noIcon} alt="" />
								</VoteWrapper>
							</Column>
						</RowDetail>

						<RowDetail>
							<div>
								<b>Description</b>
								<p>{proposal.description}</p>
							</div>
						</RowDetail>

						<RowDetail>
							<div>
								<b>XDR</b>
								{proposal.xdrs.map((xdr, idx) => (
									<XdrDiv key={idx} onClick={() => handleCopy(xdr)}>
										{xdr}
									</XdrDiv>
								))}
							</div>
						</RowDetail>
					</ContentWrapper>
				</RowItem>

				<RowItem>
					<RowDetail>
						<b>Available Votes</b>
						<p>{remainingVotes.toFixed(7)}</p>
					</RowDetail>
					<RowDetail>
						<b>Your current vote</b>
						<RowDetail>
							<VoteWrapper>
								<VoteButton disabled={editing ? !voteYes : !currentVoteYes} onClick={() => handleSetVoteYes(true)}>
									<img src={yesIcon} alt="" />
									<p>YES</p>
								</VoteButton>
								<VerticalDivider />
								<VoteButton disabled={editing ? voteYes : currentVoteYes} onClick={() => handleSetVoteYes(false)}>
									<img src={noIcon} alt="" />
									<p>NO</p>
								</VoteButton>
							</VoteWrapper>
							{editing ? (
								<RowItem style={{ justifyContent: 'center', borderBottom: 'none', paddingRight: '16px' }}>
									<Input
										type="number"
										min={0}
										value={newVoteCount}
										placeholder="--"
										style={{ width: 120 }}
										onChange={e => setNewVoteCount(e.target.value)}
										buttonText="MAX"
										onButtonClick={() => setNewVoteCount(votingPower.toFixed(7))}
									/>
								</RowItem>
							) : (
								<RowItem
									style={{ justifyContent: 'center', borderBottom: 'none', paddingRight: '16px' }}
									onClick={() => handleSetEditing(true)}
								>
									{currentVoteYes ? userYes : userNo}
									<img src={editIcon} alt="" style={{ marginLeft: 4 }} />
								</RowItem>
							)}
						</RowDetail>
					</RowDetail>
				</RowItem>

				<ModalFooter style={{ justifyContent: 'flex-end' }}>
					<TooltipButton
						variant="primary"
						onClick={() => setShowConfirm(true)}
						disabled={!isValid()}
						errorMessage="Cannot vote with more than your alloted votes."
					>
						Vote
					</TooltipButton>
				</ModalFooter>
			</Modal>

			<ConfirmActionModal
				type="Vote"
				open={showConfirm}
				onClose={handleClose}
				onBack={() => setShowConfirm(false)}
				onConfirm={handleConfirm}
			>
				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Proposal</b>
						<b>{getShortenWalletAddress(proposal.id)}</b>
					</RowDetail>
					<RowDetail>
						<b>Votes</b>
					</RowDetail>
					<RowDetail>
						<p style={{ width: 100, textAlign: 'right' }}>YES</p>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
							<p>{getFormattedNumber(Number(voteYes ? newVoteCount : '0'))}</p>
						</div>
					</RowDetail>
					<RowDetail>
						<p style={{ width: 100, textAlign: 'right' }}>NO</p>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
							<p>{getFormattedNumber(Number(!voteYes ? newVoteCount : '0'))}</p>
						</div>
					</RowDetail>
				</RowItem>
			</ConfirmActionModal>
		</>
	);
};
