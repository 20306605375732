import React from 'react';
import styled from 'styled-components';

const StyledButton = styled.button<{
	variant?: 'primary' | 'secondary';
	disable?: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 40px;
	color: ${({ theme, variant }) =>
		variant === 'primary' ? theme.colors.white : variant === 'secondary' ? theme.colors.green : theme.colors.black};
	background: ${({ theme, variant }) =>
		variant === 'primary'
			? theme.colors.green
			: variant === 'secondary'
			? theme.colors.green1
			: theme.colors.light_grey2};
	padding: 0.5rem 2rem;
	font-size: 16px;
	line-height: 21px;
	font-weight: 500;
	border: none;
	outline: none;

	&:hover {
		opacity: 0.85;
	}

	${({ disable }) =>
		disable &&
		`
		opacity: 0.15 !important;
		cursor: not-allowed;
	`}

	&:focus {
		box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.blue};
	}
`;

export interface IButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	variant?: 'primary' | 'secondary'; // primary as default
}

export const Button: React.FC<IButtonProps> = ({ children, disabled, onClick, ...props }) => {
	return (
		<StyledButton disable={disabled} onClick={e => onClick && !disabled && onClick(e)} {...props}>
			{children}
		</StyledButton>
	);
};
