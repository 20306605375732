import React, { useState } from 'react';
import styled from 'styled-components';
import { AccountOverview, Divider, SectionTitle, Toggle } from 'components';
import { MyPositions } from './MyPositions';
import { useWallet } from 'contexts';
import { PositionType } from 'mocks/models';

const RowItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const Profile = () => {
	const { connected } = useWallet();

	const [status, setStatus] = useState(0);

	return (
		<>
			<AccountOverview borrow={status !== 0} profileView />

			{connected && (
				<>
					<Divider />

					<RowItem>
						<SectionTitle title="My Positions" />

						<Toggle
							value={status}
							texts={['Lend', 'Borrow', 'All']}
							onUpdate={(value: number) => setStatus(value)}
							style={{ width: 200 }}
						/>
					</RowItem>

					<MyPositions type={status === 0 ? PositionType.Lend : status === 1 ? PositionType.Borrow : undefined} />
				</>
			)}
		</>
	);
};
