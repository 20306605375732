import React from 'react';
import { Button, IButtonProps } from 'components';
import { Tooltip } from '@material-ui/core';

interface ITooltipButtonProps extends IButtonProps {
	errorMessage: string;
}

export const TooltipButton: React.FC<ITooltipButtonProps> = ({ children, disabled, errorMessage, ...props }) => {
	return !disabled ? (
		<Button {...props}>{children}</Button>
	) : (
		<Tooltip title={errorMessage} placement="top" arrow>
			<span>
				<Button disabled {...props}>
					{children}
				</Button>
			</span>
		</Tooltip>
	);
};
