import React, { useState } from 'react';
import styled from 'styled-components';
import { AssetIcon, Button, Card, RepayModal } from 'components';
import { GreenText } from 'theme';
import { usePrices, useYieldBlox } from 'contexts';
import { mathHelper } from 'utils/protocol';
import { getAdjDisplayBalance, getDisplayBalance, getDisplayRate } from 'utils';

import redoIcon from 'assets/images/icn_redo.svg';
import { Borrow } from 'yieldblox-js';
import { YBX_TOKEN } from 'config/constants';

const Container = styled(Card)`
	min-width: 294px;
	width: 250px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;
	margin: 10px;
`;

const Divider = styled.div`
	width: 100%;
	height: 2px;
	background: ${({ theme }) => theme.colors.light_grey3};
`;

const Row = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 16px;
`;

const Title = styled.div`
	font-weight: bold;
	font-size: 22px;
	line-height: 29px;
`;

const Status = styled.div`
	background: ${({ theme }) => theme.colors.green1};
	padding: 2px 8px;
	border-radius: 16px;
`;

const Block = styled.div`
	width: 50%;
	padding: 16px;
`;

const RedoImg = styled.img`
	width: 14px;
	margin-right: 16px;
`;

interface IProps {
	position: Borrow;
}

export const BorrowTile: React.FC<IProps> = ({ position }) => {
	const { getPoolAssetDataFromToken } = useYieldBlox();
	const { prices } = usePrices();

	const poolAssetData = getPoolAssetDataFromToken(position.assetId);
	const assetCode = poolAssetData.poolAsset.underlyingId.split(':')[0];

	const pricePair = prices[poolAssetData.poolAsset.underlyingId];
	const ybxPrice = prices[YBX_TOKEN];
	const tokenYield = mathHelper.CalculateTokenYield(
		poolAssetData.lTokenIssuance,
		poolAssetData.lTokenRate,
		pricePair?.rate,
		ybxPrice?.rate ?? 1
	);

	const underlyingBalance = parseFloat(position.balance) * poolAssetData.lTokenRate;
	const Principal = parseFloat(position.principal);

	const [showRepayModal, setShowRepayModal] = useState(false);

	return (
		<Container>
			<Row>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<AssetIcon assetCode={assetCode} />

					<div style={{ marginLeft: '1rem' }}>
						<Title>{assetCode}</Title>
						<p>{getDisplayBalance(underlyingBalance, assetCode)}</p>
						<GreenText>${getAdjDisplayBalance(underlyingBalance, pricePair?.rate)}</GreenText>
					</div>
				</div>

				<Status
					style={{
						color: '#00C4EF',
						background: '#00C4EF26',
					}}
				>
					Borrowed
				</Status>
			</Row>

			<Divider />

			<Row style={{ padding: 0 }}>
				<Block>
					<p style={{ marginBottom: 8 }}>
						<b>Current Token Issuance</b>
					</p>
					<p>
						<GreenText>{getDisplayRate(tokenYield)}%</GreenText>
					</p>
				</Block>

				<Block>
					<p style={{ marginBottom: 8 }}>
						<b>Current Interest Rate</b>
					</p>
					<p>
						<GreenText>{getDisplayRate(poolAssetData.borrowRate)}%</GreenText>
					</p>
				</Block>
			</Row>

			<Divider style={{ height: 1 }} />

			<Row>
				<div style={{ width: '100%', height: 70 }}>
					<p style={{ marginBottom: 8 }}>
						<b>Principal</b>
					</p>
					<Row style={{ padding: 0, alignItems: 'center' }}>
						<div>
							<GreenText>{getDisplayBalance(Principal, assetCode)}</GreenText>
						</div>
					</Row>
				</div>
			</Row>

			<Divider />

			<Row style={{ justifyContent: 'flex-end' }}>
				<Button style={{ color: '#FFFFFF' }} variant="primary" onClick={() => setShowRepayModal(true)}>
					<RedoImg src={redoIcon} alt="" />
					Repay
				</Button>
			</Row>

			<RepayModal
				open={showRepayModal}
				onClose={() => setShowRepayModal(false)}
				position={position}
				pAssetData={poolAssetData}
			/>
		</Container>
	);
};
