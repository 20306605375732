import React, { useContext, useEffect, useRef, useState } from 'react';
import { Networks } from 'stellar-sdk';
import { Governance, Pool, PoolAsset, Prices, ProposalType, safeMath, Turret, YbxBase, YbxTurret } from 'yieldblox-js';
import { HORIZON_URL, POOL_ACCOUNT_ID, YBX_TOKEN } from 'config/constants';
import { AllocationProposalData, PoolAssetData, XdrProposalData } from 'types';
import { mathHelper } from 'utils/protocol';
import BigNumber from 'bignumber.js';

export interface IYieldBloxContext {
	base: YbxBase;
	ybxTurretHelper: YbxTurret;
	turrets: Turret[];
	horizonUrl: string;
	network: Networks;
	governance: Governance;
	allocationProposal: AllocationProposalData;
	xdrProposals: XdrProposalData[];
	veYbxRate: number;
	pool: Pool;
	poolAssets: Record<string, PoolAssetData>;
	ybxAsset: PoolAsset;
	contractHash: string;
	getPoolAssetDataFromUnderlying: (underlyingId: string) => PoolAssetData;
	getPoolAssetDataFromToken: (tokenId: string) => PoolAssetData;
	updateLedger: () => void;
	getProtocolPriceFetcher: (timestamp: number) => Prices;
}

const YieldBloxContext = React.createContext<Maybe<IYieldBloxContext>>(undefined);

YbxBase.Configure(HORIZON_URL);
const base = new YbxBase();
const ybxTurretHelper = new YbxTurret();

export const YieldBloxProvider = ({ children = null as any }) => {
	// cache local instances to keep webapi data in sync with each other
	const [currentGov, setCurrentGov] = useState<Governance>({} as Governance);
	const [currentPool, setCurrentPool] = useState<Pool>({} as Pool);
	const [poolAssets, setPoolAssets] = useState<Record<string, PoolAssetData>>({});
	const [ybxAsset, setYbxAsset] = useState<PoolAsset>({} as PoolAsset);
	const [contractHash, setContractHash] = useState<string>('');
	const [veYbxRate, setVeYbxRate] = useState<number>(0);
	const [allocationProposal, setAllocationProposal] = useState<AllocationProposalData>({} as AllocationProposalData);
	const [xdrProposals, setXdrProposals] = useState<XdrProposalData[]>([]);
	const [turrets, setTurrets] = useState<Turret[]>([]);

	const ledgerTimer = useRef<NodeJS.Timeout | null>(null);

	const updateLedger = async () => {
		if (ledgerTimer.current) {
			clearTimeout(ledgerTimer.current);
		}

		loadPool();
		loadGov();
		loadTurrets();

		ledgerTimer.current = setTimeout(() => {
			updateLedger();
		}, 30 * 60 * 1000);
	};

	// load ledger for first time on mount
	useEffect(() => {
		updateLedger();
	}, []);

	async function loadGov() {
		try {
			let newGov = await base.governance();
			// TODO: Make function for this
			let contractHash = newGov.data.getContractHash();
			let veYbxValue = await newGov.getVeYBXRate();
			await loadProposals(newGov);
			setCurrentGov(newGov);
			setContractHash(contractHash);
			setVeYbxRate(veYbxValue.toNumber());
		} catch (e) {
			console.error(e);
			console.log('Unable to load governance account.');
		}
	}

	async function loadTurrets() {
		try {
			let newTurrets = await ybxTurretHelper.Turrets();
			setTurrets(newTurrets);
		} catch (e) {
			console.error(e);
			console.log('Unable to load turrets.');
		}
	}

	async function loadPool() {
		try {
			console.log('Setting pool...');
			let newPool = await base.pool(POOL_ACCOUNT_ID);
			setCurrentPool(newPool);

			let assetRecord = await base.horizon.GetAssetAsync(YBX_TOKEN);
			let outstandingYbx = parseFloat(assetRecord.amount) + parseFloat(assetRecord.claimable_balances_amount);
			let yearlyYbxToBeIssued = mathHelper.CalculateYbxIssuanceForYear(outstandingYbx);
			let newPoolAssetData: Record<string, PoolAssetData> = {};
			let newPoolAssets = newPool.getSupportedAssets();
			for (const pAsset of newPoolAssets) {
				if (pAsset.underlyingId === YBX_TOKEN) {
					setYbxAsset(pAsset);
				} else {
					let data = await loadPoolAsset(newPool, pAsset, yearlyYbxToBeIssued);
					if (data) {
						newPoolAssetData[pAsset.underlyingId] = data;
					}
				}
			}
			setPoolAssets(newPoolAssetData);
			console.log('Pool set');
		} catch (e) {
			console.error(e);
			console.log('Unable to load pool account.');
		}
	}

	async function loadPoolAsset(newPool: Pool, pAsset: PoolAsset, ybxToBeIssued: number): Promise<Maybe<PoolAssetData>> {
		try {
			// TODO: Update base to return util rate
			let timestamp = Date.now();
			let lTokensOutstanding = await pAsset.getLiabilityTokensOutstanding();
			let lTokenRate = await pAsset.getLiabilityTokenRate(timestamp);
			let yTokensOutstanding = await pAsset.getPoolTokensOutstanding();
			let yTokenRate = await pAsset.getPoolTokenRate(timestamp);

			let liabilitiesOutstanding = lTokensOutstanding.times(lTokenRate);
			let poolOutstanding = yTokensOutstanding.times(yTokenRate);
			let poolBalance = newPool.getBalance(pAsset.underlyingId) ?? '0';

			let utilRate = safeMath.CalcUtilizationRatio(liabilitiesOutstanding, new BigNumber(poolBalance));
			let interestRate = safeMath.CalcFloatInterestRate(pAsset.data, utilRate);
			let lendRate = safeMath.CalcLendInterestRate(interestRate, utilRate);

			let lTokenIssuance = (pAsset.data.borrowingAllocation * ybxToBeIssued) / lTokensOutstanding.toNumber();
			let yTokenIssuance = (pAsset.data.lendingAllocation * ybxToBeIssued) / yTokensOutstanding.toNumber();

			// frontend does not need bignumber precision
			return {
				poolAsset: pAsset,
				borrowRate: interestRate.toNumber(),
				lendRate: lendRate.toNumber(),
				utilRate: utilRate.toNumber(),
				lTokenIssuance: lTokenIssuance,
				yTokenIssuance: yTokenIssuance,
				totalLiabilities: liabilitiesOutstanding.toNumber(),
				totalUnderlying: poolOutstanding.toNumber(),
				yTokenRate: yTokenRate.toNumber(),
				lTokenRate: lTokenRate.toNumber(),
				poolBalance: parseFloat(poolBalance),
			};
		} catch (e) {
			console.error(e);
			console.log('Unable to load pool asset data for ', pAsset.underlyingId);
			return undefined;
		}
	}

	async function loadProposals(newGov: Governance) {
		try {
			// TODO: Enable proposal loading after vote modals are tested
			let proposalAccountIds = newGov.data.getAllCustomProposalIds();
			let newProposals: XdrProposalData[] = [];
			for (const proposalId of proposalAccountIds) {
				let xdrProp = await newGov.getCustomProposal(proposalId);
				let votes = await xdrProp.getVotes();
				let yes = votes.votes.find(v => v.assetId === `YES:${xdrProp.id}`);
				let no = votes.votes.find(v => v.assetId === `NO:${xdrProp.id}`);
				newProposals.push({
					proposal: xdrProp,
					yes: parseFloat(yes?.amount ?? '0'),
					no: parseFloat(no?.amount ?? '0'),
					total: parseFloat(votes.total),
					quorumPct: parseFloat(votes.pctOfQuorum),
				});
			}

			// fetch council proposals
			let councilLock = await newGov.getCouncilProposal(ProposalType.COUNCIL_LOCK_CONTRACT);
			if (councilLock) {
				console.log('councilLock', councilLock);
				let votes = await councilLock.getVotes();
				// @ts-ignore
				let yes = votes.votes.find(v => v.assetId === `YES:${councilLock.id}`);
				// @ts-ignore
				let no = votes.votes.find(v => v.assetId === `NO:${councilLock.id}`);
				newProposals.push({
					proposal: councilLock,
					yes: parseFloat(yes?.amount ?? '0'),
					no: parseFloat(no?.amount ?? '0'),
					total: parseFloat(votes.total),
					quorumPct: parseFloat(votes.pctOfQuorum),
				});
			}
			let councilSwap = await newGov.getCouncilProposal(ProposalType.COUNCIL_SWAP_SIGNER);
			if (councilSwap) {
				console.log('councilSwap', councilSwap);
				let votes = await councilSwap.getVotes();
				// @ts-ignore
				let yes = votes.votes.find(v => v.assetId === `YES:${councilSwap.id}`);
				// @ts-ignore
				let no = votes.votes.find(v => v.assetId === `NO:${councilSwap.id}`);
				newProposals.push({
					proposal: councilSwap,
					yes: parseFloat(yes?.amount ?? '0'),
					no: parseFloat(no?.amount ?? '0'),
					total: parseFloat(votes.total),
					quorumPct: parseFloat(votes.pctOfQuorum),
				});
			}

			setXdrProposals(newProposals);
			console.log('newProposals', newProposals);
			let newAllocationProposal = await newGov.getAllocationProposal();
			if (newAllocationProposal) {
				let allcVotes = await newAllocationProposal.getVotes();
				console.log('allocVotes', allcVotes);
				let newAllocationData: AllocationProposalData = {
					proposal: newAllocationProposal,
					votes: allcVotes.votes,
					total: parseFloat(allcVotes.total),
				};
				setAllocationProposal(newAllocationData);
				console.log('newAllocationData', newAllocationData);
			}
		} catch (e) {
			console.error(e);
			console.log('Unable to load proposals');
			return undefined;
		}
	}

	function getPoolAssetDataFromUnderlying(underlyingId: string): PoolAssetData {
		return poolAssets[underlyingId];
	}

	function getPoolAssetDataFromToken(tokenId: string): PoolAssetData {
		let poolAsset = currentPool.getAssetFromToken(tokenId);
		return getPoolAssetDataFromUnderlying(poolAsset?.underlyingId ?? '');
	}

	function getProtocolPriceFetcher(timestamp?: number): Prices {
		try {
			let priceObj = base.prices(timestamp ?? Date.now());
			return priceObj;
		} catch (e) {
			console.error(e);
			console.log('Unable to load price fetcher.');
			throw e;
		}
	}

	return (
		<YieldBloxContext.Provider
			value={{
				base: base,
				ybxTurretHelper: ybxTurretHelper,
				turrets: turrets,
				horizonUrl: HORIZON_URL,
				network: base.horizon.network,
				governance: currentGov,
				allocationProposal: allocationProposal,
				xdrProposals: xdrProposals,
				veYbxRate: veYbxRate,
				pool: currentPool,
				poolAssets: poolAssets,
				ybxAsset: ybxAsset,
				contractHash: contractHash,
				getPoolAssetDataFromUnderlying: getPoolAssetDataFromUnderlying,
				getPoolAssetDataFromToken: getPoolAssetDataFromToken,
				updateLedger: updateLedger,
				getProtocolPriceFetcher: getProtocolPriceFetcher,
			}}
		>
			{children}
		</YieldBloxContext.Provider>
	);
};

export const useYieldBlox = () => {
	const context = useContext(YieldBloxContext);

	if (!context) {
		throw new Error('Component rendered outside the provider tree');
	}

	return context;
};
