import React from 'react';
import styled from 'styled-components';
import { Card, EscrowInfo } from 'components';
import { Grid, Tooltip } from '@material-ui/core';
import { GreenText } from 'theme';

import questionIcon from 'assets/images/icn_question.png';
import { useUser } from 'contexts';
import { getFormattedNumber } from 'utils';

const DetailCard = styled(Card)`
	width: 100%;
	height: 110px;
	display: flex;
	align-items: center;
	font-size: 18px;
	line-height: 24px;

	h5 {
		min-width: 50%;
		margin-right: 10px;
		text-align: center;
	}
`;

const HelpIcon = styled.img`
	position: absolute;
	top: 10px;
	right: 10px;
`;

const ToolText = styled.text`
	font: DM Sans;
	font-size: 14px;
`;

export const GovernanceOverview = () => {
	const { votingPower, activeVotes } = useUser();

	return (
		<Grid container spacing={2}>
			<Grid item md={6} sm={12}>
				<Grid container spacing={2}>
					<Grid item sm={12} xs={12}>
						<DetailCard>
							<h5>{getFormattedNumber(votingPower)}</h5>
							<div>
								<GreenText>Voting</GreenText>
								<p>power</p>
							</div>

							<Tooltip
								title={
									<ToolText style={{ color: 'white' }}>
										The total number of votes you have based on Escrowed YBX.
									</ToolText>
								}
								arrow
							>
								<HelpIcon src={questionIcon} alt="" />
							</Tooltip>

						</DetailCard>
					</Grid>
					<Grid item sm={12} xs={12}>
						<DetailCard>
							<h5>{getFormattedNumber(activeVotes)}</h5>
							<div>
								<p>Active</p>
								<GreenText>votes</GreenText>
							</div>

							<Tooltip
								title={
									<ToolText style={{ color: 'white' }}>
										The number of votes you have cast on active governance proposals.
									</ToolText>
								}
								arrow
							>
								<HelpIcon src={questionIcon} alt="" />
							</Tooltip>

						</DetailCard>
					</Grid>
				</Grid>
			</Grid>

			<Grid item md={6} sm={12} xs={12}>
				<EscrowInfo />
			</Grid>
		</Grid>
	);
};
