import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Card, Divider, ProposalModal, SectionTitle, Toggle } from 'components';
import { GovernanceOverview } from './GovernanceOverview';
import { ProposalItem } from './ProposalItem';

import { useWallet, useYieldBlox } from 'contexts';
import { XdrProposalData } from 'types';
import { AllocationItem } from './AllocationItem';
import ybxIcon from 'assets/images/icn_ybx.svg';
import { AllocationModal } from 'components/modals/AllocationModal';

const RowItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const NoConnectedCard = styled(Card)`
	width: 100%;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding-right: 20px;
	padding-right: 20px;
	background: linear-gradient(
		90deg,
		${({ theme }) => theme.colors.light_grey1} 0%,
		${({ theme }) => theme.colors.white} 100%
	);
`;

const NoConnectedDetail = styled.div`
	display: flex;
	align-items: center;
	margin-bottom: 2rem;
	margin-right: 2rem;

	img {
		height: 60px;
		margin-right: 1rem;
	}

	b {
		font-size: 20px;
		line-height: 33px;
		font-weight: 500;
	}
`;

export const Governance = () => {
	const { connected, connect } = useWallet();
	const { xdrProposals, allocationProposal} = useYieldBlox();

	const [status, setStatus] = useState(0);
	const [proposal, setProposal] = useState<Maybe<XdrProposalData>>(undefined);
	const [showAllocationModal, setShowAllocationModal] = useState<boolean>(false);
	const [showProposalModal, setShowProposalModal] = useState<boolean>(false);

	const handleSetProposal = (proposal: XdrProposalData) => {
		setProposal(proposal);
		setShowProposalModal(true);
	};

	const handleCloseProposalModal = () => {
		setShowProposalModal(false);
	};

	const handleShowAllocationModal = () => {
		setShowAllocationModal(true);
	};

	const handleCloseAllocationModal = () => {
		setShowAllocationModal(false);
	};

	return (
		<>
			<>
				{!connected ? (
					<NoConnectedCard>
						<NoConnectedDetail>
							<img src={ybxIcon} alt="" />
							<div>
								<b>No wallet connected!</b>
								<div>Please connect a wallet to use YieldBlox.</div>
							</div>
						</NoConnectedDetail>

						<Button variant="secondary" onClick={connect}>
							Connect Wallet
						</Button>
					</NoConnectedCard>
				) : (
					<>
						<SectionTitle title="Account Overview" />

						<GovernanceOverview />
					</>
				)}

				<Divider />
				<RowItem style={{ flexWrap: 'wrap' }}>
					<SectionTitle title="All Proposals" />
					
				</RowItem>
				{allocationProposal.proposal && (
					<AllocationItem allocationData={allocationProposal} onClick={handleShowAllocationModal} />
				)}
				<div style={{ width: '100%' }}>
					{xdrProposals.map((proposal, idx) => (
						<ProposalItem propData={proposal} key={idx} setProposal={handleSetProposal} />
					))}
				</div>
			</>
			{proposal && (
				<ProposalModal proposalData={proposal} open={showProposalModal} onClose={handleCloseProposalModal} />
			)}
			{allocationProposal.proposal && (
				<AllocationModal
					allocationData={allocationProposal}
					open={showAllocationModal}
					onClose={handleCloseAllocationModal}
				/>
			)}
		</>
	);
};
