import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { AssetIcon, Button, Card, IModalProps, Input, Modal, ModalFooter, ModalHeader, Toggle } from 'components';
import { GreenText } from 'theme';
import { ConfirmActionModal } from './ConfirmActionModal';
import { CollateralRepayment, PoolAssetData, TxType } from 'types';
import { usePrices, useUser, useWallet, useYieldBlox } from 'contexts';
import {
	getAdjBalance,
	getAdjDisplayBalance,
	getDisplayBalance,
	getDisplayHealthFactor,
	getDisplayRate,
	getFormattedNumber,
} from 'utils';

import arrowIcon from 'assets/images/icn_arrow_right.svg';
import { mathHelper } from 'utils/protocol';
import BigNumber from 'bignumber.js';
import { stellarHelper } from 'utils/stellar';
import { BalanceLine, Borrow, Lend, POOL_ACCOUNT_1_ID, YBX_TOKEN, RepayEvent, PaymentPath } from 'yieldblox-js';

const RowItem = styled.div`
	padding: 14px 40px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey3};
`;

const AssetNameWrapper = styled(RowItem)`
	display: flex;
	align-items: center;
`;

const RowDetail = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
`;

const DetailCard = styled(Card)`
	background: ${({ theme }) => theme.colors.light_grey1};
	padding: 12px 15px;
	display: flex;
	align-items: center;
	font-weight: 500;
	height: 64px;
`;

const AssetContainer = styled(Card)`
	width: 100%;
	max-height: 156px;
	padding: 10px;
	margin: 10px 0;
	background: ${({ theme }) => theme.colors.light_grey1};
	overflow: auto;
`;

const Flex = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

interface IProps extends IModalProps {
	position: Borrow;
	pAssetData: PoolAssetData;
}

export const RepayModal: React.FC<IProps> = ({ open, onClose, position, pAssetData }) => {
	const { walletAddress, runContract } = useWallet();
	const { getPoolAssetDataFromUnderlying } = useYieldBlox();
	const { prices } = usePrices();
	const { healthFactor, user } = useUser();

	const [loadingPaths, setLoadingPaths] = useState(false);
	const [showConfirm, setShowConfirm] = useState(false);
	const [repayCollateral, setRepayCollateral] = useState(false);
	const [liabilityFullDisplay, setLiabilityFullDisplay] = useState(false);
	const [repayAmount, setRepayAmount] = useState('');
	const [liabilityHealthDelta, setLiabilityHealthDelta] = useState<number>(0);
	const [collateralHealthDelta, setCollateralHealthDelta] = useState<number>(0);
	const [acceptableSlippage, setAcceptableSlippage] = useState<string>('2.0');
	const [collatWithdrawals, setCollatWithdrawals] = useState<{
		[assetId: string]: CollateralRepayment;
	}>({});
	const [repaymentTotal, setRepaymentTotal] = useState(0);

	const assetId = pAssetData.poolAsset.underlyingId;
	const assetCode = assetId.split(':')[0];

	const balance = position ? parseFloat(position.balance) * pAssetData.lTokenRate : 0;
	const walletBalance = parseFloat(user.getBalance(assetId) ?? '0');

	const collateralizedPositions = user.positions?.lent?.filter(l => parseFloat(l.balance) > 0) ?? [];

	// find price
	const pricePair = prices[assetId];
	const ybxPrice = prices[YBX_TOKEN];
	const tokenYield = mathHelper.CalculateTokenYield(
		pAssetData.yTokenIssuance,
		pAssetData.yTokenRate,
		pricePair?.rate,
		ybxPrice?.rate ?? 1
	);

	const newHealthFactor = mathHelper.CalculateNewHealthFactor(
		healthFactor,
		collateralHealthDelta.toString(),
		liabilityHealthDelta.toString()
	);

	const handleClose = () => {
		setShowConfirm(false);
		setRepayCollateral(false);
		setRepayAmount('');
		setLiabilityHealthDelta(0);
		setAcceptableSlippage('2.0');
		setCollatWithdrawals({});
		setLiabilityHealthDelta(0);
		setCollateralHealthDelta(0);
		if (onClose) {
			onClose();
		}
	};

	const handleConfirm = async () => {
		processTransaction();
		handleClose();
	};

	const handleSetRepayAmount = (value: string) => {
		setRepayAmount(value);
		handleSetRepaymentTotal(Number(value));
	};

	const handleSetRepaymentTotal = (value: number) => {
		setRepaymentTotal(value);
		let maxLiabilityReduction = Math.min(value, balance);
		setLiabilityHealthDelta(-1 * getAdjBalance(maxLiabilityReduction, pricePair?.rate));
	};

	const handleSetAcceptableSlippage = (value: string) => {
		let oldSlippage = Number(acceptableSlippage) / 100;
		let newSlippage = Number(value) / 100;
		setAcceptableSlippage(value);
		let newTotalRepayment = 0;
		for (let wAssetId in collatWithdrawals) {
			let oldUnderlying = collatWithdrawals[wAssetId].underlying;
			let newUnderlying = (oldUnderlying * (1.001 + oldSlippage)) / (1.001 + newSlippage);
			collatWithdrawals[wAssetId].underlying = newUnderlying;
			newTotalRepayment += newUnderlying;
		}
		handleSetRepaymentTotal(newTotalRepayment);
	};

	const handleMaxRepay = () => {
		let maxRepayAmount = Math.min(balance, walletBalance);
		handleSetRepayAmount(mathHelper.stellarMinTruncate(maxRepayAmount));
	};

	const handleToggleRepayType = (value: boolean) => {
		if (value) {
			// changing back from collateral
			setCollatWithdrawals({});
			setCollateralHealthDelta(0);
			setLiabilityHealthDelta(0);
			handleSetRepaymentTotal(0);
			setRepayCollateral(value);
		} else {
			// changing to collateral
			handleSetRepayAmount('0');
			setCollatWithdrawals({});
			setCollateralHealthDelta(0);
			setLiabilityHealthDelta(0);
			handleSetRepaymentTotal(0);
			setRepayCollateral(value);
		}
	};

	// return a number so the asset component knows the final amount computed
	const handleMaxRepayFromCollateral = async (
		collateralBalance: number,
		valueAssetId: string
	): Promise<Maybe<number>> => {
		try {
			if (!loadingPaths) {
				setLoadingPaths(true);
				let repaymentWithoutAsset = repaymentTotal;
				if (collatWithdrawals[valueAssetId]) {
					repaymentWithoutAsset -= collatWithdrawals[valueAssetId].underlying;
				}
				let leftToRepayUnderlying = balance - repaymentWithoutAsset;
				if (leftToRepayUnderlying <= 0) {
					return undefined; // don't do anything in this event
				}

				let requiredCollateral = leftToRepayUnderlying;
				let base;
				let underlyingVal;
				let path: string[] = [];
				if (assetId !== valueAssetId) {
					// price is
					let priceQuote = await stellarHelper.GetReceiveMarketQuoteAsync(valueAssetId, assetId, leftToRepayUnderlying);
					let value = priceQuote.value;
					value = value * (1 - Number(acceptableSlippage) / 100);
					requiredCollateral = (leftToRepayUnderlying / value) * 1.002;
					base = Math.min(requiredCollateral, collateralBalance);
					underlyingVal = base * value * 1.001;
					path = priceQuote.path;
				} else {
					underlyingVal = Math.min(requiredCollateral, collateralBalance);
					base = underlyingVal;
				}
				updateRepayFromCollateral(base, valueAssetId, underlyingVal, path);
				setLoadingPaths(false);
				return base;
			}
		} catch (e) {
			console.error(e);
			setLoadingPaths(false);
		}
	};

	const handleRepayFromCollateral = async (value: string, valueAssetId: string) => {
		try {
			if (!loadingPaths) {
				setLoadingPaths(true);
				let base = Number(value);
				let underlyingVal;
				let path: string[] = [];
				if (assetId === valueAssetId || base === 0) {
					underlyingVal = base;
				} else {
					let priceQuote = await stellarHelper.GetSendMarketQuoteAsync(valueAssetId, assetId, base);
					underlyingVal = (base * priceQuote.value) / (1.001 + Number(acceptableSlippage) / 100);
					path = priceQuote.path;
				}
				updateRepayFromCollateral(base, valueAssetId, underlyingVal, path);
				setLoadingPaths(false);
			}
		} catch (e) {
			console.error(e);
			setLoadingPaths(false);
		}
	};

	const updateRepayFromCollateral = (base: number, baseAssetId: string, underlyingVal: number, path: string[]) => {
		let priceToUsd = prices[baseAssetId];
		let baseAssetData = getPoolAssetDataFromUnderlying(baseAssetId);
		let usdVal = base * priceToUsd.rate;
		let deltaUsdTotal = usdVal;
		let deltaUnderlyingTotal = underlyingVal;
		if (collatWithdrawals[baseAssetId]) {
			// already exists. Remove old from totals.
			deltaUsdTotal -= collatWithdrawals[baseAssetId].usd;
			deltaUnderlyingTotal -= collatWithdrawals[baseAssetId].underlying;
		}
		// reduce the HF by the amount repaid
		setCollateralHealthDelta(collateralHealthDelta + -1 * deltaUsdTotal * baseAssetData.poolAsset.data.loanToValue);
		handleSetRepaymentTotal(repaymentTotal + deltaUnderlyingTotal);
		setCollatWithdrawals({
			...collatWithdrawals,
			[baseAssetId]: {
				base: base,
				usd: usdVal,
				underlying: underlyingVal,
				path: path,
			},
		});
	};

	const processTransaction = async () => {
		if (walletAddress) {
			let withdrawals: PaymentPath[] = [];
			for (let wAssetId in collatWithdrawals) {
				let withdrawal = collatWithdrawals[wAssetId];
				if (withdrawal) {
					withdrawals.push({
						sendAssetId: wAssetId,
						sendAmount: new BigNumber(withdrawal.base.toString()).toFixed(7, BigNumber.ROUND_UP),
						destAssetId: pAssetData.poolAsset.underlyingId,
						destAmount: new BigNumber(withdrawal.underlying.toString()).toFixed(7, BigNumber.ROUND_UP),
						path: withdrawal.path,
					});
				}
			}
			let repayAmount = new BigNumber(Math.min(repaymentTotal, balance).toString()).dividedBy(
				pAssetData.lTokenRate.toString()
			);
			let repaymentBalLine: BalanceLine = {
				assetId: pAssetData.poolAsset.liabilityTokenId,
				amount: repayAmount.toFixed(7, BigNumber.ROUND_DOWN),
			};
			let repayEvent = new RepayEvent(walletAddress, repaymentBalLine, POOL_ACCOUNT_1_ID, withdrawals);
			await runContract(repayEvent);
		}
	};

	return (
		<>
			<Modal open={open && !showConfirm} onClose={onClose} width={560}>
				<ModalHeader>Repayment</ModalHeader>

				<AssetNameWrapper>
					<AssetIcon assetCode={assetCode} />
					<h6 style={{ marginLeft: '1rem' }}>{assetCode}</h6>
				</AssetNameWrapper>

				<RowItem>
					<RowDetail style={{ overflowX: 'auto' }}>
						<DetailCard>
							<div>
								<p>Token</p>
								<GreenText>Yield</GreenText>
							</div>

							<p style={{ marginLeft: 16 }}>
								{getDisplayRate(tokenYield)}
								<GreenText>%</GreenText>
							</p>
						</DetailCard>
					</RowDetail>
				</RowItem>

				<RowItem>
					<RowDetail>
						<p onClick={() => setLiabilityFullDisplay(!liabilityFullDisplay)}>Total Liabilities</p>
						<p>{getDisplayBalance(balance, assetCode, liabilityFullDisplay)}</p>
					</RowDetail>

					<RowDetail>
						<b>Repay With Collateral</b>
						<Toggle
							value={repayCollateral ? 1 : 0}
							texts={['', '']}
							style={{ width: 60, height: 30 }}
							onUpdate={value => handleToggleRepayType(value === 0 ? false : true)}
						/>
					</RowDetail>

					{repayCollateral ? (
						<>
							<RowDetail>
								<AssetContainer>
									{collateralizedPositions.map((item, idx) => {
										return (
											<AssetItem
												item={item}
												collateralWithdrawals={collatWithdrawals}
												onMax={handleMaxRepayFromCollateral}
												onChange={handleRepayFromCollateral}
												key={idx}
											/>
										);
									})}
								</AssetContainer>
							</RowDetail>

							<RowDetail>
								<b>Maximum slippage (%)</b>
								<Input
									type="number"
									min={0}
									max={20}
									value={acceptableSlippage}
									placeholder="--"
									style={{ width: 120 }}
									onChange={e => handleSetAcceptableSlippage(e.target.value)}
								/>
							</RowDetail>

							<RowDetail>
								<b>Total Repayment</b>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
									<b>{getDisplayBalance(repaymentTotal, assetCode)}</b>
									<p>${getAdjDisplayBalance(repaymentTotal, pricePair?.rate)}</p>
								</div>
							</RowDetail>

							<RowDetail>
								<b>Excess Withdrawal</b>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
									<b></b>
									<p>
										${getFormattedNumber(Math.max(-1 * getAdjBalance(balance - repaymentTotal, pricePair?.rate), 0))}
									</p>
								</div>
							</RowDetail>
						</>
					) : (
						<>
							<RowDetail>
								<p>Available to Repay</p>
								<p>{getDisplayBalance(walletBalance, assetCode)}</p>
							</RowDetail>

							<RowDetail>
								<b>Repay Amount</b>
								<Input
									type="number"
									min={0}
									value={repayAmount}
									placeholder="--"
									style={{ width: 120 }}
									onChange={e => handleSetRepayAmount(e.target.value)}
									buttonText="MAX"
									onButtonClick={handleMaxRepay}
								/>
							</RowDetail>
						</>
					)}
				</RowItem>

				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Remaining Borrow Balance</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
							<b>{getDisplayBalance(Math.max(balance - repaymentTotal, 0), assetCode)}</b>
							<p>${getAdjDisplayBalance(Math.max(balance - repaymentTotal, 0), pricePair?.rate)}</p>
						</div>
					</RowDetail>

					<RowDetail>
						<b>Health Factor</b>
						<b>
							{getDisplayHealthFactor(healthFactor?.value)}
							<img src={arrowIcon} alt="" style={{ margin: '0 10px' }} />
							<GreenText>{getDisplayHealthFactor(newHealthFactor)}</GreenText>
						</b>
					</RowDetail>
				</RowItem>

				<ModalFooter>
					<Button variant="primary" onClick={() => setShowConfirm(true)}>
						Repay
					</Button>
				</ModalFooter>
			</Modal>

			<ConfirmActionModal
				type="Repayment"
				assetId={assetCode}
				open={showConfirm}
				onClose={handleClose}
				onBack={() => setShowConfirm(false)}
				onConfirm={handleConfirm}
			>
				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Repay Amount</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
							<b>{getDisplayBalance(Math.min(repaymentTotal, balance), assetCode)}</b>
							<p>${getAdjDisplayBalance(Math.min(repaymentTotal, balance), pricePair?.rate)}</p>
						</div>
					</RowDetail>
					{repayCollateral ? (
						<>
							<RowDetail>
								<div style={{ width: '100%' }}>
									{Object.keys(collatWithdrawals).map((key, idx) => {
										const deposit = collatWithdrawals[key];
										const itemAssetCode = key.split(':')[0];
										return (
											<RowDetail key={idx}>
												<p style={{ width: 100, textAlign: 'right' }}>
													{getDisplayBalance(deposit.base, itemAssetCode)}
												</p>
												<div
													style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}
												>
													<p>{getDisplayBalance(deposit.underlying, assetCode)}</p>
													<p>${getFormattedNumber(deposit.usd)}</p>
												</div>
											</RowDetail>
										);
									})}
								</div>
							</RowDetail>
							<RowDetail>
								<b>Excess Withdrawal</b>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
									<b></b>
									<p>
										${getFormattedNumber(Math.max(-1 * getAdjBalance(balance - repaymentTotal, pricePair?.rate), 0))}
									</p>
								</div>
							</RowDetail>
						</>
					) : (
						<RowDetail key={0}>
							<p style={{ width: 100, textAlign: 'right' }}>{getDisplayBalance(Number(repayAmount), assetCode)}</p>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
								<p>{getDisplayBalance(Number(repayAmount), assetCode)}</p>
								<p>${getAdjDisplayBalance(Number(repayAmount), pricePair?.rate)}</p>
							</div>
						</RowDetail>
					)}
				</RowItem>
			</ConfirmActionModal>
		</>
	);
};

interface IAssetItem {
	item: Lend;
	collateralWithdrawals: Record<string, CollateralRepayment>;
	onMax: (collateralBalance: number, assetId: string) => Promise<Maybe<number>>;
	onChange: (value: string, assetId: string) => void;
}

const AssetItem = ({ item, collateralWithdrawals, onMax, onChange }: IAssetItem) => {
	const { getPoolAssetDataFromToken } = useYieldBlox();

	const lendAssetData = getPoolAssetDataFromToken(item.assetId);
	const assetId = lendAssetData.poolAsset.underlyingId;
	const assetCode = assetId.split(':')[0];

	const collateralBalance = parseFloat(item.balance) * lendAssetData.yTokenRate;

	const [amount, setAmount] = useState('');

	useEffect(() => {
		const collatWithdrawal = collateralWithdrawals[assetId];
		if (collatWithdrawal !== undefined && collatWithdrawal.base !== Number(amount)) {
			onChange(amount, assetId);
		}
	}, [collateralWithdrawals]);

	const handleChange = (value: string) => {
		setAmount(value);
		onChange(value, assetId);
	};

	const handleMax = async () => {
		let maxValue = await onMax(collateralBalance, assetId);
		if (maxValue) {
			setAmount(String(maxValue));
		} else {
			setAmount('0.0');
		}
	};

	return (
		<Flex style={{ padding: 5 }}>
			<Flex>
				<AssetIcon assetCode={assetCode} />
				<span style={{ marginLeft: '1rem' }}>
					{assetCode} <GreenText>({getDisplayRate(lendAssetData.poolAsset.data.loanToValue)}%)</GreenText>
				</span>
			</Flex>

			<Flex>
				<Input
					type="number"
					min={0}
					value={amount}
					placeholder="--"
					style={{ width: 120, color: 'black' }}
					background="white"
					onChange={e => handleChange(e.target.value)}
					buttonText="MAX"
					onButtonClick={handleMax}
				/>
				<span style={{ marginLeft: 10, width: 100 }}>{getDisplayBalance(collateralBalance, assetCode)}</span>
			</Flex>
		</Flex>
	);
};
