import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { EscrowLockEvent, EscrowUnlockEvent, ProtocolEvent, EscrowUnlock } from 'yieldblox-js';
import { Button, Card, ConfirmActionModal, IModalProps, Modal, ModalFooter, ModalHeader, Toggle } from 'components';
import { GreenText, PurpleText } from 'theme';
import { Input } from 'components/common';
import { usePrices, useUser, useWallet, useYieldBlox } from 'contexts';
import { getDisplayBalance, getDisplayRate, getTimeDifference, getDisplayHealthFactor } from 'utils';
import { TooltipButton } from 'components/common/Button/TooltipButton';

import ybxIcon from 'assets/images/icn_ybx.svg';
import ybxIcon1 from 'assets/images/icn_ybx1.svg';
import escrowIcon1 from 'assets/images/icn_escrow1.svg';
import escrowIcon2 from 'assets/images/icn_escrow2.svg';
import escrowIcon3 from 'assets/images/icn_escrow3.svg';
import warningIcon from 'assets/images/icn_warning.png';
import arrowIcon from 'assets/images/icn_arrow_right.svg';
import { VEYBX_TOKEN, YBX_TOKEN } from 'config/constants';
import { Escrow } from 'yieldblox-js';
import BigNumber from 'bignumber.js';
import { mathHelper } from 'utils/protocol';
import { UnlockEscrow } from 'types';

const Content = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	padding: 16px 36px;
`;

const RowItem = styled.div`
	padding: 14px 40px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey3};
`;

const AssetContainer = styled(Card)`
	width: 100%;
	max-height: 286px;
	padding: 10px;
	margin: 10px 0;
	background: ${({ theme }) => theme.colors.light_grey1};
	overflow: auto;
`;

const DetailWrapper = styled.div`
	min-width: 60%;
	height: 34px;
	border-radius: 17px;
	padding: 2px;
	background: ${({ theme }) => theme.colors.light_grey1};
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-left: 10px;

	p {
		padding: 0 10px;
	}
`;

const RowDetail = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
`;

const IconWrapper = styled.div<{ color: string }>`
	width: 30px;
	height: 30px;
	min-width: 30px;
	border-radius: 50%;
	border: 3px solid ${({ color }) => color};
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		color: ${({ color }) => color};
		width: 100%;
	}
`;

const Flex = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Stack = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
`;

const ButtonWrapper = styled(Flex)`
	flex-direction: column;
	width: 122px;
`;

const APRButton = styled(Button)<{ selected?: boolean }>`
	width: 100%;
	height: 141px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background: ${({ theme }) => theme.colors.light_grey1};
	border-radius: 18px;
	padding: 0;

	${({ selected, theme }) =>
		selected &&
		`
			background: rgba(109, 111, 255, 0.15);
			border: 5px solid ${theme.colors.purple};
	`}

	img {
		height: 59px;
		margin-top: 5px;
	}
`;

interface IProps extends IModalProps {}

export const EscrowModal: React.FC<IProps> = ({ open, onClose }) => {
	const theme = useTheme();
	const { walletAddress, runContract } = useWallet();
	const { prices } = usePrices();
	const { poolAssets, ybxAsset } = useYieldBlox();
	const { user, healthFactor, escrowedYbx } = useUser();

	const [status, setStatus] = useState(0);
	const [type, setType] = useState(0);
	const [escrowAmount, setEscrowAmount] = useState('');
	const [showConfirm, setShowConfirm] = useState(false);
	const [unlockedEscrows, setUnlockedEscrows] = useState<UnlockEscrow[]>([]);
	const [ybxUnlocked, setYbxUnlocked] = useState<number>(0);
	const [ybxUnlockedEarly, setYbxUnlockedEarly] = useState<number>(0);
	const [veYbxBurnt, setVeYbxBurnt] = useState<number>(0);
	const [veYbxLost, setVeYbxLost] = useState<number>(0);
	const [collateralHealthDelta, setCollateralHealthDelta] = useState<number>(0);
	const [collatEscrow, setCollatEscrow] = useState(true);

	const ybxPrice = prices[YBX_TOKEN];
	const ybxBalance = parseFloat(user.getBalance(YBX_TOKEN) || '0');
	const veYbx = parseFloat(user.getBalance(VEYBX_TOKEN) || '0');
	const escrows = user.positions?.escrowed ?? [];
	const orderedEscrows = escrows.sort((a, b) => (a.unlockTime.getTime() > b.unlockTime.getTime() ? 1 : -1));

	const newHealthFactor = mathHelper.CalculateNewHealthFactor(healthFactor, collateralHealthDelta.toString(), '0');

	const lockPools = [
		{
			multiple: 1,
			lockPeriod: 0.25,
			lockDescription: '3 month lock',
			icon: escrowIcon1,
		},
		{
			multiple: 1.5,
			lockPeriod: 0.5,
			lockDescription: '6 month lock',
			icon: escrowIcon2,
		},
		{
			multiple: 4,
			lockPeriod: 1,
			lockDescription: '1 year lock',
			icon: escrowIcon3,
		},
	];

	const handleClose = () => {
		setShowConfirm(false);
		setCollatEscrow(true);
		setStatus(0);
		setType(0);
		setEscrowAmount('');
		setYbxUnlocked(0);
		setYbxUnlockedEarly(0);
		setVeYbxBurnt(0);
		setVeYbxLost(0);
		setUnlockedEscrows([]);
		setCollateralHealthDelta(0);
		if (onClose) {
			onClose();
		}
	};

	const handleToggle = (status: number) => {
		setCollatEscrow(true);
		setType(0);
		setEscrowAmount('');
		setYbxUnlocked(0);
		setYbxUnlockedEarly(0);
		setVeYbxBurnt(0);
		setVeYbxLost(0);
		setUnlockedEscrows([]);
		setCollateralHealthDelta(0);
		setStatus(status);
	};

	const handleUnlock = (unlockEscrow: UnlockEscrow) => {
		let newUnlockedEscrows = [...unlockedEscrows];
		let matchIndex = newUnlockedEscrows.findIndex(ue => ue.balanceId === unlockEscrow.balanceId);
		if (Number(unlockEscrow.amount) === 0 && matchIndex !== -1) {
			// remove unlock if it exists
			newUnlockedEscrows.splice(matchIndex, 1);
		} else {
			// add unlock
			if (matchIndex !== -1) {
				newUnlockedEscrows[matchIndex] = unlockEscrow;
			} else {
				newUnlockedEscrows.push(unlockEscrow);
			}
		}

		let newYbxUnlocked = 0;
		let newYbxUnlockedEarly = 0;
		let newVeYbxBurnt = 0;
		let newVeYbxLost = 0;
		let newLostCollateral = 0;
		newUnlockedEscrows.forEach(e => {
			let amount = parseFloat(e.amount);
			if (e.isEarly) {
				newVeYbxLost += e.veYbx;
				newYbxUnlockedEarly += amount;
			} else {
				newVeYbxBurnt += e.veYbx;
				newYbxUnlocked += amount;
			}

			if (e.isCollateral) newLostCollateral += amount;
		});
		setUnlockedEscrows(newUnlockedEscrows);
		setYbxUnlocked(newYbxUnlocked);
		setYbxUnlockedEarly(newYbxUnlockedEarly);
		setVeYbxBurnt(newVeYbxBurnt);
		setVeYbxLost(newVeYbxLost);
		// ybx is removed
		let newCollateralHealthDelta = -1 * newLostCollateral * ybxPrice.rate * ybxAsset.data.loanToValue;
		setCollateralHealthDelta(newCollateralHealthDelta);
	};

	const handleSetEscrow = (escrowAmount: string) => {
		setEscrowAmount(escrowAmount);
		// ybx added
		if (collatEscrow) {
			let healthDelta = parseFloat(escrowAmount) * ybxPrice.rate * ybxAsset.data.loanToValue;
			setCollateralHealthDelta(healthDelta);
		}
	};

	const handleSetCollatEscrow = (asCollat: boolean) => {
		if (asCollat) {
			let healthDelta = parseFloat(escrowAmount) * ybxPrice.rate * ybxAsset.data.loanToValue;
			setCollateralHealthDelta(healthDelta);
		} else {
			setCollateralHealthDelta(0);
		}
		setCollatEscrow(asCollat);
	};

	const handleMaxClick = () => {
		if (status === 0) {
			setEscrowAmount(mathHelper.stellarMinTruncate(ybxBalance));
		} else {
			handleSetEscrow('0');
		}
	};

	const handleConfirm = async () => {
		processTransaction();
		handleClose();
	};

	const processTransaction = async () => {
		let escrowEvent: Maybe<ProtocolEvent> = undefined;
		if (walletAddress && status === 1 && unlockedEscrows.length > 0) {
			let unlockedEarly = unlockedEscrows
				.filter(e => e.isEarly)
				.map(
					(e): EscrowUnlock => {
						return { id: e.balanceId, amount: e.amount };
					}
				);
			let unlocked = unlockedEscrows
				.filter(e => !e.isEarly)
				.map(
					(e): EscrowUnlock => {
						return { id: e.balanceId, amount: e.amount };
					}
				);
			escrowEvent = new EscrowUnlockEvent(walletAddress, unlockedEarly, unlocked);
		} else if (walletAddress && status === 0) {
			escrowEvent = new EscrowLockEvent(walletAddress, escrowAmount, collatEscrow, lockPools[type].lockPeriod * 12);
		}

		if (escrowEvent) {
			await runContract(escrowEvent);
		}
	};

	const isValid = () => {
		if (status === 1) {
			return unlockedEscrows.length > 0;
		} else if (status === 0) {
			if (isNaN(Number(escrowAmount)) || Number(escrowAmount) <= 0) {
				return false;
			}
			return ybxBalance >= Number(escrowAmount);
		}
	};

	return (
		<>
			<Modal open={open && !showConfirm} onClose={handleClose} width={468}>
				<ModalHeader style={{ textAlign: 'center' }}>Lock your YBX for veYBX</ModalHeader>

				<Content>
					<Flex style={{ marginTop: 12, marginBottom: 12 }}>
						<Toggle
							style={{ height: 34 }}
							value={status}
							texts={['Lock', 'Unlock']}
							onUpdate={(value: number) => handleToggle(value)}
						/>

						<DetailWrapper style={{ lineHeight: 1 }}>
							<IconWrapper style={{ background: '#FFFFFF', marginRight: 5 }} color={theme.colors.purple}>
								<img src={ybxIcon1} alt="" />
							</IconWrapper>
							<div style={{ display: 'flex', flexWrap: 'wrap', width: '50%' }}>
								<b style={{ textAlign: 'left', width: '100%' }}>{getDisplayBalance(veYbx, '')}</b>
								<div style={{ color: theme.colors.black, textAlign: 'left', width: '100%' }}>veYBX</div>
							</div>

							<div style={{ backgroundColor: '#DEDEDE', height: '100%', width: 2 }}></div>

							<div style={{ display: 'flex', flexWrap: 'wrap', width: '50%' }}>
								<b style={{ textAlign: 'right', width: '100%' }}>{getDisplayBalance(ybxBalance, '')}</b>
								<div style={{ color: theme.colors.green, textAlign: 'right', width: '100%' }}>YBX</div>
							</div>
							<IconWrapper style={{ background: '#FFFFFF', marginLeft: 5 }} color={theme.colors.green}>
								<img src={ybxIcon} alt="" />
							</IconWrapper>
						</DetailWrapper>
					</Flex>

					{status === 1 && (
						<>
							<AssetContainer style={{ paddingTop: 0 }}>
								{status === 1 &&
									orderedEscrows.map((item, idx) => <AssetItem item={item} onChange={handleUnlock} key={idx} />)}
							</AssetContainer>
							<Flex style={{ marginTop: 6, padding: '0 10px', color: theme.colors.grey1 }}>
								<div>YBX unlocked</div>
								<div>{getDisplayBalance(ybxUnlockedEarly * 0.9 + ybxUnlocked, 'YBX')}</div>
							</Flex>
							<Flex style={{ marginTop: 6, padding: '0 10px', color: theme.colors.grey1 }}>
								<div>Fees earned</div>
								<div>{getDisplayBalance(veYbxBurnt, 'YBX')}</div>
							</Flex>
							<Flex style={{ marginTop: 6, padding: '0 10px' }}>
								<div style={{ fontWeight: 500 }}>YBX to receive</div>
								<div>
									{getDisplayBalance(ybxUnlockedEarly * 0.9 + ybxUnlocked + veYbxBurnt, '')} <GreenText>YBX</GreenText>
								</div>
							</Flex>
						</>
					)}

					{status === 0 && (
						<>
							<div style={{ marginTop: 12 }}>
								<Input
									type="number"
									min={0}
									placeholder={'YBX to lock'}
									style={{ width: '100%' }}
									buttonText="MAX"
									value={escrowAmount}
									onChange={e => handleSetEscrow(e.target.value)}
									onButtonClick={handleMaxClick}
								/>
							</div>

							<RowDetail style={{ marginTop: 20 }}>
								<b>Select your lock-up period:</b>
							</RowDetail>

							<Flex style={{ marginTop: 12 }}>
								{lockPools.map((pool, idx) => (
									<ButtonWrapper key={idx}>
										<APRButton
											style={{ width: '99%', padding: 6 }}
											selected={type === idx}
											onClick={() => setType(idx)}
										>
											<div style={{ fontSize: 17.5, color: theme.colors.black }}>
												<b>{pool.multiple.toFixed(1)}x veYBX</b>
											</div>
											<div style={{ fontSize: 14, color: theme.colors.grey1, textTransform: 'uppercase' }}>
												<b>{pool.lockDescription}</b>
											</div>
											<img style={{ height: '50%' }} src={pool.icon} alt="" />
										</APRButton>

										<div style={{ fontSize: 14, fontWeight: 500, marginTop: 3 }}>You receive</div>
										<div style={{ fontSize: 14, color: theme.colors.purple }}>
											{getDisplayBalance(
												Number.isFinite(Number(escrowAmount)) ? Number(escrowAmount) * pool.multiple : 69,
												''
											)}
											<b>veYBX</b>
										</div>
									</ButtonWrapper>
								))}
							</Flex>

							<RowDetail style={{ marginTop: 20, width: '100%', justifyContent: 'space-between' }}>
								<b>Lock as collateral?</b>
								<Toggle
									value={collatEscrow ? 1 : 0}
									texts={['', '']}
									onUpdate={value => handleSetCollatEscrow(value === 0 ? false : true)}
									style={{
										width: 60,
										height: 30,
										background: `${collatEscrow ? '' : theme.colors.light_grey}`,
										opacity: `${collatEscrow ? '1' : '0.3'}`,
									}}
								/>
							</RowDetail>
						</>
					)}
				</Content>

				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Health Factor</b>
						<b>
							{getDisplayHealthFactor(healthFactor.value)}
							<img src={arrowIcon} alt="" style={{ margin: '0 10px' }} />
							<GreenText>{getDisplayHealthFactor(newHealthFactor)}</GreenText>
						</b>
					</RowDetail>
				</RowItem>

				<ModalFooter style={{ justifyContent: 'center' }}>
					<TooltipButton
						variant="primary"
						onClick={() => setShowConfirm(true)}
						disabled={!isValid()}
						errorMessage={
							status === 0
								? 'Escrow amount must be greater than zero and less than or equal to your YBX balance.'
								: 'Unescrow amount must be greater than zero and less than or equal to your sYBX balance.'
						}
						style={{ background: theme.colors.purple, color: '#FFFFFF' }}
					>
						Submit
					</TooltipButton>
				</ModalFooter>
			</Modal>

			<ConfirmActionModal
				type={status === 0 ? 'Escrow' : 'Unlocking'}
				open={showConfirm}
				onClose={handleClose}
				onBack={() => setShowConfirm(false)}
				onConfirm={handleConfirm}
			>
				{status === 0 ? (
					<RowItem style={{ borderBottom: 'none' }}>
						<Flex style={{ margin: '6px 0' }}>
							<b>YBX locked</b>
							<div style={{ color: theme.colors.green }}>{getDisplayBalance(Number(escrowAmount), 'YBX')}</div>
						</Flex>
						<Flex style={{ margin: '6px 0' }}>
							<b>veYBX minted</b>
							<div style={{ color: theme.colors.purple }}>
								{getDisplayBalance(Number(escrowAmount) * lockPools[type].multiple, '')}
							</div>
						</Flex>
						<Flex style={{ margin: '6px 0' }}>
							<b>Lock period</b>
							<div>{lockPools[type].lockDescription}</div>
						</Flex>
						<Flex style={{ margin: '6px 0' }}>
							<b>As Collateral</b>
							<div>{collatEscrow ? 'YES' : 'NO'}</div>
						</Flex>
					</RowItem>
				) : (
					<>
						{veYbxLost > 0 && (
							<RowItem>
								<Flex style={{ margin: '30px 0' }}>
									<img src={warningIcon} alt="" />

									<p style={{ marginLeft: 20 }}>
										You will not receive any of the interest accrued over the locked period. The original amount minus a
										10% penalty of YBX escrowd will be returned.
									</p>
								</Flex>
								<Flex style={{ margin: '6px 0' }}>
									<b>YBX Unlocked Early</b>
									<div style={{ color: theme.colors.green }}>{getDisplayBalance(ybxUnlockedEarly, 'YBX')}</div>
								</Flex>
								<Flex style={{ margin: '6px 0' }}>
									<b>Early Unlock Penalty</b>
									<div style={{ color: theme.colors.red }}>{getDisplayBalance(ybxUnlockedEarly * 0.1, 'YBX')}</div>
								</Flex>
								<Flex style={{ margin: '6px 0' }}>
									<b>YBX Lost (fees+issuance)</b>
									<div style={{ color: theme.colors.red }}>{getDisplayBalance(veYbxLost, 'YBX')}</div>
								</Flex>
							</RowItem>
						)}

						<RowItem style={{ borderBottom: 'none' }}>
							<Flex style={{ margin: '6px 0' }}>
								<b>YBX returned</b>
								<div style={{ color: theme.colors.green }}>
									{getDisplayBalance(ybxUnlockedEarly * 0.9 + ybxUnlocked, 'YBX')}
								</div>
							</Flex>
							<Flex style={{ margin: '6px 0' }}>
								<b>YBX earned (fees+issuance)</b>
								<div style={{ color: theme.colors.green }}>{getDisplayBalance(veYbxBurnt, 'YBX')}</div>
							</Flex>
							<Flex style={{ margin: '6px 0' }}>
								<b>Total YBX to receive</b>
								<div style={{ color: theme.colors.green }}>
									<b>{getDisplayBalance(ybxUnlockedEarly * 0.9 + ybxUnlocked + veYbxBurnt, 'YBX')}</b>
								</div>
							</Flex>
						</RowItem>
					</>
				)}
			</ConfirmActionModal>
		</>
	);
};

interface IEscrowItem {
	item: Escrow;
	onChange: (unlockEscrow: UnlockEscrow) => void;
}

const AssetItem = ({ item, onChange }: IEscrowItem) => {
	const [unlocked, setUnlocked] = useState<boolean>(false);
	const [amount, setAmount] = useState('');

	const YBX = parseFloat(item.balance);
	const veYBX = mathHelper.CalculateVeYBXIssued(YBX, item.period);
	const escrowLocked = Date.now() < item.unlockTime.getTime();

	const handleChange = (value: string) => {
		let newUnlockEscrow: UnlockEscrow;
		if (Number.isFinite(Number(value)) && Number(value) !== 0) {
			setAmount(value);
			if (value === item.balance) {
				newUnlockEscrow = {
					balanceId: item.balanceId,
					amount: item.balance,
					veYbx: veYBX,
					isCollateral: item.collateral,
					isEarly: escrowLocked,
				};
			} else {
				let ybxUnlocked = new BigNumber(value);
				let ybxPercentage = ybxUnlocked.dividedBy(item.balance);
				let veYBXUnlocked = ybxPercentage.times(veYBX);
				newUnlockEscrow = {
					balanceId: item.balanceId,
					amount: ybxUnlocked.toFixed(7, BigNumber.ROUND_DOWN),
					veYbx: veYBXUnlocked.toNumber(),
					isCollateral: item.collateral,
					isEarly: escrowLocked,
				};
			}
		} else {
			newUnlockEscrow = {
				balanceId: item.balanceId,
				amount: '0',
				veYbx: 0,
				isCollateral: item.collateral,
				isEarly: escrowLocked,
			};
			setUnlocked(false);
			setAmount('');
		}
		onChange(newUnlockEscrow);
	};

	const handleUnlock = () => {
		setUnlocked(true);
		handleChange(item.balance);
	};

	return (
		<Flex style={{ padding: 2, height: 75, borderBottom: '1px solid #DEDEDE' }}>
			<Stack>
				<div style={{ width: '100%', textAlign: 'left' }}>
					<b>{getDisplayBalance(veYBX, '')}</b> <PurpleText>veYBX</PurpleText>
				</div>
				<div style={{ width: '100%', textAlign: 'left' }}>
					<b>{getDisplayBalance(YBX, '')}</b> <GreenText>YBX</GreenText>
				</div>
			</Stack>
			<Stack style={{ fontSize: 'small', textAlign: 'center' }}>
				{unlocked ? (
					<p></p>
				) : (
				<div>
				{escrowLocked ? (
						<b>Unlock Date</b>
					) : (
						<GreenText>
							<b>UNLOCKED</b>
						</GreenText>
					)}
					<p>{item.unlockTime.toLocaleDateString()}</p>
					<div>
						{item.collateral ? (
							<div style={{ paddingRight: 5, paddingLeft: 5, background: 'rgba(109, 111, 255, 0.5)', borderRadius: 5 }}>
								<p>COLLATERAL</p>
							</div>
						) : (
							<p></p>
						)}
					</div>
				</div>
				)}
			</Stack>
			<Flex>
				{unlocked ? (
					<Input
						type="number"
						min={0}
						max={item.balance}
						value={amount}
						placeholder="--"
						style={{ width: 160, color: '#000000' }}
						background="white"
						onChange={e => handleChange(e.target.value)}
						buttonText="MAX"
						onButtonClick={() => handleChange(item.balance)}
					/>
				) : (
					<Button
						style={{ width: 120, height: 32, color: '#FFFFFF', background: '#6D6FFF' }}
						variant="primary"
						onClick={handleUnlock}
					>
						Unlock
					</Button>
				)}
			</Flex>
		</Flex>
	);
};
