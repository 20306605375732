import React, { useContext, useEffect, useState } from 'react';
import { useYieldBlox } from './yieldblox';
import { useWallet } from './wallet';
import { useUser } from './user';

export interface ITurretContext {
	loadedFees: boolean;
	feeBalances: Record<string, number>;
	loadBalances: () => Promise<void>;
	payFees: (turretId: string, amount: string) => Promise<void>;
}

const TurretContext = React.createContext<Maybe<ITurretContext>>(undefined);

export const TurretProvider = ({ children = null as any }) => {
	const { ybxTurretHelper, turrets } = useYieldBlox();
	const { walletAddress, fetchToken, token, runTurretFeePayment } = useWallet();

	// cache local instances to keep webapi data in sync with each other
	const [loadedFees, setLoadedFees] = useState<boolean>(false);
	const [feeBalances, setFeeBalances] = useState<Record<string, number>>({});

	useEffect(() => {
		if (walletAddress && token) {
			loadBalances();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [walletAddress]);

	async function loadBalances() {
		try {
			let userToken = await fetchToken();
			if (userToken !== undefined) {
				let feePromises = turrets.map(t => ybxTurretHelper.getFeeForTurret(t.turretId, userToken ?? ''));
				let results = await Promise.all(feePromises);
				let newFeeBalances: Record<string, number> = {};
				for (let i = 0; i < turrets.length; i++) {
					newFeeBalances[turrets[i].turretId] = results[i];
				}
				setFeeBalances(newFeeBalances);
				setLoadedFees(true);
			}
		} catch (e) {
			console.error(e);
			console.log('Unable to load user.', JSON.stringify(e));
		}
	}

	async function payFees(turretId: string, amount: string) {
		try {
			let turret = turrets.find(t => t.turretId === turretId);
			if (turret) {
				let result = await runTurretFeePayment(amount, turret);
				if (result) {
					let newFeeBalances: Record<string, number> = { ...feeBalances };
					newFeeBalances[turretId] = result;
					setFeeBalances(newFeeBalances);
				}
			}
		} catch (e) {
			console.error(e);
			console.log('Unable to pay fees', JSON.stringify(e));
		}
	}

	return (
		<TurretContext.Provider
			value={{
				loadedFees: loadedFees,
				feeBalances: feeBalances,
				loadBalances: loadBalances,
				payFees: payFees,
			}}
		>
			{children}
		</TurretContext.Provider>
	);
};

export const useTurret = () => {
	const context = useContext(TurretContext);

	if (!context) {
		throw new Error('Component rendered outside the provider tree');
	}

	return context;
};
