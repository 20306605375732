import React, { useContext, useEffect, useState } from 'react';
import { Escrow, HealthFactor, YbxUser } from 'yieldblox-js';
import { useYieldBlox } from './yieldblox';
import { useWallet } from './wallet';
import { usePrices } from './price';
import { YBX_TOKEN } from 'config/constants';
import { mathHelper } from 'utils/protocol';

export interface IUserContext {
	user: YbxUser;
	healthFactor: HealthFactor;
	totalLentUSD: number;
	netIR: number;
	toClaim: number;
	escrowedYbx: number;
	activeVotes: number;
	votingPower: number;

	getBalance: (assetId: string) => Maybe<string>;
}

const UserContext = React.createContext<Maybe<IUserContext>>(undefined);

export const UserProvider = ({ children = null as any }) => {
	const { base, pool, poolAssets, getPoolAssetDataFromToken } = useYieldBlox();
	const { timestamp, prices } = usePrices();
	const { walletAddress } = useWallet();

	// cache local instances to keep webapi data in sync with each other
	const [currentUser, setCurrentUser] = useState<YbxUser>({} as YbxUser);
	const [healthFactor, setHealthFactor] = useState<HealthFactor>({
		value: 0,
		liabilitiesUSD: '0',
		borrowLimitUSD: '0',
	});
	const [toClaim, setToClaim] = useState<number>(0);
	const [escrowedYbx, setEscrowedYbx] = useState<number>(0);
	const [votePower, setVotePower] = useState<number>(0);
	const [activeVotes, setActiveVotes] = useState<number>(0);
	const [netIR, setNetIr] = useState<number>(0);
	const [totalLend, setTotalLent] = useState<number>(0);

	useEffect(() => {
		if (walletAddress && Object.keys(poolAssets).length > 0) {
			loadUser(walletAddress, true);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [walletAddress, poolAssets]);

	async function loadUser(walletAddress: string, forceUpdate: boolean = false) {
		try {
			if (forceUpdate || currentUser.id !== walletAddress) {
				let newUser = await base.user(walletAddress);
				setCurrentUser(newUser);

				// calculate health factor
				let newHealthFactor = await newUser.getHealthFactorForPool(pool.id, timestamp);
				setHealthFactor(newHealthFactor);

				// determine new issuance
				let borrows = newUser.positions.getBorrowedPositionsForPool(pool.id);
				let lends = newUser.positions.getLentPositionsForPool(pool.id);
				let newToClaim = 0;

				let weightedLendingRate = 0;
				let totalValueLent = 0;
				let ybxPrice = prices[YBX_TOKEN];
				for (const position of lends) {
					let accruedYbx = await position.getAccruedYBX(timestamp, pool);
					newToClaim += parseFloat(accruedYbx);
					//get position USD value
					let poolAssetData = getPoolAssetDataFromToken(position.assetId);
					let yTokenRate = poolAssetData.yTokenRate;
					let underlyingPrice = prices[poolAssetData.poolAsset.underlyingId];
					let positionValue = Number(position.balance) * yTokenRate * Number(underlyingPrice.rate);

					//get net rate
					let ybxIssuanceRate = mathHelper.CalculateTokenYield(
						poolAssetData.yTokenIssuance,
						poolAssetData.yTokenRate,
						underlyingPrice?.rate,
						ybxPrice?.rate ?? 1
					);
					let netLendingRate = poolAssetData.lendRate + ybxIssuanceRate;
					//weight interest rate
					weightedLendingRate += netLendingRate * positionValue;

					//add totalValue
					totalValueLent += positionValue;
				}
				let weightedBorrowingRate = 0;
				for (const position of borrows) {
					let accruedYbx = await position.getAccruedYBX(timestamp, pool);
					newToClaim += parseFloat(accruedYbx);
					//get position USD value
					let poolAssetData = getPoolAssetDataFromToken(position.assetId);
					let lTokenRate = poolAssetData.lTokenRate;
					let underlyingPrice = prices[poolAssetData.poolAsset.underlyingId];
					let positionValue = Number(position.balance) * lTokenRate * Number(underlyingPrice.rate);

					//get net rate
					let ybxIssuanceRate = mathHelper.CalculateTokenYield(
						poolAssetData.lTokenIssuance,
						poolAssetData.lTokenRate,
						underlyingPrice?.rate,
						ybxPrice?.rate ?? 1
					);

					let netLendingRate = poolAssetData.borrowRate - ybxIssuanceRate;
					//weight interest rate
					weightedBorrowingRate += netLendingRate * positionValue;
				}
				let newNetIr =
					((weightedLendingRate - weightedBorrowingRate) / (totalValueLent + Number(newHealthFactor.liabilitiesUSD))) *
					100;
				setNetIr(newNetIr);
				setTotalLent(totalValueLent);
				setToClaim(newToClaim);

				// determine escrowed YBX
				let newEscrowedYbx = newUser.positions.escrowed.reduce(
					(prev: number, e: Escrow) => prev + parseFloat(e.balance),
					0
				);
				setEscrowedYbx(newEscrowedYbx);

				// determine active votes
				let newVotePower = newUser.getVotePower();
				let newActiveVotes = await newUser.getCurrentVotes();
				let maxVote = Math.max(...newActiveVotes.map(v => parseFloat(v.amount)));
				setVotePower(parseFloat(newVotePower));
				setActiveVotes(maxVote);
			}
		} catch (e) {
			console.error(e);
			console.log('Unable to load user.', JSON.stringify(e));
		}
	}

	function getBalance(assetId: string): Maybe<string> {
		// check if user is currently loaded
		if (currentUser.id) {
			return currentUser.getBalance(assetId);
		}
		return undefined;
	}

	return (
		<UserContext.Provider
			value={{
				user: currentUser,
				healthFactor: healthFactor,
				totalLentUSD: totalLend,
				netIR: netIR,
				toClaim: toClaim,
				escrowedYbx: escrowedYbx,
				votingPower: votePower,
				activeVotes: activeVotes,
				getBalance,
			}}
		>
			{children}
		</UserContext.Provider>
	);
};

export const useUser = () => {
	const context = useContext(UserContext);

	if (!context) {
		throw new Error('Component rendered outside the provider tree');
	}

	return context;
};
