import React from 'react';
import styled from 'styled-components';
import { IModalProps, Modal } from 'components';
import { GreenText } from 'theme';

import sadSprout from 'assets/images/icn_sprout_sad.svg';
import { TxText } from 'types';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 48px;
	width: 100%;
`;

const RowItem = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 0;
`;

const IconWrapper = styled.div`
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 80%;
	}
`;

interface IProps extends IModalProps {
	xdr: Maybe<string>;
	txText: TxText;
	error: Maybe<string>;
	horizonData: any;
}

export const TransactionFailModal: React.FC<IProps> = ({ open, xdr, txText, error, horizonData, onClose }) => {
	const handleClickXdr = () => {
		if (xdr) {
			copy(xdr);
			toast.success('Copied the Transaction XDR!');
		}
	};

	let failText = '';
	switch (txText) {
		case TxText.Token:
			failText = 'Failed to generate your token! Please try again by performing another action.';
			break;
		case TxText.DepositCollateral:
		case TxText.Contract:
			failText = 'Unable to complete your request.';
			break;
	}
	return (
		<Modal open={open} onClose={onClose} width={490}>
			<Content>
				<IconWrapper>
					<img src={sadSprout} alt="" />
				</IconWrapper>
				<RowItem>
					<b style={{ fontSize: 26, lineHeight: '34px' }}>Something went wrong!</b>
				</RowItem>
				<RowItem>
					<p>{failText}</p>
				</RowItem>
				{error != null && (
					<RowItem style={{width: 400, wordWrap: 'break-word', display: 'inline-block'}}>
						<p>{error}</p>
					</RowItem>
				)}
				{horizonData != null && (
					<RowItem style={{width: 400, wordWrap: 'break-word', display: 'inline-block'}}>
						<samp style={{fontSize:'small'}}>{JSON.stringify(horizonData)}</samp>
					</RowItem>
				)}
				{xdr != null && (
					<GreenText style={{ cursor: 'pointer' }} onClick={handleClickXdr}>
						Click to Copy XDR
					</GreenText>
				)}
			</Content>
		</Modal>
	);
};
