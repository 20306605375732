import { Server, AccountResponse, Asset, ServerApi, Horizon, Transaction } from 'stellar-sdk';
import { HORIZON_URL } from 'config/constants';
import { assetHelper } from 'utils/protocol';

/**
 * Horizon Api
 * Methods to fetch data from Horizon
 */

const horizon = new Server(HORIZON_URL);

/**************
 * Public API *
 **************/

//========== Ledger ==========

/**
 * Get current ledger number
 * @returns ledger number
 */
export async function GetCurrentLedger(): Promise<number> {
	try {
		return (await horizon.ledgers().order('desc').limit(1).call()).records[0].sequence;
	} catch (error) {
		console.log('Error attempting to Get current ledger number');
		throw error;
	}
}

//========== Account ==========

/**
 * Get the account from the public key.
 * @param publicKey - The public key (address) for a Stellar Account
 * @return Account Object - https://developers.stellar.org/api/resources/accounts/object/
 */
export async function GetAccountAsync(publicKey: string): Promise<AccountResponse> {
	try {
		return await horizon.loadAccount(publicKey);
	} catch (error) {
		console.error(`Error attempting to GetAccountAsync: ${publicKey}`);
		throw error;
	}
}

/**
 * Get the accounts who have trustlines for a specific asset
 * This method is paged. Paging is complete when there are no records returned.
 * @param asset - The Asset to find holders for
 * @return List of accounts that have trustlines to an asset - https://developers.stellar.org/api/resources/accounts/list/
 */
export async function GetHoldersForAssetAsync(
	asset: Asset
): Promise<ServerApi.CollectionPage<ServerApi.AccountRecord>> {
	try {
		return await horizon.accounts().forAsset(asset).limit(200).call();
	} catch (error) {
		console.log(`Error attempting to GetHoldersForAssetPageAsync: ${JSON.stringify(asset)}`);
		throw error;
	}
}

//========== Assets ==========

/**
 * Get the asset object based on the Code / Issuer
 * @param assetCode - The Asset Code for the requested asset
 * @param assetIssuer - The public key for the issuer of the asset
 * @return Asset Object - https://developers.stellar.org/api/resources/assets/object/
 */
export async function GetAssetAsync(assetCode: string, assetIssuer: string): Promise<ServerApi.AssetRecord> {
	try {
		let records = (await horizon.assets().forCode(assetCode).forIssuer(assetIssuer).call()).records;
		if (records.length !== 1) {
			throw new Error('Uable to find unique asset');
		}
		return records[0];
	} catch (error) {
		console.error(`Error attempting to GetAssetAsync for ${assetCode} with issuer ${assetIssuer}`);
		throw error;
	}
}

//========== Transactions ==========

/**
 * Get an accounts transactions.
 * This method is paged. Paging is complete when there are no records returned.
 * @param publicKey - The Public Key of the account
 * @return Transaction List - https://developers.stellar.org/api/resources/accounts/transactions/
 */
export async function GetAccountTransactionsAsync(
	publicKey: string,
	order: 'asc' | 'desc',
	cursor?: string
): Promise<ServerApi.CollectionPage<ServerApi.TransactionRecord>> {
	try {
		if (cursor) {
			return horizon.transactions().forAccount(publicKey).order(order).cursor(cursor).limit(200).call();
		} else {
			return horizon.transactions().forAccount(publicKey).order(order).limit(200).call();
		}
	} catch (error) {
		console.error(`Error attempting to GetTransactions for account: ${publicKey}`);
		throw error;
	}
}

/**
 * Get a transaction's operations from it's hash
 * @param txHash - The transaction hash we want the operations of
 * @return Operations List - https://developers.stellar.org/api/resources/transactions/operations/
 */
export async function GetTransactionOperationsAsync(
	txHash: string
): Promise<ServerApi.CollectionPage<ServerApi.OperationRecord>> {
	try {
		return await horizon.operations().forTransaction(txHash).limit(101).call();
	} catch (error) {
		console.log(`Error attempting to GetOperations for ${txHash}`);
		throw error;
	}
}

export async function SubmitTransactionAsync(transaction: Transaction): Promise<Horizon.SubmitTransactionResponse> {
	try {
		console.log('Transaction was made successfully!');
		return await horizon.submitTransaction(transaction);
	} catch (error) {
		console.log(`Error attempting to submit transaction.`);
		console.error(error);
		throw error;
	}
}

//========== Claimable Balances ==========

/**
 * Get the claimable balances for an asset object
 * @param claimableBalanceId - The ID of the claimable balance
 * @return {Promise<any>} Claimable Balances List - https://developers.stellar.org/api/resources/claimablebalances/list/
 */
export async function GetClaimableBalancesByIdAsync(
	claimableBalanceId: string
): Promise<ServerApi.ClaimableBalanceRecord> {
	try {
		return await horizon.claimableBalances().claimableBalance(claimableBalanceId).call();
	} catch (error) {
		console.error(`Error attempting to GetClaimableBalance for ${claimableBalanceId}`);
		throw error;
	}
}

export async function GetClaimableBalancesForAccountAsync(
	accountId: string
): Promise<ServerApi.CollectionPage<ServerApi.ClaimableBalanceRecord>> {
	try {
		return await horizon.claimableBalances().claimant(accountId).limit(200).call();
	} catch (error) {
		console.error(`Error attempting to Get Pool Claimable Balances for account: ${accountId}`);
		throw error;
	}
}

export async function GetDepositClaimableBalanceAsync(
	accountId: string,
	assetId: string
): Promise<ServerApi.ClaimableBalanceRecord> {
	try {
		let asset = assetHelper.GetAssetFromId(assetId);
		let page = await horizon
			.claimableBalances()
			.sponsor(accountId)
			.asset(asset)
			.claimant(accountId)
			.order('desc')
			.limit(1)
			.call();
		return page.records[0];
	} catch (error) {
		console.error(`Error attempting to Get Pool Claimable Balances for account: ${accountId}`);
		throw error;
	}
}

export async function GetTransactionForClaimableBalanceAsync(cBalanceId: string): Promise<ServerApi.TransactionRecord> {
	try {
		return (await horizon.transactions().forClaimableBalance(cBalanceId).limit(1).order('asc').call()).records[0];
	} catch (error) {
		console.error(`Error attempting to transaction for claimable balance${cBalanceId}`);
		throw error;
	}
}

//========== Trades ==========

/**
 * Gets trades for an asset pair
 * @param {Asset} sellAsset Asset being sold
 * @param {Asset} buyAsset asset being bought
 */
export async function GetTradesAsync(sellAsset: Asset, buyAsset: Asset) {
	try {
		return await horizon.trades().forAssetPair(sellAsset, buyAsset).limit(200).order('desc').call();
	} catch (error) {
		console.error(`Error attempting to trades for${sellAsset.code}:${buyAsset.code}`);
		throw error;
	}
}

// ====================
// =   Aggregations   =
// ====================

//========== Fee Stats ==========

/**
 * Get the current fees.
 * @return The Fee Stats Object https://developers.stellar.org/api/aggregations/fee-stats/object/
 */
export async function GetFeeStatsAsync(): Promise<Horizon.FeeStatsResponse> {
	try {
		return await horizon.feeStats();
	} catch (error) {
		console.error(`Error attempting to GetFeeStats: ${JSON.stringify(error)}`);
		throw error;
	}
}

//========== Order Books ==========

/**
 * Get the current Stellar orderbook
 * @param selling - The asset being sold
 * @param buying - The asset being bought
 * @return Order Book Object - https://developers.stellar.org/api/aggregations/order-books/single/
 */
export async function GetOrderbookAsync(selling: Asset, buying: Asset): Promise<ServerApi.OrderbookRecord> {
	try {
		return await horizon.orderbook(selling, buying).call();
	} catch (error) {
		console.error(
			`Error attempting to GetOrderbook. Selling: ${JSON.stringify(selling)}, Buying: ${JSON.stringify(buying)}`
		);
		throw error;
	}
}

/**
 * Get the current TWAM for the asset.
 * @param baseId - The assetId being sold
 * @param counterId - The asset being bought
 * @return Trade Aggregation Object (NOTE - SDK DOES NOT SUPPORT TYPING) - https://developers.stellar.org/api/aggregations/trade-aggregations/object/
 */
export async function GetTradeAggregationAsync(baseId: string, counterId: string, timespan?: number): Promise<any> {
	try {
		let base = assetHelper.GetAssetFromId(baseId);
		let counter = assetHelper.GetAssetFromId(counterId);
		let currentTime = Date.now();
		let oneDayAgo = currentTime - 86400000;
		let result = await horizon
			.tradeAggregation(base, counter, oneDayAgo, currentTime, 900000, 0)
			.limit(1)
			.order('desc')
			.call();
		if (result.records.length !== 1) throw new Error('Not traded');
		return result.records[0];
	} catch (error) {
		console.error(error);
		throw error;
	}
}

export async function GetPathStrictSendAsync(
	sendAssetId: string,
	destAssetId: string,
	sendAmount: string
): Promise<Maybe<ServerApi.PaymentPathRecord>> {
	try {
		let sendAsset = assetHelper.GetAssetFromId(sendAssetId);
		let destAsset = assetHelper.GetAssetFromId(destAssetId);
		let result = await horizon.strictSendPaths(sendAsset, sendAmount, [destAsset]).limit(1).call();
		if (result.records.length < 1) {
			console.log(`No path found for ${sendAssetId} -> ${destAssetId}`);
			return undefined;
		}
		return result.records[0];
	} catch (error) {
		console.error(error);
		throw error;
	}
}

export async function GetPathStrictReceiveAsync(
	sendAssetId: string,
	destAssetId: string,
	receiveAmount: string
): Promise<Maybe<ServerApi.PaymentPathRecord>> {
	try {
		let sendAsset = assetHelper.GetAssetFromId(sendAssetId);
		let destAsset = assetHelper.GetAssetFromId(destAssetId);
		let result = await horizon.strictReceivePaths([sendAsset], destAsset, receiveAmount).limit(1).call();
		if (result.records.length < 1) {
			console.log(`No path found for ${sendAssetId} -> ${destAssetId}`);
			return undefined;
		}
		return result.records[0];
	} catch (error) {
		console.error(error);
		throw error;
	}
}
