import React, { useState } from 'react';
import styled from 'styled-components';
import {
	AccountOverview,
	BorrowModal,
	DepositModal,
	Divider,
	ManageCollateralModal,
	SectionTitle,
	Toggle,
} from 'components';
import { PoolAssetData } from 'types';
import { LendingMarket } from './LendingMarket';
import { BorrowMarket } from './BorrowMarket';
import { Lend, BasePosition } from 'yieldblox-js';
import { useUser } from 'contexts';

const RowItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export interface SortableRow {
	row: JSX.Element;
	totalValueLocked: number;
	hasPosition: boolean;
}

export const Market = () => {
	const { user } = useUser();

	const [borrow, setBorrow] = useState(false);
	const [showDepositModal, setShowDepositModal] = useState(false);
	const [showManageCollateralModal, setShowManageCollateralModal] = useState(false);
	const [showBorrowModal, setShowBorrowModal] = useState(false);
	const [pAssetData, setAssetData] = useState<Maybe<PoolAssetData>>();
	const [position, setPosition] = useState<Maybe<Lend>>(undefined);

	const handleDeposit = (item: PoolAssetData) => {
		setAssetData(item);
		setShowDepositModal(true);
	};

	const handleBorrow = (item: PoolAssetData) => {
		setAssetData(item);
		setShowBorrowModal(true);
	};

	const handleManageCollateral = (item: PoolAssetData, position: Maybe<Lend>) => {
		if (position) {
			setAssetData(item);
			setPosition(position);
			setShowManageCollateralModal(true);
		}
	};

	return (
		<>
			<AccountOverview borrow={borrow} />

			<Divider />

			<RowItem>
				<SectionTitle title={!borrow ? 'Lending Markets' : 'Borrowing Markets'} />

				<Toggle
					value={borrow ? 1 : 0}
					texts={['Lend', 'Borrow']}
					onUpdate={(value: number) => setBorrow(value === 1)}
				/>
			</RowItem>

			{!borrow ? (
				<LendingMarket onDeposit={handleDeposit} onManageCollateral={handleManageCollateral} />
			) : (
				<BorrowMarket onBorrow={handleBorrow} />
			)}

			{pAssetData && user.id && (
				<DepositModal open={showDepositModal} pAssetData={pAssetData} onClose={() => setShowDepositModal(false)} />
			)}
			{pAssetData && user.id && (
				<ManageCollateralModal
					open={showManageCollateralModal}
					pAssetData={pAssetData}
					lend={position}
					onClose={() => setShowManageCollateralModal(false)}
				/>
			)}
			{pAssetData && user.id && (
				<BorrowModal open={showBorrowModal} pAssetData={pAssetData} onClose={() => setShowBorrowModal(false)} />
			)}
		</>
	);
};
