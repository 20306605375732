import React from 'react';
import styled from 'styled-components';
import { Button } from '../Button';

const InputWrapper = styled.div<{ background?: string }>`
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-radius: 40px;
	background: ${({ theme, background }) => (background ? background : theme.colors.light_grey1)};
`;

const StyledInput = styled.input<{ error?: boolean }>`
	padding: 6px 16px;
	font-size: 16px;
	line-height: 21px;
	max-width: 100%;
	color: ${({ theme }) => theme.colors.black};
	background: transparent;
	outline: none;
	border: none;

	&:placeholder {
		color: ${({ theme }) => theme.colors.light_grey};
	}
`;

interface IProps extends React.InputHTMLAttributes<HTMLInputElement> {
	error?: boolean;
	buttonText?: string;
	onButtonClick?: () => void;
	background?: string;
}

export const Input: React.ForwardRefExoticComponent<IProps> = React.forwardRef(
	({ children, error, buttonText, background, onButtonClick, ...props }, ref: React.LegacyRef<HTMLInputElement>) => {
		return (
			<InputWrapper background={background}>
				<StyledInput ref={ref as any} error={error} {...props}>
					{children}
				</StyledInput>

				{buttonText && (
					<Button
						variant="primary"
						style={{ width: 50, height: 24, marginRight: 6, fontSize: 14 }}
						onClick={() => onButtonClick && onButtonClick()}
					>
						{buttonText}
					</Button>
				)}
			</InputWrapper>
		);
	}
);
