import React, { useState } from 'react';
import styled from 'styled-components';
import { IModalProps, Modal, ModalFooter, ModalHeader } from 'components';
import { Button } from 'components/common/Button';
import { useSettings, useWallet } from 'contexts';
import faucetIcon from 'assets/images/faucetIcon.svg';
import { TooltipButton } from 'components/common/Button/TooltipButton';
import axios from 'axios';
import loadingSpinner from 'assets/images/loading_spinner.gif';
import sadSprout from 'assets/images/icn_sprout_sad.svg';
import copy from 'copy-to-clipboard';
import { toast } from 'react-toastify';
import copyIcon from 'assets/images/icn_copy.svg';

const RowItem = styled.div`
	padding: 16px 14px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey3};
`;

const RowDetail = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: space-between;
	padding: 10px 0;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 16px;
`;

const FaucetCircle = styled.div`
	display: flex;
	width: 50px;
	height 50px;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: rgba(68, 114, 255, 1);
	margin-right: 16px;

	img {
		width: 44px;
		height: 44px;
	}
`;

const IconWrapper = styled.div`
	width: 100px;
	height: 100px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	img {
		width: 100%;
	}
`;

const KeyDiv = styled.div`
	border-radius: 7px;
	padding: 10px;
	background: ${({ theme }) => theme.colors.light_grey};

	&:hover {
		opacity: 0.75;
	}
`;

interface FaucetResult {
	publicKey: string;
	secret: string;
}

interface IProps extends IModalProps {}

export const FaucetModal: React.FC<IProps> = ({ open, onClose }) => {
	const { walletAddress } = useWallet();
	const { lastFaucet } = useSettings();

	const canFaucet = walletAddress == undefined && Date.now() > parseFloat(lastFaucet) + 60 * 1000;

	const [loading, setLoading] = useState(false);
	const [result, setResult] = useState<Maybe<FaucetResult>>(undefined);
	const [success, setSuccess] = useState<Maybe<boolean>>(undefined);

	const handleClose = () => {
		setLoading(false);
		setResult(undefined);
		setSuccess(undefined);
		if (onClose) {
			onClose();
		}
	};

	const handleFaucet = async () => {
		setLoading(true);
		try {
			let resp = await axios.post<FaucetResult>('https://qibbehteha.execute-api.us-east-1.amazonaws.com/drip');
			setResult(resp.data);
			setSuccess(true);
			setLoading(false);
		} catch (e) {
			setSuccess(false);
			setLoading(false);
		}
	};

	const handleCopy = (toCopy: string) => {
		copy(toCopy);
		if (toCopy.startsWith('S')) {
			toast.success('Copied secret to clipboard');
		} else {
			toast.success('Copied publicKey to clipboard');
		}
	};

	return (
		<>
			<Modal open={open} onClose={handleClose} width={560} style={{overflow: 'wrap'}}>
				<ModalHeader style={{ justifyContent: 'start' }}>
					<FaucetCircle>
						<img src={faucetIcon} alt="" />
					</FaucetCircle>
					Testnet Faucet
				</ModalHeader>
				<Content>
					{
						// has not generated
						!loading && success === undefined && (
							<>
								<RowItem style={{ borderBottom: 'none' }}>
									<RowDetail>
										<b>Tapping the Testnet Faucet will create a new Stellar Testnet account for you to use.</b>
									</RowDetail>
								</RowItem>

								<RowItem style={{ borderBottom: 'none' }}>
									<RowDetail>
										<p>This is a new account that will be pre-loaded with testnet assets, so you can try YieldBlox without risk!
										Click the 'Generate' button to create a Testnet account and receive its key pair.</p>
									</RowDetail>
								</RowItem>
							</>
						)
					}
					{
						// generating - use loading as short circuit
						loading && (
							<>
								<IconWrapper>
									<img src={loadingSpinner} alt="" />
								</IconWrapper>
								<RowItem style={{ borderBottom: 'none' }}>
									<RowDetail>
										<b>We are creating a new testnet account! Hang tight, this can take upwards of 30 seconds.</b>
									</RowDetail>
								</RowItem>
							</>
						)
					}
					{
						// generating - use loading as short circuit
						!loading && success === false && (
							<>
								<IconWrapper>
									<img src={sadSprout} alt="" />
								</IconWrapper>

								<RowItem style={{ borderBottom: 'none' }}>
									<RowDetail>
										<b>We were unable to generate a new account. Please try again later!</b>
									</RowDetail>
								</RowItem>
							</>
						)
					}
					{
						// generating - use loading as short circuit
						!loading && success === true && result && (
							<div style={{ width: '100%' }}>
								<RowItem style={{ borderBottom: 'none' }}>
									<RowDetail style={{marginBottom: 20}}>
										<b>Below is a newly generated testnet account.</b>
									</RowDetail>
									
									<RowDetail>
										<b>Public Key:</b>
									</RowDetail>
									<RowDetail
										style={{ cursor: 'pointer', wordWrap: 'break-word', marginBottom: 20 }}
										onClick={() => handleCopy(result.publicKey)}
									>
										<div>
											<KeyDiv style={{ fontSize: 14,maxWidth: '85vw', width: '100%', textAlign: 'left', flexWrap: 'wrap'}}>
												<p style={{ paddingBottom: 10, overflowWrap: 'break-word' }}>{result.publicKey}</p>
												<div style={{ paddingBottom: 15 }}>
													<div style={{ display: 'inline-block', width: 15, float: 'left', marginRight: 10 }}>
														<img src={copyIcon} alt="" />
													</div>
													<p style={{ display: 'inline-block', float: 'left' }}> Click to copy. </p>
												</div>
											</KeyDiv>
										</div>
									</RowDetail>
									<RowDetail>
										<b>Secret Key:</b>
									</RowDetail>
									<RowDetail
										style={{ cursor: 'pointer', wordWrap: 'break-word' }}
										onClick={() => handleCopy(result.secret)}
									>
										<div>
											<KeyDiv style={{ fontSize: 14, maxWidth: '85vw', width: '100%', textAlign: 'left', flexWrap: 'wrap'}}>
												<p style={{ paddingBottom: 10 }}>{result.secret}</p>
												<div style={{ paddingBottom: 15 }}>
													<div style={{ display: 'inline-block', width: 15, float: 'left', marginRight: 10 }}>
														<img src={copyIcon} alt="" />
													</div>
													<p style={{ display: 'inline-block', float: 'left' }}> Click to copy. </p>
												</div>
											</KeyDiv>
										</div>
									</RowDetail>
								</RowItem>

								<RowItem style={{ borderBottom: 'none' }}>
									<RowDetail>
										<b>Please copy this address and import it to a <a style={{color: '#37B24A' }} href="https://docs.ybx.script3.io/user-docs/general#how-do-i-use-yieldblox" target="_blank">supported browser wallet</a> to access YieldBlox.
										Make sure the wallet is set to "Test Network," connected to the YieldBlox Testnet, and then you'll be ready to go!</b>
									</RowDetail>
								</RowItem>
							</div>
						)
					}
				</Content>

				<ModalFooter>
					{success === undefined ? (
						<TooltipButton
							variant="primary"
							disabled={!canFaucet || loading}
							errorMessage="You already have a wallet connected, or have recently used the Testnet Faucet."
							onClick={async () => await handleFaucet()}
						>
							Generate
						</TooltipButton>
					) : (
						<Button variant="primary" onClick={handleClose}>
							Close
						</Button>
					)}
				</ModalFooter>
			</Modal>
		</>
	);
};
