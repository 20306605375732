import React from 'react';
import styled from 'styled-components';

const StyledToggle = styled.div`
	position: relative;
	width: 150px;
	height: 34px;
	border-radius: 17px;
	background: linear-gradient(
		90deg,
		${({ theme }) => theme.colors.white} 0%,
		${({ theme }) => theme.colors.green1} 50%,
		${({ theme }) => theme.colors.white} 100%
	);
	box-sizing: border-box;
	display: flex;
	align-items: center;
	padding: 0;
`;

const ToggleValue = styled.button<{ active: boolean }>`
	height: 100%;
	border-radius: 17px;
	color: ${({ theme, active }) => (active ? theme.colors.white : theme.colors.black)};
	background: ${({ theme, active }) => (active ? theme.colors.green : 'transparent')};
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
	line-height: 21px;
	font-weight: 500;
	border: none;
	outline: none;
`;

interface IProps extends React.HTMLAttributes<HTMLElement> {
	value: number;
	texts: string[];
	onUpdate?: (newValue: number) => void;
}

export const Toggle: React.FC<IProps> = ({ value, texts, onUpdate, ...props }) => {
	const handleUpdate = (idx: number) => {
		if (onUpdate) {
			if (texts.length === 2 && value === idx) {
				onUpdate(1 - idx);
				return;
			}

			onUpdate(idx);
		}
	};

	return (
		<StyledToggle {...props}>
			{texts.map((text, idx) => (
				<ToggleValue
					active={value === idx}
					onClick={() => handleUpdate(idx)}
					style={{ width: `${100 / texts.length}%` }}
					key={idx}
				>
					{text}
				</ToggleValue>
			))}
		</StyledToggle>
	);
};
