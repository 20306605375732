import React, { useContext, useEffect, useRef, useState } from 'react';
import { YBX_TOKEN } from 'config/constants';
import { useYieldBlox } from './yieldblox';

export interface IPriceContext {
	prices: Record<string, PricePair>;
	timestamp: number;
	getAllPrices: (timestamp: number) => Promise<void>;
}

export interface PricePair {
	base: string; // ASSETID FORMAT
	counter: string; // FOREX FORMAT
	rate: number;
	reverseRate: number;
}

const PriceContext = React.createContext<Maybe<IPriceContext>>(undefined);

export const PriceProvider = ({ children = null as any }) => {
	const { pool, getProtocolPriceFetcher } = useYieldBlox();
	const [timestamp, setTimestamp] = useState<number>(Date.now());
	const [prices, setPrices] = useState<Record<string, PricePair>>({});

	const priceTimer = useRef<NodeJS.Timeout | null>(null);

	const updatePriceTimestamp = () => {
		let newTimestamp = Date.now() - 30 * 1000;
		getAllPrices(newTimestamp);
		setTimestamp(newTimestamp);

		if (priceTimer.current) {
			clearTimeout(priceTimer.current);
		}
		priceTimer.current = setTimeout(() => {
			updatePriceTimestamp();
		}, 5 * 60 * 1000);
	};

	useEffect(() => {
		updatePriceTimestamp();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pool]);

	/**
	 * Fetch the exchange rates from USD for all supported counter AssetIds.
	 * Sets the prices in the Prices context.
	 * @param timestamp - The timestamp to fetch the TWAPs from
	 * @returns Promise
	 */
	async function getAllPrices(timestamp: number): Promise<void> {
		if (pool.id === undefined) {
			// pool is not yet loaded, skip this round.
			console.log('Attempted to find prices before pool loaded.');
			return Promise.resolve();
		}
		console.log('Fetching prices...');
		let priceFetcher = getProtocolPriceFetcher(timestamp);
		let supportedAssets = pool.getSupportedAssets();
		let newPrices: Record<string, PricePair> = {};
		for (let poolAsset of supportedAssets) {
			let priceToUsd = await priceFetcher.getProtocolPrice(poolAsset);
			let priceAsNum = priceToUsd.toNumber();
			if (poolAsset.underlyingId === YBX_TOKEN) {
			}
			newPrices[poolAsset.underlyingId] = {
				base: poolAsset.underlyingId,
				counter: 'USD',
				rate: priceAsNum,
				reverseRate: 1 / priceAsNum,
			};
		}
		setPrices(newPrices);
		console.log('Prices set.');
		return Promise.resolve();
	}

	return <PriceContext.Provider value={{ prices, timestamp, getAllPrices }}>{children}</PriceContext.Provider>;
};

export const usePrices = () => {
	const context = useContext(PriceContext);

	if (!context) {
		throw new Error('Component rendered outside the provider tree');
	}

	return context;
};
