import React, { useState } from 'react';
import styled from 'styled-components';
import { BalanceLine, MintEvent, NATIVE, POOL_ACCOUNT_1_ID } from 'yieldblox-js';
import { AssetIcon, Card, IModalProps, Input, Modal, ModalFooter, ModalHeader } from 'components';
import { GreenText } from 'theme';
import { ConfirmActionModal } from './ConfirmActionModal';
import { PoolAssetData, TxType } from 'types';
import { usePrices, useUser, useWallet } from 'contexts';
import { mathHelper } from 'utils/protocol';
import { getAdjBalance, getAdjDisplayBalance, getDisplayBalance, getDisplayHealthFactor, getDisplayRate } from 'utils';

import arrowIcon from 'assets/images/icn_arrow_right.svg';
import { TooltipButton } from 'components/common/Button/TooltipButton';
import { YBX_TOKEN } from 'config/constants';
import BigNumber from 'bignumber.js';

const RowItem = styled.div`
	padding: 14px 40px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey3};
`;

const AssetNameWrapper = styled(RowItem)`
	display: flex;
	align-items: center;
`;

const RowDetail = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;

	p {
		color: ${({ theme }) => theme.colors.grey2};
	}
`;

const DetailCard = styled(Card)`
	background: ${({ theme }) => theme.colors.light_grey1};
	padding: 12px 15px;
	display: flex;
	align-items: center;
	font-weight: 500;
	height: 64px;

	p {
		color: ${({ theme }) => theme.colors.black};
	}
`;

interface IProps extends IModalProps {
	pAssetData: PoolAssetData;
}

export const DepositModal: React.FC<IProps> = ({ open, onClose, pAssetData }) => {
	const { walletAddress, runContract } = useWallet();
	const { prices } = usePrices();
	const { healthFactor, user, getBalance } = useUser();

	const [showConfirm, setShowConfirm] = useState(false);
	const [depositAmount, setDepositAmount] = useState('');
	const [collateralAmount, setCollateralAmount] = useState('');
	const [collateralHealthDelta, setCollateralHealthDelta] = useState<number>(0);

	const position = user.positions?.getLentPosition(pAssetData.poolAsset.poolTokenId);
	const positionValue = position?.balance ?? '0';
	const posUnderlyingValue = parseFloat(positionValue) * pAssetData.yTokenRate;
	const posUncollateralValue = parseFloat(position?.uncollateralizedBalance ?? '0') * pAssetData.yTokenRate;

	const assetCode = pAssetData.poolAsset.underlyingId.split(':')[0];
	const userBalance = parseFloat(getBalance(pAssetData.poolAsset.underlyingId) ?? '0');

	const assetPrice = prices[pAssetData.poolAsset.underlyingId];
	const ybxPrice = prices[YBX_TOKEN];
	const tokenYield = mathHelper.CalculateTokenYield(
		pAssetData.yTokenIssuance,
		pAssetData.yTokenRate,
		assetPrice?.rate,
		ybxPrice?.rate ?? 1
	);
	const loanToValue = pAssetData.poolAsset.data.loanToValue;

	const marketSize = pAssetData.totalUnderlying;
	const price = prices[pAssetData.poolAsset.underlyingId];
	const tvl = marketSize * price.rate;

	const newHealthFactor = mathHelper.CalculateNewHealthFactor(healthFactor, collateralHealthDelta.toString(), '0');

	const handleClose = () => {
		setShowConfirm(false);
		setDepositAmount('');
		setCollateralAmount('');
		setCollateralHealthDelta(0);
		if (onClose) {
			onClose();
		}
	};

	const handleMaxDeposit = () => {
		let effectiveBalance = userBalance;
		if (pAssetData.poolAsset.underlyingId === NATIVE) {
			effectiveBalance = Math.max(effectiveBalance - 50, 0);
		}
		setDepositAmount(effectiveBalance.toFixed(7));
	};

	const handleSetCollateral = (value: string) => {
		setCollateralAmount(value);
		let collateralEffect = Number(value) * loanToValue;
		setCollateralHealthDelta(getAdjBalance(collateralEffect, price?.rate));
	};

	const handleMaxCollateral = () => {
		handleSetCollateral((Number(depositAmount) || 0).toString());
	};

	const handleConfirm = async () => {
		processTransaction();
		handleClose();
	};

	const processTransaction = async () => {
		if (walletAddress) {
			let depositBalLine: BalanceLine = {
				assetId: pAssetData.poolAsset.underlyingId,
				amount: depositAmount,
			};
			let inputCollateral: BigNumber;
			if (collateralAmount === '') {
				inputCollateral = new BigNumber(0);
			} else {
				inputCollateral = new BigNumber(collateralAmount);
			}
			let adjCollateralAmount = inputCollateral
				.dividedBy(pAssetData.yTokenRate.toString())
				.times(0.99)
				.toFixed(7, BigNumber.ROUND_DOWN);
			let collateralBalLine: BalanceLine = {
				assetId: pAssetData.poolAsset.poolTokenId,
				amount: adjCollateralAmount,
			};
			let depositEvent = new MintEvent(
				walletAddress,
				POOL_ACCOUNT_1_ID,
				[collateralBalLine],
				depositBalLine,
				undefined
			);
			await runContract(depositEvent);
		}
	};

	const isValid = () => {
		if (isNaN(Number(depositAmount)) || Number(depositAmount) <= 0) {
			return false;
		}
		return userBalance >= Number(depositAmount);
	};

	return (
		<>
			<Modal open={open && !showConfirm} onClose={handleClose} width={700}>
				<ModalHeader>Deposit</ModalHeader>

				<AssetNameWrapper>
					<AssetIcon assetCode={assetCode} />
					<h6 style={{ marginLeft: '1rem' }}>{assetCode}</h6>
				</AssetNameWrapper>

				<RowItem>
					<RowDetail style={{ overflowX: 'auto' }}>
						<DetailCard>
							<div>
								<GreenText>Market</GreenText>
								<p>Size</p>
							</div>

							<div style={{ marginLeft: 16, textAlign: 'right' }}>
								<p>{getDisplayBalance(tvl, '')}</p>
								<p>{assetCode}</p>
							</div>
						</DetailCard>

						<DetailCard>
							<div>
								<GreenText>Interest</GreenText>
								<p>Rate</p>
							</div>

							<p style={{ marginLeft: 16 }}>
								{getDisplayRate(pAssetData.yTokenRate)}
								<GreenText>%</GreenText>
							</p>
						</DetailCard>

						<DetailCard>
							<div>
								<p>Token</p>
								<GreenText>Yield</GreenText>
							</div>

							<p style={{ marginLeft: 16 }}>
								{getDisplayRate(tokenYield)}
								<GreenText>%</GreenText>
							</p>
						</DetailCard>

						<DetailCard>
							<div>
								<GreenText>LTV</GreenText>
							</div>

							<p style={{ marginLeft: 16 }}>
								{getDisplayRate(loanToValue)}
								<GreenText>%</GreenText>
							</p>
						</DetailCard>
					</RowDetail>
				</RowItem>

				<RowItem>
					<RowDetail>
						<b>Current Deposit</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}>
							<b>{getDisplayBalance(posUnderlyingValue + posUncollateralValue, assetCode)}</b>
							<p>${getAdjDisplayBalance(posUnderlyingValue + posUncollateralValue, price.rate)}</p>
						</div>
					</RowDetail>

					<RowDetail>
						<b>Current Collateral</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}>
							<b>{getDisplayBalance(posUnderlyingValue, assetCode)}</b>
							<p>${getAdjDisplayBalance(posUnderlyingValue, price.rate)}</p>
						</div>
					</RowDetail>

					<RowDetail>
						<b>
							<GreenText>Available to deposit</GreenText>
						</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}>
							<b>
								<GreenText>{getDisplayBalance(userBalance, assetCode)}</GreenText>
							</b>
							<p>${getAdjDisplayBalance(userBalance, price.rate)}</p>
						</div>
					</RowDetail>
				</RowItem>

				<RowItem>
					<RowDetail>
						<b>Deposit Amount</b>
						<Input
							type="number"
							min={0}
							value={depositAmount}
							placeholder="--"
							style={{ width: 120 }}
							onChange={e => setDepositAmount(e.target.value)}
							buttonText="MAX"
							onButtonClick={handleMaxDeposit}
						/>
					</RowDetail>

					<RowDetail>
						<div>
							<b>Collateralized Amount</b>
							<p>Effective Collateral: {getDisplayBalance((Number(collateralAmount) || 0) * loanToValue, assetCode)}</p>
						</div>
						<Input
							type="number"
							min={0}
							value={collateralAmount}
							placeholder="--"
							style={{ width: 120 }}
							onChange={e => handleSetCollateral(e.target.value)}
							buttonText="MAX"
							onButtonClick={handleMaxCollateral}
						/>
					</RowDetail>
				</RowItem>

				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Health Factor</b>
						<b>
							{getDisplayHealthFactor(healthFactor.value)}
							<img src={arrowIcon} alt="" style={{ margin: '0 10px' }} />
							<GreenText>{getDisplayHealthFactor(newHealthFactor)}</GreenText>
						</b>
					</RowDetail>
				</RowItem>

				<ModalFooter>
					<TooltipButton
						variant="primary"
						onClick={() => setShowConfirm(true)}
						disabled={!isValid()}
						errorMessage="Cannot deposit more than your wallet balance"
					>
						Deposit
					</TooltipButton>
				</ModalFooter>
			</Modal>

			<ConfirmActionModal
				type="Deposit"
				assetId={pAssetData.poolAsset.underlyingId}
				open={showConfirm}
				onClose={handleClose}
				onBack={() => setShowConfirm(false)}
				onConfirm={handleConfirm}
			>
				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Deposit Amount</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
							<b>{getDisplayBalance(Number(depositAmount), assetCode)}</b>
							<p>${getAdjDisplayBalance(Number(depositAmount), price.rate)}</p>
						</div>
					</RowDetail>
					<RowDetail>
						<b>Collateralized Amount</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
							<b>{getDisplayBalance(Number(collateralAmount), assetCode)}</b>
							<p>${getAdjDisplayBalance(Number(collateralAmount), price.rate)}</p>
						</div>
					</RowDetail>
					<RowDetail>
						<b>Effective Collateral</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
							<b>{getDisplayBalance((Number(collateralAmount) || 0) * loanToValue, assetCode)}</b>
							<p>${getAdjDisplayBalance((Number(collateralAmount) || 0) * loanToValue, price.rate)}</p>
						</div>
					</RowDetail>
				</RowItem>
			</ConfirmActionModal>
		</>
	);
};
