import React from 'react';
import styled from 'styled-components';

const StyledTable = styled.table`
	position: relative;
	width: 100%;
	overflow-x: scroll;
	border-radius: 18px;
	background: ${({ theme }) => theme.colors.white};
	font-size: 16px;
	line-height: 21px;
	border-spacing: 0;

	thead {
		tr {
			height: 70px;

			th {
				text-align: center;
				padding: 0 1rem;
				border-bottom: 2px solid ${({ theme }) => theme.colors.light_grey1};
			}
		}
	}

	tbody {
		tr {
			height: 60px;

			td {
				text-align: center;
				padding: 0 1rem;
				border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey1};
			}

			&:hover {
				background: rgba(54, 176, 74, 0.1);
			}

			&:last-child {
				td {
					border-bottom: none;
				}
			}
		}
	}
`;

export const Table: React.FC<React.HTMLAttributes<HTMLTableElement>> = ({ children, ...props }) => {
	return <StyledTable {...props}>{children}</StyledTable>;
};
