import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Button, Card, IModalProps, Modal, ModalFooter, ModalHeader, Input } from 'components';
import { GreenText } from 'theme';
import { getAdjBalance, getAdjDisplayBalance, getDisplayBalance, getDisplayRate, getFormattedNumber } from 'utils';
import { ConfirmActionModal } from './ConfirmActionModal';
import { usePrices, useUser, useWallet } from 'contexts';
import { PoolAssetData, Position, TxText, TxType } from 'types';
import { BurnEvent, MintEvent } from 'yieldblox-js';

import arrowIcon from 'assets/images/icn_arrow_right.svg';
import { mathHelper, positionHelper } from 'utils/protocol';
import { BalanceLine, Lend, POOL_ACCOUNT_1_ID, BasePosition } from 'yieldblox-js';

const RowItem = styled.div`
	padding: 14px 40px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey3};
`;

const RowDetail = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
`;

const DetailCard = styled(Card)`
	background: ${({ theme }) => theme.colors.light_grey1};
	padding: 12px 15px;
	display: flex;
	align-items: center;
	font-weight: 500;
`;

const HealthCard = styled(DetailCard)`
	box-shadow: 0 7px 13px 0 rgba(0, 0, 0, 0.01);
	background: linear-gradient(
		90deg,
		${({ theme }) => theme.colors.green1} 0%,
		${({ theme }) => theme.colors.light_grey1} 100%
	);
`;

interface IProps extends IModalProps {
	pAssetData: PoolAssetData;
	lend: Maybe<Lend>;
}

export const ManageCollateralModal: React.FC<IProps> = ({ open, onClose, pAssetData, lend }) => {
	const { walletAddress, runContract, signTransaction, submitTransaction } = useWallet();
	const { healthFactor } = useUser();
	const { prices } = usePrices();

	const yBalance = lend ? parseFloat(lend.balance) : 0;
	const yUncollatBalance = lend ? parseFloat(lend.uncollateralizedBalance) : 0;
	const balance = yBalance * pAssetData.yTokenRate;
	const uncollatBalance = yUncollatBalance * pAssetData.yTokenRate;
	const totalValue = balance + uncollatBalance;

	const assetCode = pAssetData.poolAsset.underlyingId.split(':')[0];
	const loanToValue = pAssetData.poolAsset.data.loanToValue;

	// find price
	const pricePair = prices[pAssetData.poolAsset.underlyingId];

	const [showConfirm, setShowConfirm] = useState(false);
	const [amount, setAmount] = useState<number>(0);
	const [collateralDif, setCollateralDif] = useState<number>(0);
	const [collateralHealthDelta, setCollateralHealthDelta] = useState<number>(0);

	const newHealthFactor = mathHelper.CalculateNewHealthFactor(healthFactor, collateralHealthDelta.toString(), '0');

	useEffect(() => {
		setAmount(balance);
	}, [lend]);

	const handleClose = () => {
		setShowConfirm(false);
		setAmount(balance);
		setCollateralDif(0);
		setCollateralHealthDelta(0);
		if (onClose) {
			onClose();
		}
	};

	const handleSetCollateral = (value: number) => {
		let finalCBalance = value > totalValue ? totalValue : value;
		setAmount(finalCBalance);
		setCollateralDif(value - balance);
		let collateralEffect = (finalCBalance - balance) * loanToValue;
		setCollateralHealthDelta(getAdjBalance(collateralEffect, pricePair?.rate));
	};

	const handleConfirm = async () => {
		processTransaction();
		handleClose();
	};

	const handleMax = () => {
		handleSetCollateral(totalValue);
	};

	const [symbolsArr] = useState(['e', 'E', '+', '-']);

	const processTransaction = async () => {
		if (walletAddress && lend) {
			if (collateralDif > 0) {
				let yAmount = collateralDif / pAssetData.yTokenRate;
				let collateralBalLine: BalanceLine = {
					assetId: lend.assetId,
					amount: yAmount.toFixed(7),
				};
				let event = new MintEvent(walletAddress, POOL_ACCOUNT_1_ID, [collateralBalLine], undefined, undefined);
				// some amount collateralized required a turrert signature
				if (balance > 0) {
					await runContract(event);
				} else {
					let collateralXdr = await positionHelper.CreateCollateralFromNone(
						walletAddress,
						lend.assetId,
						yAmount.toFixed(7)
					);
					let signedCollatXdr = await signTransaction(collateralXdr, TxText.Contract);
					if (signedCollatXdr) {
						await submitTransaction(signedCollatXdr);
					}
				}
			} else if (collateralDif < 0) {
				let yAmount = (-1 * collateralDif) / pAssetData.yTokenRate;
				let withdrawal: BalanceLine = {
					assetId: lend.assetId,
					amount: yAmount.toFixed(7),
				};
				let event = new BurnEvent(walletAddress, POOL_ACCOUNT_1_ID, withdrawal, undefined);
				await runContract(event);
			}
		}
	};

	return (
		<>
			<Modal open={open && !showConfirm} onClose={handleClose} width={600}>
				<ModalHeader>Manage Collateral</ModalHeader>

				<RowItem>
					<RowDetail style={{ overflowX: 'auto' }}>
						<DetailCard>
							<div>
								<p>Total</p>
								<GreenText>Deposit</GreenText>
							</div>
							<p style={{ marginLeft: 16 }}>{getDisplayBalance(totalValue, assetCode)}</p>
						</DetailCard>

						<DetailCard>
							<div>
								<p>Deposit</p>
								<GreenText>collateralized</GreenText>
							</div>
							<p style={{ marginLeft: 16 }}>{getDisplayBalance(balance, assetCode)}</p>
						</DetailCard>

						<HealthCard>
							<div>
								<p>Health</p>
								<GreenText>factor</GreenText>
							</div>

							<p style={{ marginLeft: 16 }}>
								{Number.isFinite(healthFactor.value) ? getFormattedNumber(healthFactor.value) : 'N/A'}
							</p>
						</HealthCard>
					</RowDetail>
				</RowItem>

				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Collateral</b>
						<p>
							{getDisplayRate(Number(amount) / totalValue)}% -{' '}
							<GreenText>{getDisplayBalance(Number(amount), assetCode)}</GreenText>
						</p>
					</RowDetail>

					<Input
						type="number"
						onKeyDown={evt => symbolsArr.includes(evt.key) && evt.preventDefault()}
						min={0}
						max={totalValue}
						value={Number(amount)}
						style={{ width: '100%' }}
						onChange={e => handleSetCollateral(Number(e.target.value))}
						buttonText="100%"
						onButtonClick={handleMax}
					/>
				</RowItem>

				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Health Factor</b>
						<b>
							{Number.MAX_SAFE_INTEGER === healthFactor.value ? 'N/A' : healthFactor.value.toFixed(2)}
							<img src={arrowIcon} alt="" style={{ margin: '0 10px' }} />
							<GreenText>{Number.MAX_SAFE_INTEGER === newHealthFactor ? 'N/A' : newHealthFactor}</GreenText>
						</b>
					</RowDetail>
				</RowItem>

				<ModalFooter>
					<Button variant="primary" onClick={() => setShowConfirm(true)}>
						Submit
					</Button>
				</ModalFooter>
			</Modal>

			<ConfirmActionModal
				type="Manage Collateral"
				assetId={pAssetData.poolAsset.underlyingId}
				open={showConfirm}
				onClose={handleClose}
				onBack={() => setShowConfirm(false)}
				onConfirm={handleConfirm}
			>
				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Collateralized Amount</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
							<b>{getDisplayBalance(Number(amount), assetCode)}</b>
							<p>${getAdjDisplayBalance(Number(amount), pricePair.rate)}</p>
						</div>
					</RowDetail>

					<RowDetail>
						<b>Effective Collateral</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
							<b>{getDisplayBalance((Number(amount) || 0) * loanToValue, assetCode)}</b>
							<p>${getAdjDisplayBalance((Number(amount) || 0) * loanToValue, pricePair.rate)}</p>
						</div>
					</RowDetail>
				</RowItem>
			</ConfirmActionModal>
		</>
	);
};
