import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Header } from 'components';
import { Market } from './Market';
import { Profile } from './Profile';
import { Governance } from './Governance';
import { TurretPage } from './Turret';
import { Grid, Snackbar } from '@material-ui/core';
import { TosModal } from 'components/modals/TosModal';
import { useSettings, useWallet } from 'contexts';
import { RegionBlockModal } from 'components/modals/RegionBlockModal';
import { toast } from 'react-toastify';

const Container = styled.div`
	width: 100%;
	min-height: 100vh;
	overflow-x: hidden;
	background: ${({ theme }) => theme.colors.light_grey};
	padding: 1px;
`;

const Content = styled.div`
	padding: 50px;
	width: 100%;
`;

const Banner = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	flex-direction: row;
	padding: 15px;
	width: 100%;
	background: rgba(255, 94, 0, 0.15);
	color: rgba(255, 94, 0, 1);
	border-style: solid;
	border-color: rgba(255, 94, 0, 1);

	p {
		margin-right: 4px;
	}
`;

export const Main = () => {
	const { acknowledge } = useWallet();
	const { geoCheck } = useSettings();

	const [showTosModal, setShowTosModal] = useState(!acknowledge);

	// useEffect(() => {
	// 	toast.info('The Horizon Testnet server has been having intermittent issues that might affect your experience.', {
	// 		closeOnClick: true,
	// 		autoClose: 10000,
	// 		bodyStyle: {
	// 			color: '#FFFFFF',
	// 		},
	// 	});
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, []);

	return (
		<>
			<Container>
				<Header />
				<Banner>
					<p>This is a testnet application.</p>
					<p
						style={{ cursor: 'pointer', textDecoration: 'underline' }}
						onClick={() =>
							window.open(
								`https://medium.com/script3/sprout-coma-a-new-horizon-on-the-same-course-da131915e796`,
								`coma`
							)
						}
					>
						See Medium article for more information
					</p>
					<p>about mainnet.</p>
				</Banner>
				<Content>
					<Grid item container>
						<Grid item xs={false} md={1} lg={2} />
						<Grid item xs={12} md={10} lg={8}>
							<Switch>
								<Route path="/market" exact component={Market} />
								<Route path="/profile" exact component={Profile} />
								<Route path="/governance" exact component={Governance} />
								<Route path="/turret" exact component={TurretPage} />
								<Redirect to="/market" />
							</Switch>
						</Grid>
						<Grid item xs={false} md={1} lg={2} />
					</Grid>
				</Content>
			</Container>
			<TosModal open={showTosModal} onClose={() => setShowTosModal(false)} />
			<RegionBlockModal
				open={!showTosModal && !geoCheck}
				onClose={() => {
					console.log('Unable to close geoBlock');
				}}
			/>
		</>
	);
};
