import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { ClaimEvent, POOL_ACCOUNT_1_ID } from 'yieldblox-js';

import { IModalProps, Modal } from 'components';
import { Grid } from '@material-ui/core';
import { ConfirmActionModal } from './ConfirmActionModal';

import ybxIcon from 'assets/images/icn_ybx.svg';
import { TooltipButton } from 'components/common/Button/TooltipButton';
import { getDisplayBalance, getFormattedNumber } from 'utils';
import { useUser, useWallet } from 'contexts';
import { TxType } from 'types';
import { YBX_TOKEN } from 'config/constants';

const Content = styled.div`
	width: 375px;
	height: auto;
	display: flex;
	flex-direction: column;
	padding: 16px 20px;
`;

const IconWrapper = styled.div<{ color: string }>`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	border: 2px solid ${({ color }) => color};
	display: flex;
	align-items: center;
	justify-content: center;
	background: white;

	img {
		color: ${({ color }) => color};
		width: 100%;
	}
`;

const RowItem = styled.div`
	padding: 14px 40px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey3};
`;

const RowDetail = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;

	p {
		color: ${({ theme }) => theme.colors.grey2};
	}
`;

const GridCont = styled.div`
	display: grid;
	grid-template-columns: auto auto auto auto;
	grid-gap: 20px;
	align-items: center;
`;

const DetailWrapper = styled.div`
	width: 180px;
	height: 34px;
	border-radius: 17px;
	margin-top: 10px;
	padding: 2px;
	padding-right: 20px;
	background: ${({ theme }) => theme.colors.light_grey1};
	display: flex;
	justify-content: space-between;
	align-items: center;

	p {
		padding: 0 10px;
	}
`;

interface IProps extends IModalProps {}

export const ClaimModal: React.FC<IProps> = ({ open, onClose }) => {
	const theme = useTheme();
	const { walletAddress, runContract } = useWallet();
	const { toClaim } = useUser();

	const [showConfirm, setShowConfirm] = useState(false);

	const handleClose = () => {
		setShowConfirm(false);
		if (onClose) {
			onClose();
		}
	};

	const handleConfirm = async () => {
		processTransaction();
		handleClose();
	};

	const processTransaction = async () => {
		if (walletAddress) {
			let claimEvent = new ClaimEvent(walletAddress, POOL_ACCOUNT_1_ID, 'CLAIM');
			await runContract(claimEvent);
		}
	};

	const isValid = () => {
		if (Number(toClaim) <= 0) {
			return false;
		}
		return true;
	};

	return (
		<>
			<Modal open={open} onClose={onClose}>
				<Content>
					<GridCont>
						{/*
            -----NEXT ISSUANCE-----
            <Grid item md={12} sm={12}>
              <text style={{ fontSize: 20, fontWeight: 900 }}> Next Issuance </text> 
              <text style={{ fontSize: 14, fontWeight: 900, color: theme.colors.grey1}}> in 22 hours </text> 
              <DetailWrapper>
                <IconWrapper color={theme.colors.green}>
                  <img src={ybxIcon} alt="" />
                </IconWrapper>
                <div style={{ fontWeight: 900 }}>0.0000</div>
                <span style={{ color: theme.colors.green, marginLeft:8, fontWeight: 900 }}>YBX</span>
              </DetailWrapper>
            </Grid>

            <Grid item md={12} sm={12}>
              <VLine></VLine>
            </Grid>
            */}

						<Grid item md={12} sm={12}>
							<p style={{ fontSize: 20, fontWeight: 900 }}> Available to Claim </p>
							<DetailWrapper>
								<IconWrapper color={theme.colors.green}>
									<img src={ybxIcon} alt="" />
								</IconWrapper>
								<div style={{ fontWeight: 900 }}>{getFormattedNumber(toClaim)}</div>
								<span style={{ color: theme.colors.green, marginLeft: 8, fontWeight: 900 }}>YBX</span>
							</DetailWrapper>
						</Grid>

						<Grid item md={12} sm={12}>
							<TooltipButton
								variant="primary"
								onClick={() => setShowConfirm(true)}
								disabled={!isValid()}
								errorMessage="Nothing to claim"
								style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-evenly' }}
							>
								Claim
							</TooltipButton>
						</Grid>
					</GridCont>
				</Content>
			</Modal>
			<ConfirmActionModal
				type="Claim YBX"
				assetId={YBX_TOKEN}
				open={showConfirm}
				onClose={handleClose}
				onBack={() => setShowConfirm(false)}
				onConfirm={handleConfirm}
			>
				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Claim YBX</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
							<b>{getDisplayBalance(toClaim, 'YBX')}</b>
						</div>
					</RowDetail>
				</RowItem>
			</ConfirmActionModal>
		</>
	);
};
