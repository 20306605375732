import React from 'react';
import styled from 'styled-components';
import { AssetIcon, Button, IModalProps, Modal, ModalFooter, ModalHeader } from 'components';

const RowItem = styled.div`
	padding: 14px 40px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey3};
`;

const AssetNameWrapper = styled(RowItem)`
	display: flex;
	align-items: center;
`;

interface IProps extends IModalProps {
	type: string;
	assetId?: string;
	onConfirm: () => void;
	onBack: () => void;
}

export const ConfirmActionModal: React.FC<IProps> = ({ open, onClose, type, onConfirm, onBack, assetId, children }) => {
	const assetCode = assetId?.split(':')[0] || 'XLM';

	return (
		<Modal open={open} onClose={onClose} width={520}>
			<ModalHeader>Confirm {type}</ModalHeader>

			{assetId && (
				<AssetNameWrapper>
					<AssetIcon assetCode={assetCode} />
					<h6 style={{ marginLeft: '1rem' }}>{assetCode}</h6>
				</AssetNameWrapper>
			)}

			{children}

			<ModalFooter>
				<Button variant="secondary" style={{ left: '10%' }} onClick={onBack}>
					Back
				</Button>
				<Button variant="primary" onClick={onConfirm}>
					Confirm
				</Button>
			</ModalFooter>
		</Modal>
	);
};
