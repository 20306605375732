import React from 'react';
import styled from 'styled-components';
import { IModalProps, Modal } from 'components';
import { GreenText } from 'theme';

import checkIcon from 'assets/images/icn_check_circle.svg';
import { TxText } from 'types';

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 48px;
`;

const RowItem = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 0;
`;

const IconWrapper = styled.div`
	width: 100px;
	height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 80%;
	}
`;

interface IProps extends IModalProps {
	txHash: Maybe<string>;
	txText: TxText;
}

export const TransactionSuccessModal: React.FC<IProps> = ({ open, txHash, txText, onClose }) => {
	let successText = '';
	switch (txText) {
		case TxText.Token:
			successText = 'Your token was successfully generated!';
			break;
		case TxText.DepositCollateral:
		case TxText.Contract:
			successText = 'Your transaction was successfully submitted!';
			break;
	}
	return (
		<Modal open={open} onClose={onClose} width={490}>
			<Content>
				<IconWrapper>
					<img src={checkIcon} alt="" />
				</IconWrapper>
				<RowItem>
					<b style={{ fontSize: 26, lineHeight: '34px' }}>{successText}</b>
				</RowItem>
				{txHash != undefined && (
					<RowItem>
						<p>Check out the</p>&nbsp;
						<GreenText
							style={{ cursor: 'pointer' }}
							onClick={() => window.open(`https://stellar.expert/explorer/testnet/tx/${txHash}`, `${txHash}`)}
						>
							transaction details
						</GreenText>
					</RowItem>
				)}
			</Content>
		</Modal>
	);
};
