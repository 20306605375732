import { Account, Asset, Networks, Operation, Server, Transaction, TransactionBuilder } from 'stellar-sdk';
import { Turret, YbxUser } from 'yieldblox-js';

/**
 *
 * @param turret
 * @param user
 */
export async function getTurretFeePaymentXdr(
	amount: string,
	turret: Turret,
	publicKey: string
): Promise<{ xdr: string; hash: string }> {
	let stellar = new Server('https://horizon-testnet.stellar.org');
	let userAccount = await stellar.loadAccount(publicKey);
	let feeTxBuilder = new TransactionBuilder(userAccount, {
		fee: '10000',
		networkPassphrase: Networks.TESTNET,
	});

	feeTxBuilder.addOperation(
		Operation.payment({
			asset: Asset.native(),
			amount: amount,
			destination: turret.accountId,
		})
	);

	let tx = feeTxBuilder.setTimeout(30).build();
	let hash = tx.hash().toString('hex');
	let xdr = tx.toXDR();
	return { xdr, hash };
}
