import BigNumber from 'bignumber.js';
import {
  PoolAsset,
  XdrProposal,
  BalanceLine,
  AllocationProposal
} from 'yieldblox-js';

export interface SortableRow {
	row: JSX.Element;
	totalValueLocked: number;
	hasPosition: boolean;
}

export interface ScripteeTransaction {
	xdr: string;
	signed_xdr?: string;
	operationUid: string;
	publicKey: string;
}

export interface ShippingInformation {
	size: string;
	country: string;
	name: string;
	addressLn1: string;
	addressLn2?: string;
	city: string;
	stateProvinceRegion: string;
	zipCode: string;
	phoneNumber: string;
}

export enum WalletType {
	Freighter = 'Freighter',
	Albedo = 'Albedo',
}

// Market Types
export interface LendAssetData {
	assetId: string;
	interestRate: number;
	tokenYield: number;
	maxDeposit: Maybe<number>;
	currentDeposit: Maybe<number>;
	collateral: Maybe<number>;
}

export interface PoolAssetData {
	poolAsset: PoolAsset;
	borrowRate: number;
	lendRate: number;
	utilRate: number;
	totalLiabilities: number;
	totalUnderlying: number;
	poolBalance: number;
	yTokenIssuance: number;
	lTokenIssuance: number;
	yTokenRate: number;
	lTokenRate: number;
}

export interface XdrProposalData {
	proposal: XdrProposal;
	yes: number;
	no: number;
	total: number;
	quorumPct: number;
}

export interface AllocationProposalData {
	proposal: AllocationProposal;
	votes: BalanceLine[];
	total: number;
}

export interface AssetConstants {
	assetId: string;
	assetKey: string;
	trackingAccountId: string;
	baseRateConstant: number; // 2 digit, divide by 100
	slope0: number; // 3 digits, div 10
	slope1: number; // 3 digits, div 10
	slope2: number; // 3 digits, div 10
	slope3: number; // 3 digits, div 10
	rateThreshold1: number; // 2 digit, divide by 100
	rateThreshold2: number; // 2 digit, divide by 100
	rateThreshold3: number; // 2 digit, divide by 100
	liquidationFactor: number; // 2 digit, divide by 100
	liquidationIncentive: number; // 2 digit, divide by 100
	lendingAllocation: number; // 2 digit, divide by 100
	borrowingAllocation: number; // 2 digit, divide by 100
	ybxFeeAllocation: number; // 2 digit, divide by 100
}

// Profile Types
export enum PositionType {
	Borrow = 'borrow',
	Lend = 'lend',
}

export enum InterestType {
	Fixed = 'FIXED',
	Float = 'VARIABLE',
}

// NOTE - All balances are represented by the underlying
export interface Position {
	balanceId: string;
	assetId: string;
	protocolAssetId: string;
	type: PositionType;
	interestType: InterestType;
	interestSpotRate: number;
	balance: number;
	collateralBalance: number;
	healthEffect: number;
	lastModifiedTime: Date;
}

export interface Escrow {
	balanceId: string;
	lockedYbx: boolean;
	balance: number;
	unlockTimestamp: number;
}

export interface UnlockResult {
	ybxReturned: number;
	feesEarned: number;
	unlockedEarly: boolean;
	ybxReturnedEarly?: number;
}

export interface UnlockEscrow {
	balanceId: string;
	amount: string;
	veYbx: number;
	isCollateral: boolean;
	isEarly: boolean;
}

export interface CollateralDeposit {
	base: number;
	usd: number;
	borrowPower: number;
	borrowUsd: number;
}

export interface CollateralRepayment {
	base: number;
	usd: number;
	underlying: number;
	path: string[];
}

export enum TxText {
	Default,
	DepositCollateral,
	Contract,
	Token,
}

export enum TxType {
	Burn,
	Claim,
	GovManageProposal,
	GovCustomProposal,
	GovSwapProposal,
	GovLockProposal,
	GovAllocationProposal,
	Liquidate,
	Mint,
	Rate,
	Repay,
	Escrow,
	Vote,
}
