import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Lend, PoolAsset, MintEvent, BalanceLine, POOL_ACCOUNT_1_ID, STELLAR_MIN } from 'yieldblox-js';
import { mathHelper } from 'utils/protocol';
import { AssetIcon, Card, IModalProps, Input, Modal, ModalFooter, ModalHeader, Toggle } from 'components';
import { GreenText } from 'theme';
import { ConfirmActionModal } from './ConfirmActionModal';
import { CollateralDeposit, PoolAssetData, TxType } from 'types';
import { usePrices, useUser, useWallet, useYieldBlox } from 'contexts';
import {
	getAdjBalance,
	getAdjDisplayBalance,
	getDisplayBalance,
	getDisplayHealthFactor,
	getDisplayRate,
	getFormattedNumber,
} from 'utils';

import arrowIcon from 'assets/images/icn_arrow_right.svg';
import { TooltipButton } from 'components/common/Button/TooltipButton';

const RowItem = styled.div`
	padding: 14px 40px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey3};
`;

const AssetNameWrapper = styled(RowItem)`
	display: flex;
	align-items: center;
`;

const RowDetail = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;
`;

const DetailCard = styled(Card)`
	background: ${({ theme }) => theme.colors.light_grey1};
	padding: 12px 15px;
	display: flex;
	align-items: center;
	font-weight: 500;
	height: 64px;
`;

const AssetContainer = styled(Card)`
	width: 100%;
	max-height: 156px;
	padding: 10px;
	margin: 10px 0;
	background: ${({ theme }) => theme.colors.light_grey1};
	overflow: auto;
`;

const Flex = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

interface IProps extends IModalProps {
	pAssetData: PoolAssetData;
}

export const BorrowModal: React.FC<IProps> = ({ open, onClose, pAssetData }) => {
	const { walletAddress, runContract } = useWallet();
	const { getPoolAssetDataFromToken } = useYieldBlox();
	const { prices } = usePrices();
	const { healthFactor, user } = useUser();
	const theme = useTheme();

	const [showConfirm, setShowConfirm] = useState(false);
	const [allowAdditional, setAllowAdditional] = useState(false);
	const [borrowAmount, setBorrowAmount] = useState('');
	const [liabilityHealthDelta, setLiabilityHealthDelta] = useState<number>(0);

	// track deposit additional collateral
	const [depositAmounts, setDepositsAmounts] = useState<{
		[assetId: string]: CollateralDeposit;
	}>({});
	const [depositUsdTotal, setDepositUsdTotal] = useState(0);
	const [depositBorrowPowerTotal, setDepositBorrowPowerTotal] = useState(0);
	const [depositBorrowUsdTotal, setDepositBorrowUsdTotal] = useState(0);
	const collateralizablePositions = user.positions?.lent?.filter(l => parseFloat(l.uncollateralizedBalance) > 0) ?? [];

	const assetCode = pAssetData.poolAsset.underlyingId.split(':')[0];

	// find position for asset and user
	const position = user.positions?.getBorrowedPosition(pAssetData.poolAsset.underlyingId);
	const posUnderlying = position ? parseFloat(position.balance) * pAssetData.lTokenRate : 0;

	// find price
	const pricePair = prices[pAssetData.poolAsset.underlyingId];
	const marketSize = pAssetData.totalUnderlying;
	const tvl = marketSize * pricePair.rate;

	const maxBorrowUSD = (parseFloat(healthFactor.borrowLimitUSD) - parseFloat(healthFactor.liabilitiesUSD)) / 1.12;
	const newHealthFactor = mathHelper.CalculateNewHealthFactor(
		healthFactor,
		depositBorrowUsdTotal.toString(),
		liabilityHealthDelta.toString()
	);

	const handleClose = () => {
		setShowConfirm(false);
		setAllowAdditional(false);
		setBorrowAmount('');
		setLiabilityHealthDelta(0);
		setDepositsAmounts({});
		setDepositUsdTotal(0);
		setDepositBorrowUsdTotal(0);
		setDepositBorrowPowerTotal(0);
		if (onClose) {
			onClose();
		}
	};

	const handleSetAllowAdditional = (value: boolean) => {
		setAllowAdditional(value);
		setDepositsAmounts({});
		setDepositUsdTotal(0);
		setDepositBorrowUsdTotal(0);
		setDepositBorrowPowerTotal(0);
	};

	const handleSetBorrow = (value: string) => {
		setBorrowAmount(value);
		setLiabilityHealthDelta(getAdjBalance(Number(value), pricePair?.rate));
	};

	const handleSetBorrowUsdTotal = (depositBorrowUsd: number) => {
		let currentBorrowPower = getAdjBalance(Number(borrowAmount), pricePair?.rate) - depositBorrowUsd;
		setLiabilityHealthDelta(currentBorrowPower);
		setDepositBorrowUsdTotal(depositBorrowUsd);
	};

	const handleMaxBorrow = () => {
		handleSetBorrow(getAdjBalance(maxBorrowUSD + depositBorrowUsdTotal, pricePair?.reverseRate).toString());
	};

	const handleConfirm = async () => {
		processTransaction();
		handleClose();
	};

	const processTransaction = async () => {
		if (walletAddress) {
			let collateralizations: BalanceLine[] = [];
			for (let dAssetId in depositAmounts) {
				let deposit = depositAmounts[dAssetId];
				if (deposit.base < Number(STELLAR_MIN)) continue;
				let collateralData = getPoolAssetDataFromToken(dAssetId);
				collateralizations.push({
					assetId: dAssetId,
					amount: (deposit.base / collateralData.yTokenRate).toFixed(7),
				});
			}
			let borrowBalLine: BalanceLine = {
				assetId: pAssetData.poolAsset.underlyingId,
				amount: borrowAmount,
			};
			let borrowEvent = new MintEvent(walletAddress, POOL_ACCOUNT_1_ID, collateralizations, undefined, borrowBalLine);
			await runContract(borrowEvent);
		}
	};

	const handleDepositWithCollateral = (value: string, valueAssetId: string, ltv: number) => {
		let collateralData = getPoolAssetDataFromToken(valueAssetId);
		let priceToUsd = prices[collateralData.poolAsset.underlyingId];
		let base = Number(value);
		let usdVal = base * priceToUsd.rate * collateralData.yTokenRate;
		let borrowUsd = usdVal * ltv;
		let borrowVal = borrowUsd * pricePair.reverseRate;

		let deltaUsdTotal = usdVal;
		let deltaBorrowUsd = borrowUsd;
		let deltaUnderlyingUsed = borrowVal;
		if (depositAmounts[valueAssetId]) {
			// already exists. Remove old from totals.
			deltaUsdTotal -= depositAmounts[valueAssetId].usd;
			deltaBorrowUsd -= depositAmounts[valueAssetId].borrowUsd;
			deltaUnderlyingUsed -= depositAmounts[valueAssetId].borrowPower;
		}
		setDepositUsdTotal(depositUsdTotal + deltaUsdTotal);
		handleSetBorrowUsdTotal(depositBorrowUsdTotal + deltaBorrowUsd);
		setDepositBorrowPowerTotal(depositBorrowPowerTotal + deltaUnderlyingUsed);
		setDepositsAmounts({
			...depositAmounts,
			[valueAssetId]: {
				base: base,
				usd: usdVal,
				borrowPower: borrowVal,
				borrowUsd: borrowUsd,
			},
		});
	};

	const isValid = () => {
		let maxB = getAdjBalance(maxBorrowUSD + depositBorrowUsdTotal, pricePair?.reverseRate);
		if (isNaN(Number(borrowAmount)) || Number(borrowAmount) <= 0) {
			return false;
		}
		return (Number(maxB) ?? 0) >= Number(borrowAmount);
	};

	return (
		<>
			<Modal open={open && !showConfirm} onClose={onClose} width={750}>
				<ModalHeader>Borrow</ModalHeader>

				<AssetNameWrapper>
					<AssetIcon assetCode={assetCode} />
					<h6 style={{ marginLeft: '1rem' }}>{assetCode}</h6>
				</AssetNameWrapper>

				<RowItem style={{ overflowX: 'auto' }}>
					<RowDetail>
						<DetailCard>
							<div>
								<GreenText>Market</GreenText>
								<p>Size</p>
							</div>

							<div style={{ marginLeft: 16 }}>
								<p>{getDisplayBalance(tvl, '')}</p>
								<p>{assetCode}</p>
							</div>
						</DetailCard>

						<DetailCard>
							<div>
								<GreenText>Liquidity</GreenText>
							</div>

							<div style={{ marginLeft: 16 }}>
								<p>{getDisplayBalance(pAssetData.poolBalance, '')}</p>
								<p>{assetCode}</p>
							</div>
						</DetailCard>

						<DetailCard>
							<div>
								<GreenText>Interest</GreenText>
								<p>Rate</p>
							</div>

							<p style={{ marginLeft: 16 }}>
								{getDisplayRate(pAssetData.lTokenRate)}
								<GreenText>%</GreenText>
							</p>
						</DetailCard>

						<DetailCard>
							<div>
								<GreenText>LTV</GreenText>
							</div>

							<p style={{ marginLeft: 16 }}>
								{getDisplayRate(pAssetData.poolAsset.data.loanToValue)}
								<GreenText>%</GreenText>
							</p>
						</DetailCard>
					</RowDetail>
				</RowItem>

				<RowItem>
					<RowDetail>
						<b>Current Borrow</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}>
							<b>{getDisplayBalance(posUnderlying, assetCode)}</b>
							<p>${getAdjDisplayBalance(posUnderlying, pricePair?.rate)}</p>
						</div>
					</RowDetail>

					<RowDetail>
						<b>Maximum Borrow</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}>
							<b>
								{getAdjDisplayBalance(maxBorrowUSD + depositBorrowUsdTotal, pricePair?.reverseRate)} {assetCode}
							</b>
						</div>
					</RowDetail>
				</RowItem>

				<RowItem>
					<RowDetail>
						<b>Amount to borrow</b>
						<Input
							type="number"
							min={0}
							value={borrowAmount}
							placeholder="--"
							style={{ width: 120 }}
							onChange={e => handleSetBorrow(e.target.value)}
							buttonText="MAX"
							onButtonClick={handleMaxBorrow}
						/>
					</RowDetail>

					<RowDetail>
						<b>Deposit Additional collateral</b>
						<Toggle
							value={allowAdditional ? 1 : 0}
							texts={['', '']}
							style={{
								width: 60,
								height: 30,
								background: `${allowAdditional ? '' : theme.colors.light_grey}`,
								opacity: `${allowAdditional ? '1' : '0.3'}`,
							}}
							onUpdate={value => handleSetAllowAdditional(value === 0 ? false : true)}
						/>
					</RowDetail>

					{allowAdditional && (
						<>
							<RowDetail>
								<AssetContainer>
									{collateralizablePositions.map((item, idx) => (
										<AssetItem item={item} onChange={handleDepositWithCollateral} key={idx} />
									))}
								</AssetContainer>
							</RowDetail>

							<RowDetail>
								<b>Additional Borrow Power</b>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}>
									<b>{getDisplayBalance(depositBorrowPowerTotal, assetCode)}</b>
									<p>${getAdjDisplayBalance(depositBorrowPowerTotal, pricePair?.rate)}</p>
								</div>
							</RowDetail>

							<RowDetail>
								<b>Total Collateral Deposit</b>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}>
									<p>
										{getAdjDisplayBalance(depositUsdTotal, pricePair?.reverseRate)} {assetCode}
									</p>
									<p>${getFormattedNumber(depositUsdTotal)}</p>
								</div>
							</RowDetail>
						</>
					)}
				</RowItem>

				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Health Factor</b>
						<b>
							{getDisplayHealthFactor(healthFactor.value)}
							<img src={arrowIcon} alt="" style={{ margin: '0 10px' }} />
							<GreenText>{getDisplayHealthFactor(newHealthFactor)}</GreenText>
						</b>
					</RowDetail>
				</RowItem>

				<ModalFooter>
					<TooltipButton
						variant="primary"
						onClick={() => setShowConfirm(true)}
						disabled={!isValid()}
						errorMessage="Cannot borrow more than your max"
					>
						Borrow
					</TooltipButton>
				</ModalFooter>
			</Modal>

			<ConfirmActionModal
				type="Borrow"
				assetId={assetCode}
				open={showConfirm}
				onClose={handleClose}
				onBack={() => setShowConfirm(false)}
				onConfirm={handleConfirm}
			>
				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Borrow Amount</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
							<b>{getDisplayBalance(Number(borrowAmount), assetCode)}</b>
							<p>${getAdjDisplayBalance(Number(borrowAmount), pricePair?.rate)}</p>
						</div>
					</RowDetail>

					{allowAdditional && (
						<>
							<RowDetail>
								<b>Collateral Added</b>
								<p></p>
								<b>Borrow Limit</b>
							</RowDetail>
							<RowDetail>
								<div style={{ width: '100%' }}>
									{Object.keys(depositAmounts).map((key, idx) => {
										const deposit = depositAmounts[key];
										const itemAssetCode = key.split(':')[0].substring(3);
										return (
											<RowDetail key={idx}>
												<p style={{ width: 100, textAlign: 'right' }}>{itemAssetCode}</p>
												<div
													style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}
												>
													<p>{getDisplayBalance(deposit.base, itemAssetCode)}</p>
													<p>${getFormattedNumber(deposit.borrowUsd)}</p>
												</div>
											</RowDetail>
										);
									})}
								</div>
							</RowDetail>
						</>
					)}
				</RowItem>
			</ConfirmActionModal>
		</>
	);
};

interface IAssetItem {
	item: Lend;
	onChange: (value: string, valueAssetId: string, ltv: number) => void;
}

// TODO: use yTokens for accuracy ??
const AssetItem = ({ item, onChange }: IAssetItem) => {
	const { getPoolAssetDataFromToken } = useYieldBlox();
	const theme = useTheme();

	const pAssetData = getPoolAssetDataFromToken(item.assetId);
	const assetCode = pAssetData.poolAsset.underlyingId.split(':')[0];
	const ltv = pAssetData.poolAsset.data.loanToValue;

	const uncollatUnderlying = parseFloat(item.uncollateralizedBalance) * pAssetData.yTokenRate;

	const [amount, setAmount] = useState('');

	const handleChange = (value: string) => {
		setAmount(value);
		onChange(value, item.assetId, ltv);
	};

	const handleMax = () => {
		setAmount(uncollatUnderlying.toString());
		onChange(uncollatUnderlying.toString().toString(), item.assetId, ltv);
	};

	return (
		<Flex style={{ padding: 5 }}>
			<Flex>
				<AssetIcon assetCode={assetCode} />
				<span style={{ marginLeft: '1rem' }}>
					{assetCode} <GreenText>({getDisplayRate(ltv)}%)</GreenText>
				</span>
			</Flex>

			<Flex>
				<Input
					type="number"
					min={0}
					value={amount}
					placeholder="--"
					background={theme.colors.white}
					style={{ width: 120 }}
					onChange={e => handleChange(e.target.value)}
					buttonText="MAX"
					onButtonClick={handleMax}
				/>
				<span style={{ marginLeft: 10, width: 100 }}>{getDisplayBalance(uncollatUnderlying, assetCode)}</span>
			</Flex>
		</Flex>
	);
};
