import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { BalanceLine, MintEvent, NATIVE, POOL_ACCOUNT_1_ID, STELLAR_MIN, Turret } from 'yieldblox-js';
import { AssetIcon, Button, Card, IModalProps, Input, Modal, ModalFooter, ModalHeader } from 'components';
import { GreenText } from 'theme';
import { ConfirmActionModal } from './ConfirmActionModal';
import { PoolAssetData, TxType } from 'types';
import { usePrices, useTurret, useUser, useWallet, useYieldBlox } from 'contexts';
import { mathHelper } from 'utils/protocol';
import { getAdjBalance, getAdjDisplayBalance, getDisplayBalance, getDisplayRate, getShortenWalletAddress } from 'utils';

import arrowIcon from 'assets/images/icn_arrow_right.svg';
import { TooltipButton } from 'components/common/Button/TooltipButton';
import turretIcon from 'assets/images/icn_turret.png';
import { YBX_TOKEN } from 'config/constants';
import BigNumber from 'bignumber.js';

const RowItem = styled.div`
	padding: 14px 40px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey3};
`;

const TurretIcon = styled.img`
	width: 38px;
	height: 38px;
`;

const RowDetail = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 5px 0;

	p {
		color: ${({ theme }) => theme.colors.grey2};
	}
`;

const DetailCard = styled(Card)`
	background: ${({ theme }) => theme.colors.light_grey1};
	padding: 12px 15px;
	display: flex;
	align-items: center;
	font-weight: 500;
	height: 64px;

	p {
		color: ${({ theme }) => theme.colors.black};
	}
`;

interface IProps extends IModalProps {
	turret: Turret;
}

export const TurretModal: React.FC<IProps> = ({ open, onClose, turret }) => {
	const { walletAddress } = useWallet();
	const { contractHash } = useYieldBlox();
	const { user } = useUser();
	const { prices } = usePrices();
	const { loadBalances, feeBalances, payFees } = useTurret();
	const theme = useTheme();

	const [showConfirm, setShowConfirm] = useState(false);
	const [depositAmount, setDepositAmount] = useState('');

	const availableXlm = Number(user.id ? user.getBalance(NATIVE) ?? '0' : '0');
	const feeBalance = feeBalances[turret.turretId];
	const xlmPricePair = prices[NATIVE];

	const xlmFee = 1000 / turret.info.divisor.run;

	const handleClose = () => {
		setShowConfirm(false);
		setDepositAmount('');
		if (onClose) {
			onClose();
		}
	};

	const handleConfirm = async () => {
		processFeePayment();
		handleClose();
	};

	const processFeePayment = async () => {
		if (walletAddress) {
			let amountXlm = Number(depositAmount);
			if (Number.isFinite(amountXlm) && amountXlm >= 0.01) {
				await payFees(turret.turretId, amountXlm.toFixed(7));
			}
		}
	};

	const isValid = () => {
		if (isNaN(Number(depositAmount)) || Number(depositAmount) < 0.1) {
			return false;
		}
		return Math.min(availableXlm, 10) >= Number(depositAmount);
	};

	return (
		<>
			<Modal open={open && !showConfirm} onClose={handleClose} width={700}>
				<ModalHeader>Add Turret Fees</ModalHeader>

				<RowItem>
					<RowDetail style={{ justifyContent: 'left' }}>
						<TurretIcon src={turretIcon} alt="" />
						<div style={{ marginLeft: '1rem' }}>
							<span>{turret.rootUrl}</span>
							<p style={{ fontSize: 12, color: theme.colors.grey1 }}>{getShortenWalletAddress(turret.accountId)}</p>
						</div>
					</RowDetail>
				</RowItem>

				<RowItem>
					<RowDetail>
						<b>Version</b>
						<div style={{ color: theme.colors.blue }}>
							<p>{turret.info.version}</p>
						</div>
					</RowDetail>
					<RowDetail>
						<b>Horizon</b>
						<div style={{ color: theme.colors.blue }}>
							<p>{turret.info.horizon}</p>
						</div>
					</RowDetail>
					<RowDetail>
						<b>Runtime Fee</b>
						<div style={{ color: theme.colors.blue }}>
							<p>{`${xlmFee.toFixed(7)} XLM/sec`}</p>
						</div>
					</RowDetail>
					<RowDetail>
						<b>Contract Signer</b>
						<div style={{ color: theme.colors.blue }}>
							<p>{getShortenWalletAddress(turret.signerId)}</p>
						</div>
					</RowDetail>
					<RowDetail>
						<b>Hash</b>
						<div style={{ color: theme.colors.blue }}>
							<p>{contractHash}</p>
						</div>
					</RowDetail>
				</RowItem>

				<RowItem>
					{feeBalance !== undefined ? (
						<RowDetail>
							<b>Current Balance</b>
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}>
								<b>{getDisplayBalance(feeBalance, 'XLM')}</b>
								<p>${getAdjDisplayBalance(feeBalance, xlmPricePair?.rate)}</p>
							</div>
						</RowDetail>
					) : (
						<Button variant="primary" onClick={() => loadBalances()}>
							Fetch Balance
						</Button>
					)}

					<RowDetail>
						<b>Wallet Balance</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 200 }}>
							<b>{getDisplayBalance(availableXlm, 'XLM')}</b>
							<p>${getAdjDisplayBalance(availableXlm, xlmPricePair?.rate)}</p>
						</div>
					</RowDetail>

					<RowDetail>
						<b>Deposit Amount</b>
						<Input
							type="number"
							min={0}
							value={depositAmount}
							placeholder="--"
							style={{ width: 120 }}
							onChange={e => setDepositAmount(e.target.value)}
							buttonText="MAX"
							onButtonClick={() => setDepositAmount('10')}
						/>
					</RowDetail>
				</RowItem>

				<ModalFooter>
					<TooltipButton
						variant="primary"
						onClick={() => setShowConfirm(true)}
						disabled={!isValid()}
						errorMessage="Please select a valid deposit between 0.1 and 10 XLM"
					>
						Add Fees
					</TooltipButton>
				</ModalFooter>
			</Modal>

			<ConfirmActionModal
				type="Add Fees"
				assetId={NATIVE}
				open={showConfirm}
				onClose={handleClose}
				onBack={() => setShowConfirm(false)}
				onConfirm={handleConfirm}
			>
				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Fee Deposit Amount</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
							<b>{`${Number(depositAmount).toFixed(7)} XLM`}</b>
							<p>${getAdjDisplayBalance(Number(depositAmount), xlmPricePair?.rate)}</p>
						</div>
					</RowDetail>
					<RowDetail>
						<b>Turret</b>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
							<b>{turret.rootUrl}</b>
						</div>
					</RowDetail>
				</RowItem>
			</ConfirmActionModal>
		</>
	);
};
