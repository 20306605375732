import React from 'react';
import styled from 'styled-components';
import { IModalProps, Modal } from 'components';
import { WalletType } from 'types';
import { toast } from 'react-toastify';

import ybxIcon from 'assets/images/icn_ybx.svg';
import albedoIcon from 'assets/images/wallet_albedo.svg';
import freighterIcon from 'assets/images/wallet_freighter.svg';

const RowItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	border-bottom: 2px solid ${({ theme }) => theme.colors.light_grey3};
	border-top: 2px solid ${({ theme }) => theme.colors.light_grey3};
`;

const WalletButton = styled.button`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	border: none;
	outline: none;
	background: ${({ theme }) => theme.colors.white};
	border-top: 1px solid ${({ theme }) => theme.colors.light_grey3};
	cursor: pointer;
	color: ${({ theme }) => theme.colors.black};

	&:hover {
		background: ${({ theme }) => theme.colors.light_grey};
	}

	&:first-child {
		border-top: none;
	}
`;

const LogoIconWrapper = styled.div`
	width: 80px;
	height: 80px;
	border-radius: 50%;
	background: ${({ theme }) => theme.colors.green1};
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
	}
`;

const Title = styled.div`
	font-size: 26px;
	font-weight: 500;
	line-height: 34px;
	text-align: center;
	margin-top: 10px;
	color: ${({ theme }) => theme.colors.black};
`;

const WalletIcon = styled.img`
	height: 40px;
	margin-right: 10px;
`;

const QRIcon = styled.img`
	height: 80px;
	margin-right: 20px;
`;

interface IProps extends IModalProps {
	connect: (_walletType: WalletType) => void;
}

export const WalletConnectModal: React.FC<IProps> = ({ open, onClose, connect }) => {
	const handleWalletClick = async (type: WalletType) => {
		try {
			await connect(type);
			if (onClose) {
				onClose();
			}
			toast.success(`Wallet connected!`);
		} catch (e: any) {
			toast.error(`Unable to connect to wallet.`);
		}
	};

	return (
		<Modal open={open} onClose={onClose} width={490}>
			<RowItem style={{ flexDirection: 'column', borderTop: 'none' }}>
				<LogoIconWrapper>
					<img src={ybxIcon} alt="" />
				</LogoIconWrapper>

				<Title>Connect your wallet</Title>
			</RowItem>

			<div>
				<WalletButton
					onClick={
						//() => toast.warning('This wallet is current not supported.')
						async () => await handleWalletClick(WalletType.Albedo)
					}
				>
					<WalletIcon src={albedoIcon} alt="" />
					<h5>Albedo</h5>
				</WalletButton>

				<WalletButton onClick={async () => await handleWalletClick(WalletType.Freighter)}
				style={{borderBottomLeftRadius: 20, borderBottomRightRadius: 20}}
				>
					<WalletIcon src={freighterIcon} alt="" />
					<h5>Freighter</h5>
				</WalletButton>

			</div>
		</Modal>
	);
};
