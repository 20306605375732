import React, { useState } from 'react';
import styled from 'styled-components';
import { Lend } from 'yieldblox-js';
import { AssetIcon, Button, Card, WithdrawModal, ManageCollateralModal } from 'components';
import { GreenText } from 'theme';
import { usePrices, useYieldBlox } from 'contexts';
import { mathHelper } from 'utils/protocol';
import { getAdjDisplayBalance, getCollateralRate, getDisplayBalance, getDisplayRate } from 'utils';

import { YBX_TOKEN } from 'config/constants';

const Container = styled(Card)`
	min-width: 294px;
	width: 250px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0;
	margin: 10px;
`;

const Divider = styled.div`
	width: 100%;
	height: 2px;
	background: ${({ theme }) => theme.colors.light_grey3};
`;

const Row = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: flex-start;
	padding: 16px;
`;

const Title = styled.div`
	font-weight: bold;
	font-size: 22px;
	line-height: 29px;
`;

const Status = styled.div`
	background: ${({ theme }) => theme.colors.green1};
	padding: 2px 8px;
	border-radius: 16px;
`;

const Block = styled.div`
	width: 50%;
	padding: 16px;
`;

interface IProps {
	position: Lend;
}

// TODO: Morning alex refactor this to return two seprate things
export const LendTile: React.FC<IProps> = ({ position }) => {
	const { getPoolAssetDataFromToken } = useYieldBlox();
	const { prices } = usePrices();

	const poolAssetData = getPoolAssetDataFromToken(position.assetId);
	const assetCode = poolAssetData.poolAsset.underlyingId.split(':')[0];

	const pricePair = prices[poolAssetData.poolAsset.underlyingId];
	const ybxPrice = prices[YBX_TOKEN];
	const tokenYield = mathHelper.CalculateTokenYield(
		poolAssetData.yTokenIssuance,
		poolAssetData.yTokenRate,
		pricePair?.rate,
		ybxPrice?.rate ?? 1
	);

	const underlyingBalance = parseFloat(position.balance) * poolAssetData.yTokenRate;
	const underlyingUnCollat = parseFloat(position.uncollateralizedBalance) * poolAssetData.yTokenRate;
	const totalValue = underlyingBalance + underlyingUnCollat;

	const [showWithdrawModal, setShowWithdrawModal] = useState(false);
	const [showCollateralModal, setShowCollateralModal] = useState(false);

	return (
		<Container>
			<Row>
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<AssetIcon assetCode={assetCode} />

					<div style={{ marginLeft: '1rem' }}>
						<Title>{assetCode}</Title>
						<p>{getDisplayBalance(totalValue, assetCode)}</p>
						<GreenText>${getAdjDisplayBalance(totalValue, pricePair?.rate)}</GreenText>
					</div>
				</div>

				<Status
					style={{
						color: '#FF8A00',
						background: '#FF8A0026',
					}}
				>
					Lent
				</Status>
			</Row>

			<Divider />

			<Row style={{ padding: 0 }}>
				<Block>
					<p style={{ marginBottom: 8 }}>
						<b>Current Token Issuance</b>
					</p>
					<p>
						<GreenText>{getDisplayRate(tokenYield)}%</GreenText>
					</p>
				</Block>

				<Block>
					<p style={{ marginBottom: 8 }}>
						<b>Current Interest Rate</b>
					</p>
					<p>
						<GreenText>{getDisplayRate(poolAssetData.lendRate)}%</GreenText>
					</p>
				</Block>
			</Row>

			<Divider style={{ height: 1 }} />

			<Row>
				<div style={{ width: '100%', height: 70 }}>
					<p style={{ marginBottom: 8 }}>
						<b>Collateral</b>
					</p>
					<Row style={{ padding: 0, alignItems: 'center' }}>
						<div>
							<p>{getCollateralRate(position)}%</p>
							<GreenText>{getDisplayBalance(underlyingBalance, assetCode)}</GreenText>
						</div>
						<Button style={{ color: '#000000' }} onClick={() => setShowCollateralModal(true)}>
							Update
						</Button>
					</Row>
				</div>
			</Row>

			<Divider />

			<Row style={{ justifyContent: 'flex-end' }}>
				<Button style={{ color: '#FFFFFF' }} variant="primary" onClick={() => setShowWithdrawModal(true)}>
					Withdraw
				</Button>
			</Row>

			<WithdrawModal
				open={showWithdrawModal}
				onClose={() => setShowWithdrawModal(false)}
				position={position}
				pAssetData={poolAssetData}
			/>
			<ManageCollateralModal
				open={showCollateralModal}
				onClose={() => setShowCollateralModal(false)}
				pAssetData={poolAssetData}
				lend={position}
			/>
		</Container>
	);
};
