import { useLocalStorageState } from 'hooks';
import React, { useContext, useState } from 'react';

export interface ISettingsContext {
	theme: string;
	geoCheck: boolean;
	lastFaucet: string;
	setLastFaucet: (timestamp: any) => void;
	setTheme: (newTheme: any) => void;
}

const SettingsContext = React.createContext<Maybe<ISettingsContext>>(undefined);

export const SettingsProvider = ({ children = null as any }) => {
	const [theme, setTheme] = useLocalStorageState('theme', 'light');
	const [lastFaucet, setLastFaucet] = useLocalStorageState('faucetTimestamp', '0');

	// TODO: Add geo check
	const [geoCheck, setGeoCheck] = useState<boolean>(true);

	return (
		<SettingsContext.Provider value={{ theme, geoCheck, lastFaucet, setLastFaucet, setTheme }}>
			{children}
		</SettingsContext.Provider>
	);
};

export const useSettings = () => {
	const context = useContext(SettingsContext);

	if (!context) {
		throw new Error('Component rendered outside the provider tree');
	}

	return context;
};
