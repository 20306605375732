import React from 'react';
import styled from 'styled-components';

interface IAssetIcon {
	assetCode: string;
}

const IconWrapper = styled.img`
	width: 38px;
	height: 38px;
`;

export const AssetIcon: React.FC<IAssetIcon> = ({ assetCode }) => {
	return <IconWrapper src={`/assets/${assetCode.toLowerCase()}.png`} alt={assetCode} />;
};
