import React from 'react';
import styled from 'styled-components';
import { Button, Card, ProposalTag } from 'components';

import yesIcon from 'assets/images/icn_yes.svg';
import noIcon from 'assets/images/icn_no.svg';
import checkIcon from 'assets/images/icn_check_circle.svg';
import editIcon from 'assets/images/icn_edit.svg';
import { Proposal } from 'mocks/models';
import { AllocationProposal, ProposalStatus } from 'yieldblox-js';
import { AllocationProposalData } from 'types';
import { getFormattedNumber, getShortenWalletAddress, getTimeDifference } from 'utils';

const Container = styled(Card)`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 8px 0;
	flex-wrap: wrap;

	b {
		line-height: 34px;
	}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px;
`;

const Description = styled.p`
	color: ${({ theme }) => theme.colors.grey2};
`;

const VoteWrapper = styled.div`
	display: flex;
	align-items: center;

	img {
		height: 14px;
		margin: 4px;
	}
`;

const VerticalDivider = styled.div`
	width: 1px;
	background: ${({ theme }) => theme.colors.light_grey3};
	height: 20px;
	margin: 0 20px;
`;

interface IProps {
	allocationData: AllocationProposalData;
	onClick: () => void;
}

export const AllocationItem: React.FC<IProps> = ({ allocationData, onClick }) => {
	const { proposal, votes, total } = allocationData;

	return (
		<Container onClick={onClick}>
			<Column style={{ alignItems: 'flex-start' }}>
				<p style={{ fontSize: 22, lineHeight: '22px' }}>
					<b>Allocation Proposal</b>
				</p>
				<Description>Determine the allocation of YBX...</Description>
			</Column>

			<Column>
				<p>
					<b>Account</b>
				</p>
				<Description>{getShortenWalletAddress(proposal.id)}</Description>
			</Column>

			<Column>
				<p>
					<b>Total Votes</b>
				</p>
				<VoteWrapper>
					<p>{getFormattedNumber(total)}</p>
				</VoteWrapper>
			</Column>

			<Column>
				<p>
					<b>Time Left</b>
				</p>
				<Description>{getTimeDifference(7 * 24 * 60 * 60 * 1000 + proposal.lastModifiedTime.getTime())}</Description>
			</Column>

			<Column>
				<ProposalTag proposalStatus={proposal.status} />
			</Column>
		</Container>
	);
};
