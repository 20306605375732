import { PaymentPath } from 'yieldblox-js';

export class PaymentPathDetail implements PaymentPath {
  sendAssetId: string = '';
  sendAmount: string = '';
  destAssetId: string = '';
  destAmount: string = '';
  value: number = 0;

  path: string[] = [];
}
