import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Card, IModalProps, Modal, ModalFooter, ModalHeader, Input } from 'components';
import { GreenText } from 'theme';

import sadSprout from 'assets/images/icn_sprout_sad.svg';

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 48px;
	width: 100%;
`;

const RowItem = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 0;
`;

const IconWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 80%;
	}
`;

interface IProps extends IModalProps {}

export const RegionBlockModal: React.FC<IProps> = ({ open, onClose }) => {
	return (
		<>
			<Modal
				open={open}
				onClose={() => {
					console.log('Cannot close Region Block.');
				}}
				width={600}
			>
				<ModalHeader>Unsupported Region</ModalHeader>
				<Content>
					<IconWrapper>
						<img src={sadSprout} alt="" />
					</IconWrapper>
					<RowItem>
						<b style={{ fontSize: 26, lineHeight: '34px' }}>YieldBlox is not available in your country.</b>
					</RowItem>
					<GreenText style={{ cursor: 'pointer' }} onClick={() => window.open(`https://yieldblox.com`, '_self')}>
						YieldBlox Terms of Use
					</GreenText>
				</Content>
			</Modal>
		</>
	);
};
