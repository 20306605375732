import styled, { createGlobalStyle, DefaultTheme, css } from 'styled-components';
import { Colors } from './styled';

const MEDIA_WIDTHS = {
	upToExtraSmall: 500,
	upToSmall: 720,
	upToMedium: 960,
	upToLarge: 1280,
	upToExtraLarge: 1400,
};

const mediaWidthTemplates: {
	[width in keyof typeof MEDIA_WIDTHS]: typeof css;
} = Object.keys(MEDIA_WIDTHS).reduce((accumulator, size) => {
	(accumulator as any)[size] = (a: any, b: any, c: any) => css`
		@media (max-width: ${(MEDIA_WIDTHS as any)[size]}px) {
			${css(a, b, c)}
		}
	`;
	return accumulator;
}, {}) as any;

export const LIGHT_COLORS: Colors = {
	white: '#FFFFFF',
	black: '#051B35',
	green: '#37B24A',
	green1: '#37B24A26',
	purple: '#6D6FFF',
	red: '#FE4040',
	red1: '#FE404040',
	light_grey: '#F1F1F1',
	light_grey1: '#F0F0F0',
	light_grey2: '#E3E3E3',
	light_grey3: '#DEDEDE',
	grey1: '#949494',
	grey2: '#8A959C',
	yellow: '#FFCB00',
	yellow1: '#FFCB0026',
	shadow: '#00000003',
	blue: '#4472FF',
	pink: '#A56DFF',
};

export const DARK_COLORS: Colors = {
	white: '#2C2F36',
	black: '#FFFFFF',
	green: '#37B24A',
	green1: '#37B24A26',
	purple: '#8983FF',
	red: '#FE4040',
	red1: '#FE404040',
	light_grey: '#191B1F',
	light_grey1: '#565A68',
	light_grey2: '#E3E3E3',
	light_grey3: '#DEDEDE',
	grey1: '#949494',
	grey2: '#8A959C',
	yellow: '#FFCB00',
	yellow1: '#FFCB0026',
	shadow: '#00000003',
	blue: '#4472FF',
	pink: '#A56DFF',
};

export const defaultTheme: DefaultTheme = {
	colors: DARK_COLORS,

	grids: {
		sm: 8,
		md: 12,
		lg: 24,
	},

	//shadows
	shadow1: '#2F80ED',

	// media queries
	mediaWidth: mediaWidthTemplates,

	// css snippets
	flexColumnNoWrap: css`
		display: flex;
		flex-flow: column nowrap;
	`,
	flexRowNoWrap: css`
		display: flex;
		flex-flow: row nowrap;
	`,
};

export const darkTheme: DefaultTheme = {
	...defaultTheme,
	colors: DARK_COLORS,
};

export const lightTheme: DefaultTheme = {
	...defaultTheme,
	colors: LIGHT_COLORS,
};

export const ThemedGlobalStyle = createGlobalStyle`
  html {
  }

  body {
    margin: 0;
    padding: 0;
    font-family: 'DM Sans', sans-serif;
		font-size: 16px;
		line-height: 21px;
		color: ${({ theme }) => theme.colors.black};
		transition: all 0.5s linear;
  }

	h1 {
		font-size: 80px;
	}

	h2 {
		font-size: 64px;
	}

	h3 {
		font-size: 48px;
	}

	h4 {
		font-size: 40px;
	}

	h5 {
		font-size: 30px;
    font-weight: 500;
	}

	h6 {
		font-size: 22px;
		margin: 0;
		line-height: 29px;
		font-weight: 500;
	}

	h1, h2, h3, h4, h5, p, span {
		line-height: 120%;
		margin: 0;
	}

  select, button {
    font-family: 'DM Sans', sans-serif;
    outline: none;
    cursor: pointer;
  }
	
	input::-webkit-outer-spin-button,
	input::-webkit-inner-spin-button {
		-webkit-appearance: none;
		margin: 0;
	}
	
	input[type=number] {
		-moz-appearance: textfield;
	}

  select::-ms-expand {
      display: none;
  }

  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }

  * {
    box-sizing: border-box;
  }
`;

export const GreenText = styled.span`
	color: ${({ theme }) => theme.colors.green};
`;

export const PurpleText = styled.span`
	color: ${({ theme }) => theme.colors.purple};
`;
