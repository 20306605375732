import React from 'react';
import styled from 'styled-components';
import { Card } from '../Card';

const Container = styled.div<{ open: boolean }>`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	display: ${({ open }) => (open ? 'flex' : 'none')};
	justify-content: center;
	align-items: center;
	background: rgba(0, 0, 0, 0.5);
	z-index: 10;
	overflow-y: auto;
	flex-wrap: wrap;
`;

const ContentCard = styled(Card)<{ width?: number; height?: number }>`
	padding: 0;
	min-width: 330px;
	${({ width }) => (width ? `width: ${width}px;` : '')}
	${({ height }) => (height ? `height: ${height}px;` : '')}
`;

export const ModalHeader = styled.div`
	font-size: 22px;
	line-height: 29px;
	font-weight: 500;
	padding: 14px 40px;
	border-bottom: 2px solid ${({ theme }) => theme.colors.light_grey3};
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

export const ModalFooter = styled.div`
	padding: 14px 40px;
	border-top: 2px solid ${({ theme }) => theme.colors.light_grey3};
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	right: 0;
`;

export interface IModalProps extends React.HTMLAttributes<HTMLElement> {
	open: boolean;
	onClose?: () => void;
	width?: number;
	height?: number;
}

export const Modal: React.FC<IModalProps> = ({ open, onClose, width, height, children }) => {
	return (
		<Container onClick={() => onClose && onClose()} open={open}>
			<ContentCard onClick={(e: any) => e.stopPropagation()} width={width} height={height}>
				{children}
			</ContentCard>
		</Container>
	);
};

export const TopRightModal: React.FC<IModalProps> = ({ open, onClose, width, height, children }) => {
	return (
		<Container onClick={() => onClose && onClose()} open={open} style={{alignContent: 'flex-start', justifyContent: 'flex-end'}}>
			<ContentCard onClick={(e: any) => e.stopPropagation()} width={width} height={height} style={{marginTop: '4.5rem', marginRight: '0.5rem'}}>
				{children}
			</ContentCard>
		</Container>
	);
};
