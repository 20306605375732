import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { Button, ClaimModal, MenuModal, FaucetModal } from 'components';
import { Link, useLocation } from 'react-router-dom';
import { useWallet } from 'contexts/wallet';
import { WalletType } from 'types';
import { getFormattedNumber, getShortenWalletAddress } from 'utils';
import { GreenText } from 'theme';
import copy from 'copy-to-clipboard';
import { useSettings } from 'contexts/settings';
import { toast } from 'react-toastify';

import logoImg from 'assets/images/ybx_logo_light_tn.svg';
import logoImgDark from 'assets/images/ybx_logo_dark_tn.svg';
import ybxIcon from 'assets/images/icn_ybx.svg';
import scripteeIcon from 'assets/images/icn_scriptee.svg';
import arrowIcon from 'assets/images/icn_arrow_down.svg';
import navActiveIcon from 'assets/images/icn_nav_active.svg';
import copyIcon from 'assets/images/icn_copy.svg';
import albedoIcon from 'assets/images/wallet_albedo.svg';
import freighterIcon from 'assets/images/wallet_freighter.svg';
import lightIcon from 'assets/images/icn_light.svg';
import darkIcon from 'assets/images/icn_dark.svg';
import faucetIcon from 'assets/images/faucetIcon.svg';
import { useUser } from 'contexts';

const Container = styled.div`
	position: relative;
	width: 100%;
	height: 70px;
	background: ${({ theme }) => theme.colors.white};
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 2.5rem;
	box-sizing: border-box;
`;

const LogoImg = styled.img`
	width: 180px;
`;

const LinkContainer = styled.div`
	position: absolute;
	top: 0;
	left: 50%;
	height: 100%;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	justify-content: center;
`;

const LinkItem = styled(Link)`
	position: relative;
	height: 100%;
	color: ${({ theme }) => theme.colors.black};
	text-decoration: none;
	font-size: 20px;
	line-height: 26px;
	font-weight: 500;
	padding: 0 35px;
	display: flex;
	align-items: center;
`;

const DetailContainer = styled.div`
	display: flex;
	align-items: center;
`;

const DetailWrapper = styled.div`
	height: 34px;
	border-radius: 17px;
	padding: 2px;
	background: ${({ theme }) => theme.colors.light_grey1};
	display: flex;
	justify-content: space-between;
	align-items: center;

	p {
		padding: 0 10px;
	}
`;

const IconWrapper = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: ${({ theme }) => theme.colors.green1};
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
	}
`;

const ArrowIcon = styled.img`
	width: 16px;
	margin: 0 10px 0 4px;
`;

const NavActiveIcon = styled.img`
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
`;

const BurgerButton = styled.button`
	display: flex;
`;

const ChangeWalletWrapper = styled(DetailWrapper)`
	position: absolute;
	top: 34px;
	left: 0;
	width: 100%;
	background: ${({ theme }) => theme.colors.white};
	box-shadow: 0 7px 13px ${({ theme }) => theme.colors.shadow};
	cursor: pointer;
`;

const ThemeButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin-right: 16px;
	cursor: pointer;

	img {
		width: 24px;
		height: 24px;
	}
`;

const FaucetButton = styled.div`
	display: flex;
	width: 24px;
	height 24px;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: rgba(68, 114, 255, 1);
	margin-right: 16px;
	cursor: pointer;
	&: hover {
			opacity: 0.5;
	}

	img {
		width: 20px;
		height: 20px;
	}
`;

interface INavItem {
	to: string;
	title: string;
}

const NavItem = ({ to, title }: INavItem) => {
	const location = useLocation();

	return (
		<LinkItem to={to}>
			{title}
			{location.pathname === to && <NavActiveIcon src={navActiveIcon} alt="" />}
		</LinkItem>
	);
};

export const Header = () => {
	const { theme, setTheme } = useSettings();
	const { connect, disconnect, connected, walletAddress, walletType } = useWallet();
	const { toClaim, user } = useUser();
	const [showMenu, setShowMenu] = useState(false);
	const [showClaim, setShowClaim] = useState(false);
	const [showFaucet, setShowFaucet] = useState(false);

	const [width, setWidth] = React.useState(window.innerWidth);
	const breakpoint = 1410;

	let walletButtonRef = useRef<HTMLDivElement | null>(null);

	React.useEffect(() => {
		const handleResizeWindow = () => setWidth(window.innerWidth);
		// subscribe to window resize event "onComponentDidMount"
		window.addEventListener('resize', handleResizeWindow);
		return () => {
			// unsubscribe "onComponentDestroy"
			window.removeEventListener('resize', handleResizeWindow);
		};
	}, []);

	const handleClickOutside = (event: any) => {
		if (walletButtonRef.current && !walletButtonRef.current.contains(event.target)) {
			setShowChangeWallet(false);
		}
	};

	React.useEffect(() => {
		document.addEventListener('click', handleClickOutside, true);
		return () => {
			document.removeEventListener('click', handleClickOutside, true);
		};
	});

	const [showChangeWallet, setShowChangeWallet] = useState(false);

	const handleClickWalletButton = () => {
		if (!showChangeWallet) {
			setShowChangeWallet(true);
		} else {
			copy(walletAddress || '');
			toast.success('Copied the wallet address');
			setShowChangeWallet(false);
		}
	};

	const handleDisconnectWallet = () => {
		disconnect();
	};

	const handleFaucetMobile = () => {
		setShowMenu(false);
		setShowFaucet(true);
	};

	const handleClaimMobile = () => {
		setShowMenu(false);
		setShowClaim(true);
	};

	const getWalletLogo = () => {
		switch (walletType) {
			case WalletType.Freighter:
				return freighterIcon;
			case WalletType.Albedo:
				return albedoIcon;
			default:
				return scripteeIcon;
		}
	};

	return (
		<>
			<Container>
				<LogoImg src={theme === 'light' ? logoImg : logoImgDark} alt="" />

				{width < breakpoint ? (
					<div>
						<Button variant="secondary" style={{ width: 10 }} onClick={() => setShowMenu(true)}>
							☰
						</Button>
					</div>
				) : (
					<div style={{ display: 'flex', alignItems: 'center' }}>
						<LinkContainer>
							<NavItem to="/market" title="Market" />
							<NavItem to="/profile" title="Profile" />
							<NavItem to="/governance" title="Governance" />
							<NavItem to="/turret" title="Turrets" />
						</LinkContainer>

						<FaucetButton onClick={() => setShowFaucet(true)}>
							<img src={faucetIcon} alt="" />
						</FaucetButton>

						<ThemeButton onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
							<img src={theme === 'dark' ? lightIcon : darkIcon} alt="" />
						</ThemeButton>

						{!connected || !walletAddress ? (
							<Button variant="primary" onClick={connect}>
								Connect Wallet
							</Button>
						) : (
							<DetailContainer>
								<div style={{ marginRight: 16, position: 'relative' }} ref={walletButtonRef}>
									<DetailWrapper style={{ cursor: 'pointer' }} onClick={handleClickWalletButton}>
										<IconWrapper style={{ background: '#F0F0F0' }}>
											<img src={getWalletLogo()} alt="" />
										</IconWrapper>
										<p>{getShortenWalletAddress(walletAddress)}</p>
										{showChangeWallet ? <ArrowIcon src={copyIcon} alt="" /> : <ArrowIcon src={arrowIcon} alt="" />}
									</DetailWrapper>

									{showChangeWallet && (
										<ChangeWalletWrapper onClick={() => setShowChangeWallet(false)}>
											<p onClick={handleDisconnectWallet}>
												<GreenText>Disconnect</GreenText>
											</p>
											<ArrowIcon src={arrowIcon} alt="" style={{ transform: 'rotate(180deg)' }} />
										</ChangeWalletWrapper>
									)}
								</div>

								<DetailWrapper style={{ cursor: 'pointer' }} onClick={() => setShowClaim(true)}>
									<p>{getFormattedNumber(toClaim)}</p>
									<IconWrapper>
										<img src={ybxIcon} alt="" />
									</IconWrapper>
								</DetailWrapper>
							</DetailContainer>
						)}
					</div>
				)}
			</Container>

			<MenuModal
				open={showMenu}
				onClose={() => setShowMenu(false)}
				onClaim={handleClaimMobile}
				onFaucet={handleFaucetMobile}
			/>
			{user.id && <ClaimModal open={showClaim} onClose={() => setShowClaim(false)} />}
			<FaucetModal open={showFaucet} onClose={() => setShowFaucet(false)} />
		</>
	);
};
