import { POOL_ACCOUNT_ID, YBX_TOKEN } from 'config/constants';
import { AssetConstants } from 'types';

/**
 * Pool Data Helper
 * Helper functions to work with Account Data Fields of the Pool Account in Stellar
 */

/*****************
 * Pool Data Keys *
 *****************/

const issuerKeyTemplate = (issuerKey: string, overlapKey: string, underlyingKey: string) =>
	`${issuerKey}_${overlapKey}_${underlyingKey}_Issuer`;

const assetInfoKeyTemplate = (issuerKey: string, overlapKey: string, underlyingKey: string) =>
	`${issuerKey}_${overlapKey}_${underlyingKey}_Data`;

/********************
 * Pool Data Getters *
 ********************/

/**
 * Get the underlying asset issuer from a encoded pool token asset code
 * @param data - Pool account data
 * @param poolAssetCode - The Pool Asset code
 * @returns The underlying asset issuer
 */
export function GetAssetIssuer(data: Record<string, string>, poolAssetCode: string): string {
	let key = issuerKeyTemplate(poolAssetCode.charAt(1), poolAssetCode.charAt(2), poolAssetCode.slice(3));
	return validateAndDecode(data[key]);
}

/**
 * Get the contract hash from the pool
 * @param data - pool account data
 * @returns The current contract hash
 */
export function GetContractHash(data: Record<string, string>): string {
	return validateAndDecode(data['YieldBlox_Contract_Hash']);
}

/******************
 * General Helpers *
 ******************/

/**
 * Get the relevant data for supported assets
 * @param data - The pool account data to find the supported assets of
 * @returns - An array of AssetData
 */
export function GetSupportedAssets(data: Record<string, string>): AssetConstants[] {
	const regexAssetKey = /^\w\w[a-zA-Z0-9]{1,9}_Underlying$/;

	let supportedAssetKeys: string[] = [];
	for (let key in data) {
		if (regexAssetKey.test(key)) {
			let trimmedKey = key.slice(0, -11);
			supportedAssetKeys.push(trimmedKey);
		}
	}

	let supportedAssets: AssetConstants[] = [];
	for (let key of supportedAssetKeys) {
		let underlyingDataValue = validateAndDecode(data[`${key}_Underlying`]);
		let assetDataValue = validateAndDecode(data[`${key}_Data`]);
		if (underlyingDataValue === undefined || assetDataValue === undefined) {
			throw new Error('Broken key while fetching current assets');
		}
		let assetData = buildAssetData(assetDataValue, underlyingDataValue, key);

		// fetch other asset constants
		assetData.trackingAccountId = validateAndDecode(data[`${key}_Utilization_Tracker`]);
		supportedAssets.push(assetData);
	}

	return supportedAssets;
}

export function GetYbxConstantsData(data: Record<string, string>): AssetConstants {
	let ybxDataValue = validateAndDecode(data[`YBX_Data`]);
	if (ybxDataValue === undefined) {
		throw new Error('Broken key while fetching current assets');
	}
	let ybxData = buildData(ybxDataValue, YBX_TOKEN, 'YBX');
	return ybxData;
}

/******************
 * Private Helpers *
 ******************/

function validateAndDecode(encodedData: string): string {
	if (encodedData === undefined) {
		//@ts-ignore
		return undefined;
	}
	return Buffer.from(encodedData, 'base64').toString();
}

function buildAssetData(assetDataValue: string, underlyingDataValue: string, key: string): AssetConstants {
	let assetUnderlyingEntries = underlyingDataValue.split('_');
	/*
  underlyingEntries:
  issuer_last3OfAssetCode

  NOTE: last3OfAssetCode is only entered if the asset code is longer than 9 chars
  */
	let assetCode = key.slice(2) + assetUnderlyingEntries[1];
	let issuer = assetUnderlyingEntries[0];
	let assetId = `${assetCode}:${issuer}`;
	return buildData(assetDataValue, assetId, key);
}

function buildData(assetDataValue: string, assetId: string, key: string): AssetConstants {
	let assetDataEntries = assetDataValue.split('_');
	assetDataEntries.shift(); // remove leading underscore
	/*
  _: empty, underscore to avoid precursor problem
  assetId: string;
	assetKey: string;
	baseRateConstant: number; // 2 digit, divide by 100
	slope0: number; // 3 digits, div 10
	slope1: number; // 3 digits, div 10
	slope2: number; // 3 digits, div 10
	slope3: number; // 3 digits, div 10
	rateThreshold1: number; // 2 digit, divide by 100
	rateThreshold2: number; // 2 digit, divide by 100
	rateThreshold3: number; // 2 digit, divide by 100
	liquidationFactor: number; // 2 digit, divide by 100
	liquidationIncentive: number; // 2 digit, divide by 100
	lendingAllocation: number; // 2 digit, divide by 100
	borrowingAllocation: number; // 2 digit, divide by 100
	ybxFeeAllocation: number; // 2 digit, divide by 100
  */
	return {
		assetId: assetId,
		assetKey: key, // set above
		trackingAccountId: '', //set later
		baseRateConstant: parseFloat(assetDataEntries[0]) / 100,
		slope0: parseFloat(assetDataEntries[1]) / 10,
		slope1: parseFloat(assetDataEntries[2]) / 10,
		slope2: parseFloat(assetDataEntries[3]) / 10,
		slope3: parseFloat(assetDataEntries[4]) / 10,
		rateThreshold1: parseFloat(assetDataEntries[5]) / 100,
		rateThreshold2: parseFloat(assetDataEntries[6]) / 100,
		rateThreshold3: parseFloat(assetDataEntries[7]) / 100,
		liquidationFactor: parseFloat(assetDataEntries[8]) / 100,
		liquidationIncentive: parseFloat(assetDataEntries[9]) / 100,
		lendingAllocation: parseFloat(assetDataEntries[10]) / 100,
		borrowingAllocation: parseFloat(assetDataEntries[11]) / 100,
		ybxFeeAllocation: parseFloat(assetDataEntries[12]) / 100,
	};
}
