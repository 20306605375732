import { Asset } from 'stellar-sdk';

// TESTNET
/***** Horizon *****/
export const HORIZON_URL = 'https://horizon-testnet.stellar.org';
export const HORIZON_NETWORK = 'TESTNET';
export const STELLAR_NETWORK_KEY = 'Test SDF Network ; September 2015';

/***** YBX Accounts *****/
export const POOL_ACCOUNT_ID = 'GDGHQTCJ3SGFBWBHJGVRUFBRLZGS5VS52HEDH4GVPX5GZRJQAOW7ZM37';
export const ISSUANCE_ACCOUNT_ID = 'GCIWMQHPST7LQ7V4LHAF2UP6ZSDCFRYYP7IM4BBAFSBZMVTR3BB4OQZ5';
export const DIST_ACCOUNT_ID = 'GATBAGTTQLQ4VKZMXLINLS6M4F2PEXMAZCK5ZE5ES4B6A2DXNGCFRX54';
export const ESCROW_ACCOUNT_ID = 'GANC25ARTWI2XFLZYFKFIDMXC3CFSNC6LBTHLHBQDMLZCRAJ4ISDCOHR';
export const DAO_TREASURY_ID = 'GDMZKMXXIU6HKKQLEK7RFB7CJJZJ7QJ5E6DWOSYHVYHGOGPONGSJMSY6';
export const CONTROLLED_ACCOUNTS: string[] = [
	POOL_ACCOUNT_ID,
	ISSUANCE_ACCOUNT_ID,
	DIST_ACCOUNT_ID,
	ESCROW_ACCOUNT_ID,
	DAO_TREASURY_ID,
];

/***** YBX Assets *****/
export const YBX_TOKEN = `YBX:${ISSUANCE_ACCOUNT_ID}`;
export const VEYBX_TOKEN = `veYBX:${ESCROW_ACCOUNT_ID}`;
export const NATIVE = 'XLM:native';
export const DEX_USDC = 'USDC:GAEB3HSAWRVILER6T5NMX5VAPTK4PPO2BAL37HR2EOUIK567GJFEO437';

// PUBNET
// /***** Horizon *****/
// export const HORIZON_URL = 'https://horizon.stellar.org/'
// export const HORIZON_NETWORK = 'PUBLIC';
// export const STELLAR_NETWORK_KEY = 'Public Global Stellar Network ; September 2015';
