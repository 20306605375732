/**
 * Utility functions to help manage user positions
 */
import { DIST_ACCOUNT_ID } from 'yieldblox-js';
import { Operation, Claimant } from 'stellar-sdk';
import { assetHelper } from '.';
import { stellarHelper, horizonApi } from '../stellar';

export async function CreateCollateralFromNone(publicKey: string, assetId: string, amount: string): Promise<string> {
	let account = await horizonApi.GetAccountAsync(publicKey);
	let txBuilder = await stellarHelper.CreateTransactionBuilder(account, 'ybxCollateralize');
	let asset = assetHelper.GetAssetFromId(assetId);
	txBuilder.addOperation(
		Operation.createClaimableBalance({
			asset: asset,
			amount: amount,
			claimants: [new Claimant(DIST_ACCOUNT_ID, Claimant.predicateNot(Claimant.predicateUnconditional()))],
		})
	);
	return txBuilder.build().toXDR();
}
