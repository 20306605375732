import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { BalanceLine, ProposalType, STELLAR_ZERO, VoteEvent, YBX_TOKEN } from 'yieldblox-js';
import {
	ProposalTag,
	IModalProps,
	Input,
	Modal,
	ModalFooter,
	ModalHeader,
	Table,
	Toggle,
	ConfirmActionModal,
	AssetIcon,
} from 'components';
import { GreenText } from 'theme';

import PropAllocIcon from 'assets/images/icn_prop_alloc.png';
import LinkIcon from 'assets/images/icn_link.svg';
import { AllocationProposalData, PoolAssetData, TxType } from 'types';
import { getDisplayBalance, getDisplayRate, getFormattedNumber, getShortenWalletAddress } from 'utils';
import { useUser, useWallet, useYieldBlox } from 'contexts';
import { TooltipButton } from 'components/common/Button/TooltipButton';
import { Grid } from '@material-ui/core';

const RowItem = styled.div`
	padding: 14px 40px;
	border-bottom: 1px solid ${({ theme }) => theme.colors.light_grey3};
`;

const RowDetail = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: space-between;
	padding: 8px 0;
`;

const PropIcon = styled.img`
	width: 30px;
	height: 30px;
`;

const ContentWrapper = styled.div`
	width: 100%;
	background: ${({ theme }) => theme.colors.light_grey1};
	box-shadow: 0px 7px 13px ${({ theme }) => theme.colors.shadow};
	border-radius: 18px;
	padding: 19px 25px 6px 25px;
`;

const Status = styled.div<{ secondary?: boolean; border?: boolean }>`
	color: ${({ theme, secondary }) => (secondary ? theme.colors.yellow : theme.colors.green)};
	background: ${({ theme, secondary }) => (secondary ? theme.colors.yellow1 : theme.colors.green1)};
	border-color: ${({ theme, secondary }) => (secondary ? theme.colors.yellow : theme.colors.green)};
	padding: 2px 16px;
	border-radius: 16px;
	border-style: solid;
	border-width: ${({ border }) => (border ? '0.5px' : 0)};
	font-size: 16px;
`;

const Flex = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

interface IProps extends IModalProps {
	allocationData: AllocationProposalData;
}

export const AllocationModal: React.FC<IProps> = ({ open, onClose, allocationData }) => {
	const { proposal, votes, total } = allocationData;
	const { user, votingPower } = useUser();
	const { poolAssets, ybxAsset } = useYieldBlox();
	const { walletAddress, runContract } = useWallet();
	const theme = useTheme();

	const [showConfirm, setShowConfirm] = useState<boolean>(false);
	const [isBorrow, setIsBorrow] = useState<boolean>(false);
	const [userVotes, setUserVotes] = useState<BalanceLine[]>([]);
	const [totalVotes, setTotalVotes] = useState<number>(0);
	const [loadingVotes, setLoadingVotes] = useState<boolean>(false);

	const ybxVotes = parseFloat(votes.find(v => v.assetId === YBX_TOKEN)?.amount ?? '0');
	const [ybxAmount, setYbxAmount] = useState<string>('0');

	const remainingVotes = votingPower - totalVotes;

	const handleVote = (vote: BalanceLine) => {
		let asNumber = Number(vote.amount);
		if (Number.isFinite(asNumber)) {
			vote.amount = asNumber.toFixed(7); // format string stellar style
			// truncate vote to 7 decimals, ensure its actually a number
			let newUserVotes = [...userVotes];
			let matchIndex = userVotes.findIndex(v => v.assetId === vote.assetId);
			if (matchIndex !== -1 && vote.amount === STELLAR_ZERO) {
				newUserVotes.splice(matchIndex, 1);
			}

			if (vote.amount !== STELLAR_ZERO) {
				if (matchIndex !== -1) {
					newUserVotes[matchIndex] = vote;
				} else {
					newUserVotes.push(vote);
				}
			}

			let newTotalVotes = newUserVotes.reduce(
				(prev: number, vote: BalanceLine): number => prev + Number(vote.amount),
				0
			);
			setUserVotes(newUserVotes);
			setTotalVotes(newTotalVotes);
		}
	};

	const processTransaction = async () => {
		if (userVotes.length > 0 && walletAddress) {
			let voteEvent = new VoteEvent(walletAddress, proposal.id, userVotes, ProposalType.ALLOCATION);
			await runContract(voteEvent);
		}
	};

	const handleYbx = (value: string) => {
		setYbxAmount(value);
		let vote = {
			amount: Number(value).toFixed(7),
			assetId: YBX_TOKEN,
		};
		handleVote(vote);
	};

	const isValid = () => {
		return remainingVotes >= 0;
	};

	const handleClose = () => {
		setShowConfirm(false);
		setIsBorrow(false);
		if (onClose) {
			onClose();
		}
	};

	const handleConfirm = async () => {
		processTransaction();
		handleClose();
	};

	useEffect(() => {
		const loadAllocationVotes = async () => {
			if (user.id) {
				setLoadingVotes(true);
				let votes = await proposal.getVotesForAccount(user.id);
				setUserVotes(votes);
				const currentYbxVotes = votes.find(v => v.assetId === YBX_TOKEN)?.amount ?? '0';
				setYbxAmount(currentYbxVotes);

				let currentTotalVotes = votes.reduce((prev: number, v: BalanceLine) => prev + parseFloat(v.amount), 0);
				setTotalVotes(currentTotalVotes);
				setLoadingVotes(false);
			}
		};
		loadAllocationVotes().catch(console.error);
	}, [open]);

	return (
		<>
			<Modal open={open} onClose={handleClose} width={700}>
				<ModalHeader>
					<div>Allocation Proposal</div>
					<ProposalTag proposalStatus={proposal.status} />
				</ModalHeader>

				<RowItem>
					<RowDetail style={{ justifyContent: 'flex-start' }}>
						<PropIcon src={PropAllocIcon} alt="icon" />
						<b style={{ margin: '0 16px' }}>Account</b>
						<p>{getShortenWalletAddress(proposal.id)}</p>
					</RowDetail>

					<RowDetail style={{ justifyContent: 'flex-start' }}>
						<img src={LinkIcon} alt="" />
						<b style={{ margin: '0 16px' }}>
							<GreenText
								onClick={() =>
									window.open(
										'https://docs.ybx.script3.io/user-docs/governance#ybx-incentive-allocations',
										'allocation'
									)
								}
							>
								More Information
							</GreenText>
						</b>
					</RowDetail>
				</RowItem>
				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail style={{ padding: '2px 0' }}>
						<b>Remaining Votes</b>
						<Status border>{getDisplayBalance(remainingVotes, '')}</Status>
					</RowDetail>
				</RowItem>
				<RowItem style={{ borderBottom: 'none', padding: 12 }}>
					<ContentWrapper style={{ padding: '10px 20px' }}>
						<Grid container spacing={1} style={{ paddingBottom: '10px' }}>
							<Grid item xs={4} sm={3}>
								<b>Code</b>
							</Grid>
							<Grid item xs={4} sm={3}>
								<b>Last Allocation</b>
							</Grid>
							<Grid item xs={4} sm={3}>
								<b>Pending Allocation</b>
							</Grid>
							<Grid item xs={12} sm={3}>
								<b>Your votes</b>
							</Grid>
						</Grid>
						<Grid container spacing={1}>
							<Grid item xs={4} sm={3}>
								<Flex style={{ justifyContent: 'flex-start' }}>
									<AssetIcon assetCode={'YBX'} />
									<b style={{ marginLeft: '0.25rem' }}>YBX</b>
								</Flex>
							</Grid>
							<Grid item xs={4} sm={3}>
								<Flex>
									{getDisplayRate(ybxAsset?.data?.lendingAllocation)}
									<GreenText>%</GreenText>
								</Flex>
							</Grid>
							<Grid item xs={4} sm={3}>
								<Flex>
									{getDisplayRate(ybxVotes / total)}
									<GreenText>%</GreenText>
								</Flex>
							</Grid>
							<Grid item xs={12} sm={3} style={{ justifyContent: 'flex-end' }}>
								<Input
									type="number"
									min={0}
									value={ybxAmount}
									placeholder="--"
									style={{ width: 120 }}
									background={theme.colors.white}
									onChange={e => handleYbx(e.target.value)}
								/>
							</Grid>
						</Grid>
						<RowDetail>
							<Toggle
								value={isBorrow ? 1 : 0}
								texts={['Lending', 'Borrowing']}
								onUpdate={(value: number) => setIsBorrow(value === 1)}
								style={{ width: 200 }}
							/>
						</RowDetail>
						{Object.values(poolAssets).map((item, idx) => (
							<AllocationItem
								item={item}
								votes={votes}
								userVotes={userVotes}
								total={total}
								isBorrow={isBorrow}
								loadingVotes={loadingVotes}
								onChange={handleVote}
								key={idx}
							/>
						))}
					</ContentWrapper>
				</RowItem>

				<ModalFooter style={{ justifyContent: 'flex-end' }}>
					<TooltipButton
						variant="primary"
						onClick={() => setShowConfirm(true)}
						disabled={!isValid()}
						errorMessage="Cannot vote with more than your alloted votes."
					>
						Vote
					</TooltipButton>
				</ModalFooter>
			</Modal>

			<ConfirmActionModal
				type="Vote"
				open={showConfirm}
				onClose={handleClose}
				onBack={() => setShowConfirm(false)}
				onConfirm={handleConfirm}
			>
				<RowItem style={{ borderBottom: 'none' }}>
					<RowDetail>
						<b>Proposal</b>
						<b>{getShortenWalletAddress(proposal.id)}</b>
					</RowDetail>
					<RowDetail>
						<b>Votes</b>
					</RowDetail>
					{userVotes
						.filter(v => v.assetId.startsWith('YBX'))
						.map((vote, idx) => (
							<RowDetail key={idx}>
								<p style={{ width: 100, textAlign: 'right' }}>{vote.assetId.split(':')[0]}</p>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
									<p>{getFormattedNumber(Number(vote.amount))}</p>
								</div>
							</RowDetail>
						))}
					<RowDetail>
						<b>Lending Allocations</b>
					</RowDetail>
					{userVotes
						.filter(v => v.assetId.startsWith('y'))
						.map((vote, idx) => (
							<RowDetail key={idx}>
								<p style={{ width: 100, textAlign: 'right' }}>{vote.assetId.split(':')[0].substring(3)}</p>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
									<p>{getFormattedNumber(Number(vote.amount))}</p>
								</div>
							</RowDetail>
						))}
					<RowDetail>
						<b>Borrowing Allocations</b>
					</RowDetail>
					{userVotes
						.filter(v => v.assetId.startsWith('l'))
						.map((vote, idx) => (
							<RowDetail key={idx}>
								<p style={{ width: 100, textAlign: 'right' }}>{vote.assetId.split(':')[0].substring(3)}</p>
								<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: 220 }}>
									<p>{getFormattedNumber(Number(vote.amount))}</p>
								</div>
							</RowDetail>
						))}
				</RowItem>
			</ConfirmActionModal>
		</>
	);
};

interface IAllocationItem {
	item: PoolAssetData;
	votes: BalanceLine[];
	userVotes: BalanceLine[];
	total: number;
	isBorrow: boolean;
	loadingVotes: boolean;
	onChange: (vote: BalanceLine) => void;
}

const AllocationItem = ({ item, votes, userVotes, total, isBorrow, loadingVotes, onChange }: IAllocationItem) => {
	const theme = useTheme();
	const assetId = item.poolAsset.underlyingId;
	const assetCode = assetId.split(':')[0];

	// lending info
	const yTokenId = item.poolAsset.poolTokenId;
	const yTokenTotalVotes = parseFloat(votes.find(v => v.assetId === yTokenId)?.amount ?? '0');
	const yTokenPercentage = yTokenTotalVotes / total;
	const yTokenAllocation = item.poolAsset.data.lendingAllocation;

	// borrow info
	const lTokenId = item.poolAsset.liabilityTokenId;
	const lTokenTotalVotes = parseFloat(votes.find(v => v.assetId === lTokenId)?.amount ?? '0');
	const lTokenPercentage = lTokenTotalVotes / total;
	const lTokenAllocation = item.poolAsset.data.borrowingAllocation;

	const [amountBorrow, setAmountBorrow] = useState('0');
	const [amountLend, setAmountLend] = useState('0');

	useEffect(() => {
		if (!loadingVotes) {
			const lTokenCurrentVotes = userVotes.find(v => v.assetId === lTokenId)?.amount ?? '0';
			setAmountBorrow(lTokenCurrentVotes);
			const yTokenCurrentVotes = userVotes.find(v => v.assetId === yTokenId)?.amount ?? '0';
			setAmountLend(yTokenCurrentVotes);
		}
	}, [loadingVotes]);

	const handleChange = (value: string) => {
		let vote: BalanceLine;
		if (isBorrow) {
			setAmountBorrow(value);
			vote = {
				amount: Number(value).toFixed(7),
				assetId: lTokenId,
			};
		} else {
			setAmountLend(value);
			vote = {
				amount: Number(value).toFixed(7),
				assetId: yTokenId,
			};
		}
		onChange(vote);
	};

	return (
		<Grid container spacing={1}>
			<Grid item xs={4} sm={3}>
				<Flex style={{ justifyContent: 'flex-start' }}>
					<AssetIcon assetCode={assetCode} />
					<b style={{ marginLeft: '0.25rem', alignContent: 'center' }}>{assetCode}</b>
				</Flex>
			</Grid>
			<Grid item xs={4} sm={3}>
				<Flex>
					{getDisplayRate(isBorrow ? lTokenAllocation : yTokenAllocation)}
					<GreenText>%</GreenText>
				</Flex>
			</Grid>
			<Grid item xs={4} sm={3}>
				<Flex>
					{getDisplayRate(isBorrow ? lTokenPercentage : yTokenPercentage)}
					<GreenText>%</GreenText>
				</Flex>
			</Grid>
			<Grid item xs={12} sm={3} style={{ justifyContent: 'flex-end' }}>
				<Input
					type="number"
					min={0}
					value={isBorrow ? amountBorrow : amountLend}
					placeholder="--"
					style={{ width: 120 }}
					background={theme.colors.white}
					onChange={e => handleChange(e.target.value)}
				/>
			</Grid>
		</Grid>
	);
};
