import React from 'react';
import styled from 'styled-components';
import { Button, Card, ProposalTag } from 'components';

import yesIcon from 'assets/images/icn_yes.svg';
import noIcon from 'assets/images/icn_no.svg';
import checkIcon from 'assets/images/icn_check_circle.svg';
import editIcon from 'assets/images/icn_edit.svg';
import { Proposal } from 'mocks/models';
import { ProposalStatus, XdrProposal } from 'yieldblox-js';
import { useUser } from 'contexts';
import { getPublicKey } from '@stellar/freighter-api';
import { getDisplayRate, getFormattedNumber, getShortenWalletAddress } from 'utils';
import { XdrProposalData } from 'types';

const Container = styled(Card)`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin: 8px 0;
	flex-wrap: wrap;

	b {
		line-height: 34px;
	}
`;

const Column = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 10px;
`;

const Description = styled.p`
	color: ${({ theme }) => theme.colors.grey2};
`;

const VoteWrapper = styled.div`
	display: flex;
	align-items: center;

	img {
		height: 14px;
		margin: 4px;
	}
`;

const VerticalDivider = styled.div`
	width: 1px;
	background: ${({ theme }) => theme.colors.light_grey3};
	height: 20px;
	margin: 0 20px;
`;

const Status = styled.div<{ status?: boolean }>`
	border-radius: 16px;
	background: ${({ theme, status }) => (status ? theme.colors.green1 : theme.colors.yellow1)};
	color: ${({ theme, status }) => (status ? theme.colors.green : theme.colors.yellow)};
	padding: 1px;
	display: flex;
	align-items: center;

	p {
		padding: 0 10px;
	}

	img {
		height: 20px;
	}
`;

interface IProps {
	propData: XdrProposalData;
	setProposal: (proposal: XdrProposalData) => void;
}

export const ProposalItem: React.FC<IProps> = ({ propData, setProposal }) => {
	const { proposal, yes, no, quorumPct } = propData;

	return (
		<Container onClick={() => setProposal(propData)}>
			<Column style={{ alignItems: 'flex-start' }}>
				<p style={{ fontSize: 22, lineHeight: '22px' }}>
					<b>{proposal.title}</b>
				</p>
				<Description>{proposal.description?.substring(0, 32)}...</Description>
			</Column>

			<Column>
				<p>
					<b>Account</b>
				</p>
				<Description>{getShortenWalletAddress(proposal.id)}</Description>
			</Column>

			<Column>
				<p>
					<b>Current Votes</b>
				</p>
				<VoteWrapper>
					<p>{getFormattedNumber(yes)}</p>
					<img src={yesIcon} alt="" />
					<VerticalDivider />
					<p>{getFormattedNumber(no)}</p>
					<img src={noIcon} alt="" />
				</VoteWrapper>
			</Column>

			<Column>
				<p>
					<b>Quorum Percentage</b>
				</p>
				<Description>{getDisplayRate(quorumPct)}</Description>
			</Column>

			<Column style={{ height: '100%' }}>
				<ProposalTag proposalStatus={proposal.status} />
			</Column>
		</Container>
	);
};
