import React, { useState } from 'react';
import styled from 'styled-components';
import { IModalProps, Modal, Button, ClaimModal, FaucetModal, TopRightModal } from 'components';
import { WalletType } from 'types';
import { toast } from 'react-toastify';
import { Link, useLocation } from 'react-router-dom';
import { getShortenWalletAddress, getFormattedNumber } from 'utils';
import { useWallet } from 'contexts/wallet';
import copy from 'copy-to-clipboard';
import { GreenText } from 'theme';

import logoImg from 'assets/images/ybx_logo.svg';
import ybxIcon from 'assets/images/icn_ybx.svg';
import scripteeIcon from 'assets/images/icn_scriptee.svg';
import arrowIcon from 'assets/images/icn_arrow_down.svg';
import navActiveIcon from 'assets/images/icn_nav_active.svg';
import copyIcon from 'assets/images/icn_copy.svg';
import faucetIcon from 'assets/images/faucetIcon.svg';
import lightIcon from 'assets/images/icn_light.svg';
import darkIcon from 'assets/images/icn_dark.svg';
import albedoIcon from 'assets/images/wallet_albedo.svg';
import freighterIcon from 'assets/images/wallet_freighter.svg';

import { useUser } from 'contexts';
import { useSettings } from 'contexts/settings';

const RowItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	border-bottom: 2px solid ${({ theme }) => theme.colors.light_grey3};
	border-top: 2px solid ${({ theme }) => theme.colors.light_grey3};
`;

const WalletButton = styled.button`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0px;
	border: none;
	outline: none;
	background: transparent;
	border-top: 1px solid ${({ theme }) => theme.colors.light_grey3};
	cursor: pointer;

	&:hover {
		background: ${({ theme }) => theme.colors.light_grey};
	}

	&:first-child {
		border-top: none;
	}
`;

const Container = styled.div`
	position: relative;
	width: 100%;
	height: 70px;
	background: ${({ theme }) => theme.colors.white};
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 2.5rem;
	box-sizing: border-box;
`;

const LogoImg = styled.img`
	width: 180px;
`;

const LinkContainer = styled.div`
	position: absolute;
	top: 0;
	left: 50%;
	height: 100%;
	transform: translateX(-50%);
	display: flex;
	align-items: center;
	justify-content: center;
`;

const LinkItem = styled(Link)`
	position: relative;
	height: 100%;
	width: 100%;
	color: ${({ theme }) => theme.colors.black};
	text-decoration: none;
	font-size: 20px;
	line-height: 26px;
	font-weight: 500;
	padding: 20px;
	display: flex;
	align-items: center;
`;

const DetailContainer = styled.div`
	display: flex;
	align-items: center;
`;

const DetailWrapper = styled.div`
	height: 34px;
	border-radius: 17px;
	padding: 2px;
	background: ${({ theme }) => theme.colors.light_grey1};
	display: flex;
	justify-content: space-between;
	align-items: center;

	p {
		padding: 0 10px;
	}
`;

const IconWrapper = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: ${({ theme }) => theme.colors.green1};
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
	}
`;

const ArrowIcon = styled.img`
	width: 10px;
	margin: 0 10px 0 4px;
`;

const ChangeWalletWrapper = styled(DetailWrapper)`
	position: absolute;
	top: 34px;
	left: 0;
	width: 100%;
	background: ${({ theme }) => theme.colors.white};
	box-shadow: 0 7px 13px ${({ theme }) => theme.colors.shadow};
	cursor: pointer;
`;

const ThemeButton = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	margin: 4px;
	cursor: pointer;

	img {
		width: 24px;
		height: 24px;
	}
`;

const FaucetButton = styled.div`
	display: flex;
	width: 24px;
	height 24px;
	align-items: center;
	justify-content: center;
	border-radius: 50%;
	background: rgba(68, 114, 255, 1);
	margin: 4px;
	cursor: pointer;
	&: hover {
			opacity: 0.5;
	}

	img {
		width: 20px;
		height: 20px;
	}
`;

const NavItem = ({ to, title }: INavItem) => {
	const location = useLocation();

	return <LinkItem to={to}>{title}</LinkItem>;
};

interface IProps extends IModalProps {
	onClaim: () => void;
}

interface IProps extends IModalProps {
	onFaucet: () => void;
}

interface INavItem {
	to: string;
	title: string;
}

export const MenuModal: React.FC<IProps> = ({ open, onClaim, onFaucet, onClose }) => {
	const { connect, connected, disconnect, walletAddress, walletType } = useWallet();
	const { toClaim } = useUser();
	const [showChangeWallet, setShowChangeWallet] = useState(false);
	const [showFaucet, setShowFaucet] = useState(false);
	const { theme, setTheme } = useSettings();

	const handleClickWalletButton = () => {
		if (!showChangeWallet) {
			setShowChangeWallet(true);
		} else {
			copy(walletAddress || '');
			toast.success('Copied the wallet address');
		}
	};

	const handleChangeWallet = (e: any) => {
		e.stopPropagation();
		setShowChangeWallet(false);
		disconnect();
		connect();
	};

	const getWalletLogo = () => {
		switch (walletType) {
			case WalletType.Freighter:
				return freighterIcon;
			case WalletType.Albedo:
				return albedoIcon;
			default:
				return scripteeIcon;
		}
	};

	return (
		<div>
			<TopRightModal open={open} onClose={onClose} width={250}>
				<RowItem style={{ flexDirection: 'column', borderTop: 'none'}}>
					{!connected || !walletAddress ? (
						<>
						<Button variant="primary" onClick={connect} style={{ cursor: 'pointer', display: 'inline-block' }}>
							Connect Wallet
						</Button>

						<DetailWrapper style={{ width: 100, cursor: 'pointer', marginTop: 20 }}>
							<FaucetButton onClick={onFaucet}>
								<img src={faucetIcon} alt="" />
							</FaucetButton>

								<ThemeButton onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
									<img src={theme === 'dark' ? lightIcon : darkIcon} alt="" />
								</ThemeButton>
						</DetailWrapper>
						</>
					) : (
						<DetailContainer style={{ display: 'flex', flexWrap: 'wrap' }}>
							<div style={{ marginRight: '100%', position: 'relative' }}>
								<DetailWrapper style={{ cursor: 'pointer' }} onClick={handleClickWalletButton}>
									<IconWrapper style={{ background: '#F0F0F0' }}>
										<img src={getWalletLogo()} alt="" />
									</IconWrapper>
									<p>{getShortenWalletAddress(walletAddress)}</p>
									{showChangeWallet ? <ArrowIcon src={copyIcon} alt="" /> : <ArrowIcon src={arrowIcon} alt="" />}
								</DetailWrapper>

								{showChangeWallet && (
									<ChangeWalletWrapper onClick={() => setShowChangeWallet(false)}>
										<p onClick={handleChangeWallet}>
											<GreenText>Disconnect</GreenText>
										</p>
										<ArrowIcon src={arrowIcon} alt="" style={{ transform: 'rotate(180deg)' }} />
									</ChangeWalletWrapper>
								)}
							</div>

							<DetailWrapper onClick={onClaim} style={{ cursor: 'pointer', marginTop: 30 }}>
								<p>{getFormattedNumber(toClaim)}</p>
								<IconWrapper>
									<img src={ybxIcon} alt="" />
								</IconWrapper>
							</DetailWrapper>

							<DetailWrapper onClick={onFaucet} style={{ cursor: 'pointer', marginTop: 30, marginLeft: 30 }}>
								<FaucetButton>
									<img src={faucetIcon} alt="" />
								</FaucetButton>
							</DetailWrapper>

							<DetailWrapper style={{ cursor: 'pointer', marginTop: 30, marginLeft: 30 }}>
								<ThemeButton onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
									<img src={theme === 'dark' ? lightIcon : darkIcon} alt="" />
								</ThemeButton>
							</DetailWrapper>
							
						</DetailContainer>
					)}
				</RowItem>

				<div>
					<WalletButton style={{width: '100%', height: '100%' }} onClick={onClose}>
						<NavItem to="/market" title="Market" />
					</WalletButton>

					<WalletButton style={{width: '100%', height: '100%' }} onClick={onClose}>
						<NavItem to="/profile" title="Profile" />
					</WalletButton>

					<WalletButton style={{width: '100%', height: '100%' }} onClick={onClose}>
						<NavItem to="/governance" title="Governance" />
					</WalletButton>

					<WalletButton style={{width: '100%', height: '100%', borderBottomLeftRadius: 20, borderBottomRightRadius: 20 }} onClick={onClose}>
						<NavItem to="/turret" title="Turrets" />
					</WalletButton>
				</div>
			</TopRightModal>
		</div>
	);
};
