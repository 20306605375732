import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Table, Input, Button } from 'components';
import { Tooltip } from '@material-ui/core';
import { useWallet, useYieldBlox, useUser, useTurret } from 'contexts';
import { getDisplayBalance, getShortenWalletAddress } from 'utils';

import questionIcon from 'assets/images/icn_question.png';
import turretIcon from 'assets/images/icn_turret.png';
import { Turret } from 'yieldblox-js';

const RowItem = styled.div`
	display: flex;
	align-items: center;
`;

const TableDiv = styled.div`
	width: 100%;
	overflow-x: auto;
	border-radius: 18px;
	position: relative;
`;

const TurretIcon = styled.img`
	width: 38px;
	height: 38px;
`;

const ToolText = styled.text`
	font: DM Sans;
	font-size: 14px;
`;

interface IProps {
	onClick: (item: Turret) => void;
}

export const TurretNetwork: React.FC<IProps> = ({ onClick }) => {
	const theme = useTheme();
	const { turrets } = useYieldBlox();

	const { loadBalances, feeBalances } = useTurret();

	const getRow = (turret: Turret, idx: number) => {
		const feeBalance = feeBalances[turret.turretId];
		return (
			<tr key={idx} onClick={() => onClick(turret)} style={{ cursor: 'pointer' }}>
				<td style={{ textAlign: 'left' }}>
					<RowItem>
						<TurretIcon src={turretIcon} alt="" />
						<div style={{ marginLeft: '1rem' }}>
							<span>{turret.rootUrl.replace('https://', '')}</span>
							<p style={{ fontSize: 12, color: theme.colors.grey1 }}>{getShortenWalletAddress(turret.accountId)}</p>
						</div>
					</RowItem>
				</td>
				<td>
					{feeBalance !== undefined ? (
						<b>{getDisplayBalance(feeBalance, 'XLM')}</b>
					) : (
						<Button
							style={{ width: 150, padding: 5 }}
							variant="primary"
							onClick={e => {
								e.stopPropagation();
								loadBalances();
							}}
						>
							Fetch Balance
						</Button>
					)}
				</td>
				<td>
					<Button variant="primary" style={{ width: 150, padding: 5 }}>
						Add fees
					</Button>
				</td>
			</tr>
		);
	};

	const rows = turrets.map((turret, idx) => getRow(turret, idx));

	return (
		<TableDiv>
			<Table>
				<thead>
					<tr>
						<th style={{ textAlign: 'left' }}>Turret ID/Root URL</th>
						<th>
							<Tooltip
								title={
									<ToolText style={{ color: 'white' }}>The amount of XLM you're providing to this turret.</ToolText>
								}
								arrow
							>
								<RowItem
									style={{ justifyContent: 'center' }}
									onClick={e => {
										e.stopPropagation();
									}}
								>
									Fee Balance
									<img src={questionIcon} alt="" style={{ marginLeft: 4 }} />
								</RowItem>
							</Tooltip>
						</th>
						<th></th>
					</tr>
				</thead>

				<tbody>{rows}</tbody>
			</Table>
		</TableDiv>
	);
};
