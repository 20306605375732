import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Card, Divider, SectionTitle } from 'components';
import { WalletType } from 'types';
import { useUser, useWallet } from 'contexts';
import { getDisplayBalance, getShortenWalletAddress } from 'utils';
import { TurretNetwork } from './TurretNetwork';

import albedoIcon from 'assets/images/wallet_albedo.svg';
import freighterIcon from 'assets/images/wallet_freighter.svg';
import scripteeIcon from 'assets/images/icn_scriptee.svg';
import greenIIcon from 'assets/images/green_i_icn.svg';
import { NATIVE, XLM_RESERVE, Turret } from 'yieldblox-js';
import { TurretModal } from 'components/modals/TurretModal';

const RowItem = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const BalanceCard = styled(Card)`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 1rem;
	overflow: auto;
`;

const DetailWrapper = styled.div`
	height: 34px;
	border-radius: 17px;
	padding: 2px;
	background: ${({ theme }) => theme.colors.light_grey1};
	display: flex;
	justify-content: space-between;
	align-items: center;

	p {
		padding: 0 10px;
	}
`;

const IconWrapper = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background: ${({ theme }) => theme.colors.green1};
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		width: 100%;
	}
`;

export const TurretPage = () => {
	const theme = useTheme();

	const { connected, walletAddress, walletType } = useWallet();
	const { user } = useUser();

	const [selectedTurret, setSelectedTurret] = useState<Maybe<Turret>>(undefined);
	const [showTurretModal, setShowTurretModal] = useState<boolean>(false);

	const xlmBalance = user.id ? parseFloat(user.getBalance(NATIVE) ?? '0') : 0;

	const getWalletLogo = () => {
		switch (walletType) {
			case WalletType.Freighter:
				return freighterIcon;
			case WalletType.Albedo:
				return albedoIcon;
			default:
				return scripteeIcon;
		}
	};

	const handleShowTurretModal = (turret: Turret) => {
		setSelectedTurret(turret);
		setShowTurretModal(true);
	};

	return (
		<>
			{connected && (
				<>
					<BalanceCard>
						<img src={`/assets/xlm.svg`} alt="" style={{ width: 76, height: 76 }} />
						<RowItem style={{ width: '100%', padding: '1rem' }}>
							<p style={{marginRight: 30}}>Your wallet's XLM balance</p>
							<h6>{getDisplayBalance(xlmBalance, 'XLM')}</h6>
						</RowItem>
						<div style={{ borderLeft: `1px solid ${theme.colors.light_grey2}`, padding: '2rem' }}>
							<DetailWrapper>
								<IconWrapper style={{ background: '#F0F0F0' }}>
									<img src={getWalletLogo()} alt="" />
								</IconWrapper>
								<p>{getShortenWalletAddress(walletAddress)}</p>
							</DetailWrapper>
						</div>
					</BalanceCard>

					<div style={{paddingLeft: 30, paddingRight: 30}}>
						<RowItem style={{marginTop: 30, justifyContent: 'flex-start'}}>
							<div style={{width: 30, height: '100%', marginRight: 20}}>
								<IconWrapper style={{width: 30, height: 30, display: 'inline-block'}}>
									<img style={{width: '100%', height: '100%'}} src={greenIIcon} alt="" />
								</IconWrapper>
							</div>
							<b style={{fontSize: '85%', display: 'inline-block'}}> Stellar Turrets require a small fee to function. Add fees to the the supporting Turrets below to use YieldBlox. </b>
						</RowItem>
					</div>

					<Divider />
				</>
			)}

			<RowItem>
				<SectionTitle title="Turret Network" />
			</RowItem>

			<TurretNetwork onClick={handleShowTurretModal} />

			{selectedTurret && user.id && (
				<TurretModal open={showTurretModal} turret={selectedTurret} onClose={() => setShowTurretModal(false)} />
			)}
		</>
	);
};
