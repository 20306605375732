import React from 'react';
import styled from 'styled-components';

const TitleWrapper = styled.div`
	font-size: 20px;
	line-height: 26px;
	padding: 16px;

	span {
		font-weight: bold;
		margin-right: 10px;
		color: ${({ theme }) => theme.colors.green};
	}
`;

interface IProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
	title: string;
}

export const SectionTitle: React.FC<IProps> = ({ title }) => {
	return (
		<TitleWrapper>
			<span>-</span>
			{title}
		</TitleWrapper>
	);
};
