import React from 'react';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import { ThemedGlobalStyle, lightTheme, darkTheme } from 'theme';
import { Main } from 'pages';
import { WalletProvider, YieldBloxProvider, PriceProvider, UserProvider } from 'contexts';
import { HashRouter } from 'react-router-dom';
import { useSettings } from 'contexts/settings';

import 'react-toastify/dist/ReactToastify.css';
import { TurretProvider } from 'contexts/turret';

function App() {
	const { theme } = useSettings();

	return (
		<ThemeProvider theme={theme === 'dark' ? darkTheme : lightTheme}>
			<ThemedGlobalStyle />

			<YieldBloxProvider>
				<PriceProvider>
					<WalletProvider>
						<UserProvider>
							<TurretProvider>
								<HashRouter>
									<Main />
								</HashRouter>
							</TurretProvider>
						</UserProvider>
					</WalletProvider>
				</PriceProvider>
			</YieldBloxProvider>

			<ToastContainer autoClose={2000} />
		</ThemeProvider>
	);
}

export default App;
