import * as horizonApi from './horizonApi';
import {
	TransactionBuilder,
	AccountResponse,
	Memo,
	Asset,
	ServerApi,
	Transaction,
	Account,
	Operation,
} from 'stellar-sdk';
import { HORIZON_NETWORK } from 'config/constants';
import { AssetRecord } from 'stellar-sdk/lib/types/assets';
import { PaymentPathDetail } from './paymentPathDetail';
import { assetHelper } from 'utils/protocol';
import { transformer } from 'yieldblox-js';

/**
 * Stellar Helper
 * Helper functions to work with Stellar
 */

//========== TransactionBuilder ==========

/**
 * Fetches the current suggested fee
 * @returns A valid fee
 */
export async function GetCurrentFee(): Promise<string> {
	try {
		let feeStats = await horizonApi.GetFeeStatsAsync();
		let fee = parseFloat(feeStats.fee_charged.mode) * 2;
		return fee.toFixed(7);
	} catch (error) {
		throw error;
	}
}

/**
 * Creats a TransactionBuilder object
 * @param sourceAccount - The source account for the Transaction
 * @param memo - The memo for the Transaction
 * @param maxTime - The UNIX timestamp where the Transaction will be invalid after
 * @param minTime - The UNIX timestamp where the Transaction will be invalid before
 * @returns A TransactionBuilder object
 */
export async function CreateTransactionBuilder(
	sourceAccount: AccountResponse,
	memo: string,
	maxTime?: number,
	minTime?: number
): Promise<TransactionBuilder> {
	let fee = await GetCurrentFee();
	if (!maxTime) {
		// set to 5 min in the future by default
		// TransactionBuilder takes UNIX (by the second) timestamp
		maxTime = Math.floor(Date.now() / 1000) + 5 * 60;
	}
	// set minTime to immediately if not provided
	if (!minTime) minTime = 0;

	return new TransactionBuilder(sourceAccount, {
		memo: Memo.text(memo),
		fee: fee,
		networkPassphrase: HORIZON_NETWORK,
		timebounds: {
			maxTime: maxTime,
			minTime: minTime,
		},
	});
}

/**
 *
 * @param walletAddress The wallet address to generate a token for
 * @returns
 */
export function CreateUnsignedTokenXdr(walletAddress: string, hash: string): string {
	const tempAcct = new Account(walletAddress, '-1');
	const txBuilder = new TransactionBuilder(tempAcct, { fee: '100', networkPassphrase: HORIZON_NETWORK });

	txBuilder.addOperation(
		Operation.manageData({
			name: 'txFunctionHash',
			value: hash,
		})
	);

	// set TTL on the token for 1 hour
	return txBuilder
		.setTimeout(31 * 60)
		.build()
		.toXDR();
}

export async function SubmitTransaction(envelopeXdr: string): Promise<string> {
	let tx = TransactionBuilder.fromXDR(envelopeXdr, HORIZON_NETWORK);
	if (tx instanceof Transaction) {
		try {
			let result = await horizonApi.SubmitTransactionAsync(tx);
			return result.hash;
		} catch (e: any) {
			throw e;
		}
	} else {
		throw new Error('XDR can not be for a FeeBumpTransaction');
	}
}

//========== Trustlines ==========

/**
 * Verify a trustline for an account
 * @param account - The account response to verify the trustline for
 * @param asset - The asset to verify the trustline for
 * @returns {boolean}
 */
export function VerifyTrustline(account: AccountResponse, asset: Asset): boolean {
	if (asset.isNative()) {
		return true;
	}
	return account.balances.some(b => {
		let _b: any = b;
		return (
			_b.asset_type === asset.getAssetType() &&
			_b.asset_code === asset.getCode() &&
			_b.asset_issuer === asset.getIssuer()
		);
	});
}

//========== Assets ==========

/**
 * Find the outstanding token amount for a given asset
 * @param assetId - The assetId of the asset
 */
export async function GetOutstandingTokens(assetId: string): Promise<number> {
	try {
		let assetIds = assetId.split(':');
		let assetRecord = await horizonApi.GetAssetAsync(assetIds[0], assetIds[1]);
		return parseFloat(assetRecord.amount) + parseFloat(assetRecord.claimable_balances_amount);
	} catch (e) {
		console.error(e);
		return 0;
	}
}

/**
 * Find the asset record for a given asset
 * @param assetId - The assetId of the asset
 */
export async function GetAssetRecordAsync(assetId: string): Promise<AssetRecord> {
	try {
		let assetIds = assetId.split(':');
		return await horizonApi.GetAssetAsync(assetIds[0], assetIds[1]);
	} catch (e) {
		console.error(e);
		throw e;
	}
}

/**
 * Find DEX price for asset
 * @param baseId - The id of the asset to be the base
 * @param counterId - The id of the asset to be the counter
 * @returns
 */
export async function GetDEXPriceAsync(baseId: string, counterId: string): Promise<number> {
	try {
		let tradeAggregation = await horizonApi.GetTradeAggregationAsync(baseId, counterId);
		return parseFloat(tradeAggregation.avg);
	} catch (e) {
		return 0.1; // TODO - Just don't want to deal with this during testing.
	}
}

//========== Claimable Balances ==========

/**
 * Verify an account is an unconditional claimant of a claimable balance
 * @param claimableBalance - The Claimable Balance to check the claimants of
 * @param claimantId - The public key of the claimant
 */
export function VerifyUnconditionalClaimant(
	claimableBalance: ServerApi.ClaimableBalanceRecord,
	claimantId: string
): boolean {
	for (let claimant of claimableBalance.claimants) {
		if (claimant.destination !== claimantId) continue;
		//@ts-ignore - Typing is odd here - TODO: Submit issue
		return claimant.predicate.uncondictional;
	}
	return false;
}

//========== Order Book ==========

/**
 * Get the market rate for a given asset transfer
 * @param sendAssetId - The assetId for the asset to sell / send
 * @param destAssetId - The assetId for the asset to buy / send
 * @param sendAmount - The amount to sell / send
 * @returns The market quote price, or zero if no price was found
 */
export async function GetSendMarketQuoteAsync(
	sendAssetId: string,
	destAssetId: string,
	sendAmount: number
): Promise<PaymentPathDetail> {
	// inflate send amount to avoid weak paths
	let inflation = 1.1;
	let inflatedSendAmount = sendAmount * inflation;
	let pathPaymentRecord = await horizonApi.GetPathStrictSendAsync(
		sendAssetId,
		destAssetId,
		inflatedSendAmount.toFixed(7)
	);
	let path: PaymentPathDetail = new PaymentPathDetail();
	path.value = 0;
	if (pathPaymentRecord) {
		path.value = Number(pathPaymentRecord.destination_amount) / Number(pathPaymentRecord.source_amount);
		path.sendAssetId = sendAssetId;
		path.sendAmount = pathPaymentRecord.source_amount;
		path.destAssetId = destAssetId;
		path.destAmount = pathPaymentRecord.destination_amount;
		pathPaymentRecord.path.forEach(e => {
			path.path.push(transformer.getAssetIdFromObject(e.asset_type, e.asset_issuer, e.asset_code));
		});
	}
	return path;
}

/**
 * Get the market rate for a given asset transfer
 * @param sendAssetId - The assetId for the asset to sell / send
 * @param destAssetId - The assetId for the asset to buy / send
 * @param sendAmount - The amount to sell / send
 * @returns The market quote price, or zero if no price was found
 */
export async function GetReceiveMarketQuoteAsync(
	sendAssetId: string,
	destAssetId: string,
	recieveAmount: number
): Promise<PaymentPathDetail> {
	let path: PaymentPathDetail = new PaymentPathDetail();
	// inflate send amount to avoid weak paths
	let inflatedSendAmount = recieveAmount * 1.1;
	let pathPaymentRecord = await horizonApi.GetPathStrictReceiveAsync(
		sendAssetId,
		destAssetId,
		inflatedSendAmount.toFixed(7)
	);
	if (pathPaymentRecord) {
		path.value = Number(pathPaymentRecord.destination_amount) / Number(pathPaymentRecord.source_amount);
		path.sendAssetId = sendAssetId;
		path.sendAmount = pathPaymentRecord.source_amount;
		path.destAssetId = destAssetId;
		path.destAmount = pathPaymentRecord.destination_amount;
		pathPaymentRecord.path.forEach(e => {
			path.path.push(transformer.getAssetIdFromObject(e.asset_type, e.asset_issuer, e.asset_code));
		});
	}
	return path;
}
