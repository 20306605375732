import React from 'react';
import styled from 'styled-components';
import { IModalProps, Modal } from 'components';

import ybxIcon from 'assets/images/icn_ybx.svg';
import loadingSpinner from 'assets/images/loading_spinner.gif';
import { getShortenWalletAddress } from 'utils';
import { TxText } from 'types';

const Content = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;
	padding: 48px;
`;

const RowItem = styled.div`
	display: flex;
	align-items: center;
	padding: 10px 0;
`;

const DetailWrapper = styled.div`
	height: 34px;
	border-radius: 17px;
	margin-left: 16px;
	padding: 2px;
	background: ${({ theme }) => theme.colors.light_grey1};
	display: flex;
	justify-content: space-between;
	align-items: center;

	img {
		width: 30px;
	}

	p {
		padding: 0 10px;
	}
`;

const IconWrapper = styled.div`
	width: 100px;
	height: 100px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	img {
		width: 100%;
	}
`;

interface IProps extends IModalProps {
	wallet: string;
	txText: TxText;
}

export const ConfirmTransactionModal: React.FC<IProps> = ({ open, txText, wallet, onClose }) => {
	let header = '';
	let footer = '';
	switch (txText) {
		case TxText.Token:
			header = 'Please confirm the token sent to your wallet.';
			footer = 'This is to verify you are the wallet owner, and is NOT a valid transaction.';
			break;
		case TxText.DepositCollateral:
			header = 'Please confirm the claimable balance creation in your wallet';
			footer = 'The contract execution will occur after this is submitted.';
			break;
		case TxText.Contract:
		case TxText.Default:
			header = 'Please confirm the transaction in your wallet';
			footer = '';
			break;
	}
	return (
		<Modal open={open} onClose={onClose} width={490}>
			<Content>
				<IconWrapper>
					<img src={loadingSpinner} alt="" />
				</IconWrapper>
				<RowItem>
					<b style={{ fontSize: 26, lineHeight: '34px' }}>{header}</b>
				</RowItem>
				<RowItem>
					<b>Wallet Name</b>
					<DetailWrapper>
						<img src={ybxIcon} alt="" />
						<p>{getShortenWalletAddress(wallet)}</p>
					</DetailWrapper>
				</RowItem>
				<RowItem>
					<p>{footer}</p>
				</RowItem>
			</Content>
		</Modal>
	);
};
