import albedoIntent from '@albedo-link/intent';
import { v4 as uuid } from 'uuid';
import { HORIZON_NETWORK } from 'config/constants';

export async function connect(): Promise<string> {
	let token = uuid();
	let result = await albedoIntent.publicKey({ token: token });
	return result.pubkey;
}

export async function sign(xdr: string): Promise<string> {
	let result = await albedoIntent.tx({ xdr: xdr, network: HORIZON_NETWORK });
	return result.signed_envelope_xdr;
}
