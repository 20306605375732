/**
 * Util functions to help the UI display information
 */

import BigNumber from 'bignumber.js';
import { PricePair } from 'contexts/price';
import { Position } from 'types';
import { parse } from 'uuid';
import { Lend } from 'yieldblox-js';

/**
 * Returns a consistent, shorten wallet address
 * @param address
 * @returns
 */
export const getShortenWalletAddress = (address: Maybe<string>): string => {
	if (!address) {
		return '';
	}

	return `${address.substr(0, 4)}...${address.substr(address.length - 4, address.length)}`;
};

export const getAdjDisplayBalance = (balance: Maybe<number>, rate: Maybe<number>): string => {
	if (!balance) {
		return '--';
	}

	let exchangeRate: number;
	if (!rate) {
		console.log('Rate undefined');
		exchangeRate = 1;
	} else {
		exchangeRate = rate;
	}

	return `${getFormattedNumber(exchangeRate * balance)}`;
};

export const getAdjBalance = (balance: Maybe<number>, rate: Maybe<number>): number => {
	if (!balance) {
		return 0;
	}

	let exchangeRate: number;
	if (!rate) {
		console.log('Rate undefined');
		exchangeRate = 1;
	} else {
		exchangeRate = rate;
	}

	return exchangeRate * balance;
};

export const getDisplayBalance = (balance: Maybe<number>, assetCode: string, fullDisplay: boolean = false): string => {
	if (balance == undefined) {
		return '--';
	} else if (balance === 0) {
		return `0 ${assetCode}`;
	}

	if (fullDisplay) {
		return `${balance.toFixed(7)} ${assetCode}`;
	} else {
		return `${getFormattedNumber(balance)} ${assetCode}`;
	}
};

export const getDisplayRate = (rate: Maybe<number>): string => {
	if (!rate || Math.abs(rate) === Infinity) {
		return '0';
	}

	return `${(rate * 100).toFixed(2)}`;
};

export const getCollateralRate = (position: Maybe<Lend>): string => {
	if (!position) {
		return '0';
	}

	const posCBalance = parseFloat(position.balance);
	const posUCBalance = parseFloat(position.uncollateralizedBalance);
	const posTotalBalance = posCBalance + posUCBalance;
	if (posCBalance === 0) {
		return '0';
	}

	return ((posCBalance / posTotalBalance) * 100).toFixed(1);
};

export const getFormattedNumber = (value: number, loop: boolean = true): string => {
	if (loop) {
		if (value >= 1e9) {
			return `${getFormattedNumber(value * 1e-9, false)}b`;
		}
		if (value >= 1e6) {
			return `${getFormattedNumber(value * 1e-6, false)}M`;
		}
		if (value >= 1e3) {
			return `${getFormattedNumber(value * 1e-3, false)}k`;
		}
		if (value >= 1e-6) {
			if (value < 1e-4) {
				return `${getFormattedNumber(value * 1e6, false)}μ`;
			}
			if (value < 1e-1) {
				return `${getFormattedNumber(value * 1e3, false)}m`;
			}
		}
	}

	const res = value.toFixed(2);
	let parts = res.split('.');
	parts[0] = Number(parts[0]).toLocaleString('en-US');

	return parts.join('.');
};

export const getDisplayHealthFactor = (value: Maybe<number>): string => {
	if (value !== undefined && value !== Number.MAX_SAFE_INTEGER) {
		if (value > 1000) {
			return 'Good';
		}
		return value.toFixed(3);
	}
	return 'N/A';
};

export const getTimeDifference = (time: Maybe<number>) => {
	if (time == undefined) {
		return 'none';
	}
	const diff = Math.abs(time - Date.now());

	const secs = diff / 1000;
	if (secs < 60) {
		return `${Math.floor(secs)} secs`;
	}
	const mins = secs / 60;
	if (mins < 60) {
		return `${Math.floor(mins)} mins`;
	}
	const hours = mins / 60;
	if (hours < 24) {
		return `${Math.floor(hours)} hours`;
	}
	const days = hours / 24;
	return `${Math.floor(days)} days`;
};
