import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { Button, Card, EscrowModal } from 'components';
import { GreenText, PurpleText } from 'theme';
import { useUser, useYieldBlox } from 'contexts';
import { getDisplayBalance, getDisplayRate } from 'utils';
import { VEYBX_TOKEN, YBX_TOKEN } from 'config/constants';
import { Tooltip } from '@material-ui/core';

import ybxIcon from 'assets/images/icn_ybx.svg';
import ybxIcon1 from 'assets/images/icn_ybx1.svg';
import questionIcon from 'assets/images/icn_question.png';
import background from 'assets/images/escrow_background_leaf.svg';

const DetailCard = styled(Card)`
	width: 100%;
	min-width: 200px;
	display: flex;
	flex-direction: column;
	font-size: 18px;
	line-height: 24px;
	background: ${({ theme }) => theme.colors.white + '99'};
	border: 2px solid ${({ theme }) => theme.colors.purple};
`;

const HeroWrapper = styled.div`
	border-radius: 18px;
	padding: 6px;
	width: 100%;
	min-width: 170px;
	background: transparent;
	border: 2px solid ${({ theme }) => theme.colors.purple};

	box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
	box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.01);
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	}
`;

const FilterDiv = styled.div`
	width: 100%;
	background: ${({ theme }) => theme.colors.light_grey + 'B3'};
	border-radius: 12px;
	overflow: hidden;
	text-align: center;
	box-shadow: 0px 4px 10px ${({ theme }) => theme.colors.light_grey + '90'};

	background-size: cover;
	background-repeat-y: no-repeat;
	background-position: center bottom;
`;

const HelpIcon = styled.img`
	position: absolute;
	top: 10px;
	right: 10px;
`;

const Flex = styled.div`
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
`;

const DetailWrapper = styled.div`
	width: 100%;
	height: 34px;
	border-radius: 17px;
	padding: 2px;
	padding-right: 20px;
	background: ${({ theme }) => theme.colors.light_grey + 'B3'};
	box-shadow: 0px 7px 13px rgba(0, 0, 0, 0.05);
	display: flex;
	justify-content: space-between;
	align-items: center;

	p {
		padding: 0 10px;
	}
`;

const IconWrapper = styled.div<{ color: string }>`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	display: flex;
	align-items: center;
	justify-content: center;

	img {
		color: ${({ color }) => color};
		width: 30px;
	}
`;

const ToolText = styled.text`
	font: DM Sans;
	font-size: 14px;
`;

const EscrowDiv = styled.div`
	border-radius: 18px;
	background-size: cover;
	background-repeat-y: no-repeat;
	background-position: center bottom;
`;

interface IProps {}

export const EscrowInfo: React.FC<IProps> = () => {
	const theme = useTheme();
	const { user } = useUser();
	const { ybxAsset, veYbxRate } = useYieldBlox();

	const [showModal, setShowModal] = useState(false);

	const ybxBalance = user.id ? Number(user.getBalance(YBX_TOKEN) || '0') : 0;
	const veYbx = user.id ? Number(user.getBalance(VEYBX_TOKEN) || '0') : 0;
	return (
		<>
			<EscrowDiv style={{ backgroundImage: `url(${background})`}}>
				<DetailCard style={{paddingBottom: 14}}>
					<h5>
						<PurpleText>Escrowed</PurpleText> YBX
					</h5>

					<div>
						<HeroWrapper>
								<FilterDiv style={{ justifyContent: 'space-around', padding: 10}}> 
									<div style={{height: '100%', justifyContent: 'space-around'}}>
										<h6 style={{color: theme.colors.purple, marginTop: 4, display: 'inline-block'}}> 1 veYBX </h6> 
										<h6 style={{marginTop: 4, marginRight: 20, marginLeft: 20, display: 'inline-block'}}> = </h6> 
										<h5 style={{ display: 'inline-block', color: theme.colors.green, fontWeight: 300}}> {getDisplayRate(veYbxRate)} YBX </h5>
									</div>									
								</FilterDiv>
								
						</HeroWrapper>
					</div>

					<Flex style={{ marginTop: 10 }}>
						<Flex style={{ width: '50%', minWidth: '170px', flexDirection: 'column' }}>
							<DetailWrapper style={{ marginBottom: 13, border: `2px solid ${theme.colors.purple}`}}>
								<IconWrapper style={{ background: theme.colors.white }} color={theme.colors.purple}>
									<img src={ybxIcon1} alt="" />
								</IconWrapper>
								<div>{getDisplayBalance(veYbx, '')}</div>
								<span style={{ color: theme.colors.purple }}>veYBX</span>
							</DetailWrapper>

							<DetailWrapper style={{ border: `2px solid ${theme.colors.green}` }}>
								<IconWrapper style={{ background: theme.colors.white }} color={theme.colors.green}>
									<img src={ybxIcon} alt="" />
								</IconWrapper>
								<div>{getDisplayBalance(ybxBalance, '')}</div>
								<span style={{ color: theme.colors.green, marginLeft: 20 }}>YBX</span>
							</DetailWrapper>
						</Flex>

						<Flex style={{ width: '50%', marginTop: 10 }}>
							<Button
								style={{ background: '#6D6FFF', color: '#FFFFFF' }}
								onClick={() => setShowModal(true)}
							>
								Manage
							</Button>
						</Flex>
					</Flex>

					<Tooltip
						title={
							<ToolText style={{ color: 'white' }}>
								Escrowed YBX: your current loan-to-value for locked YBX, and your
								current balance of veYBX and YBX.
							</ToolText>
						}
						arrow
					>
						<HelpIcon src={questionIcon} alt="" />
					</Tooltip>
				</DetailCard>
			</EscrowDiv>

			{user.id && <EscrowModal open={showModal} onClose={() => setShowModal(false)} />}
		</>
	);
};
