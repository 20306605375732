import React from 'react';
import styled from 'styled-components';
import { Table, Toggle, AssetIcon } from 'components';
import { GreenText } from 'theme';
import { PoolAssetData, SortableRow } from 'types';
import { usePrices, useYieldBlox, useUser } from 'contexts';
import { getAdjDisplayBalance, getDisplayBalance, getDisplayRate } from 'utils';
import { mathHelper } from 'utils/protocol';
import questionIcon from 'assets/images/icn_question.png';
import { Tooltip } from '@material-ui/core';
import { YBX_TOKEN } from 'config/constants';

const RowItem = styled.div`
	display: flex;
	align-items: center;
`;

const TableDiv = styled.div`
	width: 100%;
	overflow-x: auto;
	border-radius: 18px;
	position: relative;
`;

const ToolText = styled.text`
	font: DM Sans;
	font-size: 14px;
`;

interface IProps {
	onBorrow: (item: PoolAssetData) => void;
}

export const BorrowMarket: React.FC<IProps> = ({ onBorrow }) => {
	const { poolAssets } = useYieldBlox();
	const { user, healthFactor } = useUser();
	const { prices } = usePrices();

	const getRow = (item: PoolAssetData, idx: number): SortableRow => {
		// find position for asset and user
		const position = user.positions?.getBorrowedPosition(item.poolAsset.liabilityTokenId);
		const positionValue = parseFloat(position?.balance ?? '0');
		const posUnderlyingValue = positionValue * item.lTokenRate;

		const assetCode = item.poolAsset.underlyingId.split(':')[0];

		const assetPrice = prices[item.poolAsset.underlyingId];
		const ybxPrice = prices[YBX_TOKEN];
		const tokenYield = mathHelper.CalculateTokenYield(
			item.lTokenIssuance,
			item.lTokenRate,
			assetPrice?.rate,
			ybxPrice?.rate ?? 1
		);

		const marketSize = item.totalUnderlying;
		const tvl = marketSize * assetPrice?.rate ?? 1;

		const maxBorrowUSD = parseFloat(healthFactor.borrowLimitUSD) - parseFloat(healthFactor.liabilitiesUSD);
		return {
			totalValueLocked: tvl,
			hasPosition: positionValue !== 0,
			row: (
				<tr key={idx} onClick={() => onBorrow(item)} style={{ cursor: 'pointer' }}>
					<td style={{ textAlign: 'left' }}>
						<RowItem>
							<AssetIcon assetCode={assetCode} />
							<span style={{ marginLeft: '1rem' }}>{assetCode}</span>
						</RowItem>
					</td>
					<td></td>
					<td>{getDisplayBalance(marketSize, assetCode)}</td>
					<td>{getDisplayBalance(item.poolBalance, assetCode)}</td>
					<td>
						{getDisplayRate(item.borrowRate)}
						<GreenText>%</GreenText>
					</td>
					<td>
						{getDisplayRate(tokenYield)}
						<GreenText>%</GreenText>
					</td>
					<td>{getDisplayBalance(positionValue === 0 ? undefined : posUnderlyingValue, assetCode)}</td>
					<td>
						{getAdjDisplayBalance(maxBorrowUSD, assetPrice?.reverseRate)} {assetCode}
					</td>
				</tr>
			),
		};
	};

	// compare positions, sort by liquidity
	let poolAssetKeys = Object.keys(poolAssets);
	let unsortedRows = poolAssetKeys.map((key, idx) => getRow(poolAssets[key], idx));
	let sortedRows = unsortedRows
		.sort((a, b) =>
			b.hasPosition === a.hasPosition ? b.totalValueLocked - a.totalValueLocked : b.hasPosition ? 1 : -1
		)
		.map(x => x.row);

	return (
		<TableDiv>
			<Table>
				<thead>
					<tr>
						<th style={{ textAlign: 'left' }}>Asset</th>
						<th></th>
						<th> Market Size</th>
						<th>Liquidity</th>
						<th>
							<RowItem style={{ flexDirection: 'column' }}>
								<div>Interest Rate</div>
							</RowItem>
						</th>
						<th>
							<Tooltip
								title={<ToolText style={{ color: 'white' }}>The yearly YBX return from borrowing this asset.</ToolText>}
								arrow
							>
								<RowItem
									style={{ justifyContent: 'center' }}
									onClick={e => {
										e.stopPropagation();
									}}
								>
									YBX Issuance Rate
									<img src={questionIcon} alt="" style={{ marginLeft: 4 }} />
								</RowItem>
							</Tooltip>
						</th>
						<th>Current Borrow</th>
						<th>Max Borrow</th>
					</tr>
				</thead>

				<tbody>{sortedRows}</tbody>
			</Table>
		</TableDiv>
	);
};
