import BigNumber from 'bignumber.js';
import { AssetRecord } from 'stellar-sdk/lib/types/assets';
import { AssetConstants } from 'types';
import { HealthFactor, safeMath, STELLAR_MIN } from 'yieldblox-js';

export function stellarMinTruncate(n: number): string {
  let stellarMin: number = parseFloat(STELLAR_MIN);
  let minMultiple: number = 1 / stellarMin;
  let stellarValue: number = (
    Math.round(minMultiple * n) *
    stellarMin
  );
  return stellarValue.toFixed(
    Math.abs(Math.log10(stellarMin))
  );
}

//========== Rate Math ==========

export function CalculateLendYbxTokenEffect(
	outstandingYbx: number,
	poolTokenRecord: AssetRecord,
	yTokenRate: number,
	assetConsts: AssetConstants
): number {
	let issuanceForYear = CalculateYbxIssuanceForYear(outstandingYbx);
	let dailyIssuanceLend = issuanceForYear * assetConsts.lendingAllocation;
	let marketUnderlying = parseFloat(poolTokenRecord.claimable_balances_amount) * yTokenRate;
	return dailyIssuanceLend / marketUnderlying;
}

export function CalculateBorrowYbxTokenEffect(
	outstandingYbx: number,
	outstandingLiabilities: number,
	assetConsts: AssetConstants
): number {
	let issuanceForYear = CalculateYbxIssuanceForYear(outstandingYbx);
	let yearlyIssuanceBorrow = issuanceForYear * assetConsts.borrowingAllocation;
	let marketUnderlying = outstandingLiabilities;
	return yearlyIssuanceBorrow / marketUnderlying;
}

export function CalculateTokenYield(
	perTokenIssuance: number,
	tokenRate: number,
	underlyingPrice: Maybe<number>,
	ybxPrice: number
): number {
	// NOTE - assume prices are of the same denomination
	return perTokenIssuance * (ybxPrice / (underlyingPrice ?? 1));
}

export function CalculateYbxIssuanceForYear(outstandingYbx: number): number {
	const ybxTotal = 1_500_000_000;
	// TODO: Update - this value represents the yearly issuance percentage based on 1 year of 0.1% daily issuance #math
	const ybxIssuanceYearly = 0.238985164;
	const toBeIssued = ybxTotal - outstandingYbx;
	return ybxIssuanceYearly * toBeIssued;
}

/**
 * Calculate the Lending Spot Rate for an asset
 * @param borrowRate - The current borrow spot rate for the asset
 * @param utilRate - The current utilization rate for the asset
 * @returns
 */
export function CalculateLendSpotRate(borrowRate: number, utilRate: number): number {
	return borrowRate * utilRate;
}

//===== Avg Interest Rate Calcs =====
// NOTE: These are a bit intensive, and shouldn't be calculated unless necessary
export function CalculateSYBXSpotRate(outstandingSYBX: number, stakingPoolYbx: number): number {
	return stakingPoolYbx / outstandingSYBX;
}

export function CalculateAvgUtilRateForBalance(): number {
	throw new Error();
}

export function CalculatePoolTokenPresentValue(): number {
	return 100;
}

//========== Health Factors and Things ==========

export function CalculateMaxPermittedBorrow(liabilityHealth: BigNumber, collateralHealth: BigNumber): number {
	return collateralHealth.dividedBy(1.1).minus(liabilityHealth).times(0.99).toNumber();
}

/**
 * Calculate the new health factor for a user given some delta
 * @param healthFactor - thg
 * @param cDeltaUSD - The change in borrowing power in USD
 * @param lDeltaUSD - The change in liabilities in
 * @returns The new health factor
 */
export function CalculateNewHealthFactor(
	healthFactor: HealthFactor,
	cDeltaUSD: string | BigNumber,
	lDeltaUSD: string | BigNumber
): number {
	let liabilities = new BigNumber(healthFactor.liabilitiesUSD).plus(lDeltaUSD);
	let effectiveCollateral = new BigNumber(healthFactor.borrowLimitUSD).plus(cDeltaUSD);
	return safeMath.divideToNumber(effectiveCollateral, liabilities).toNumber();
}

/**
 * Calculate the amount of veYBX for a given escrow setup
 * @param ybx - The amount of ybx in escrow
 * @param period - How long the ybx will be in escrow
 */
export function CalculateVeYBXIssued(ybx: number, period: number): number {
	if (period >= 12) {
		return ybx * 4;
	} else if (period >= 6) {
		return ybx * 1.5;
	} else {
		return ybx;
	}
}
