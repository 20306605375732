import React from 'react';
import styled from 'styled-components';
import { Table, AssetIcon } from 'components';
import { GreenText } from 'theme';
import { usePrices, useYieldBlox, useUser } from 'contexts';
import { PoolAssetData, SortableRow } from 'types';
import { getCollateralRate, getDisplayBalance, getDisplayRate } from 'utils';
import { mathHelper } from 'utils/protocol';
import questionIcon from 'assets/images/icn_question.png';
import { Tooltip } from '@material-ui/core';

import editIcon from 'assets/images/icn_edit.svg';
import { Lend } from 'yieldblox-js';
import { YBX_TOKEN } from 'config/constants';

const RowItem = styled.div`
	display: flex;
	align-items: center;
`;

const TableDiv = styled.div`
	width: 100%;
	overflow-x: auto;
	border-radius: 18px;
	position: relative;
`;

const ToolText = styled.text`
	font: DM Sans;
	font-size: 14px;
`;

export interface ILendingMarket {
	onDeposit: (poolAsset: PoolAssetData) => void;
	onManageCollateral: (pAssetData: PoolAssetData, position: Maybe<Lend>) => void;
}

export const LendingMarket: React.FC<ILendingMarket> = ({ onDeposit, onManageCollateral }) => {
	const { poolAssets } = useYieldBlox();
	const { user } = useUser();
	const { prices } = usePrices();

	const getRow = (item: PoolAssetData, idx: number): SortableRow => {
		// find position for asset and user
		const position = user.positions?.getLentPosition(item.poolAsset.poolTokenId);
		const positionCValue = position?.balance ?? '0';
		const positionUCValue = position?.uncollateralizedBalance ?? '0';
		const posUnderlyingValue = (parseFloat(positionCValue) + parseFloat(positionUCValue)) * item.yTokenRate;

		const assetCode = item.poolAsset.underlyingId.split(':')[0];

		const assetPrice = prices[item.poolAsset.underlyingId];
		const ybxPrice = prices[YBX_TOKEN];
		const tokenYield = mathHelper.CalculateTokenYield(
			item.yTokenIssuance,
			item.yTokenRate,
			assetPrice?.rate,
			ybxPrice?.rate ?? 1
		);

		const marketSize = item.totalUnderlying;
		const tvl = marketSize * assetPrice?.rate ?? 1;

		return {
			totalValueLocked: tvl,
			hasPosition: position !== undefined,
			row: (
				<tr key={idx} onClick={() => onDeposit(item)} style={{ cursor: 'pointer' }}>
					<td style={{ textAlign: 'left' }}>
						<RowItem>
							<AssetIcon assetCode={assetCode} />
							<span style={{ marginLeft: '1rem' }}>{assetCode}</span>
						</RowItem>
					</td>
					<td></td>
					<td>{getDisplayBalance(marketSize, assetCode)}</td>
					<td>
						{getDisplayRate(item.lendRate)}
						<GreenText>%</GreenText>
					</td>
					<td>
						{getDisplayRate(tokenYield)}
						<GreenText>%</GreenText>
					</td>
					<td>
						{getDisplayRate(item.poolAsset.data.loanToValue)}
						<GreenText>%</GreenText>
					</td>
					<td>{getDisplayBalance(posUnderlyingValue, assetCode)}</td>
					<td>
						<RowItem
							style={{ justifyContent: 'center' }}
							onClick={e => {
								if (position && posUnderlyingValue > 0) {
									e.stopPropagation();
									onManageCollateral(item, position);
								}
							}}
						>
							{getCollateralRate(position)}
							<GreenText>%</GreenText>
							{position && posUnderlyingValue > 0 && <img src={editIcon} alt="" style={{ marginLeft: 4 }} />}
						</RowItem>
					</td>
				</tr>
			),
		};
	};

	// Sort by liquidity the hasPosition
	let poolAssetKeys = Object.keys(poolAssets);
	let unsortedRows = poolAssetKeys.map((key, idx) => getRow(poolAssets[key], idx));
	let sortedRows = unsortedRows
		.sort((a, b) =>
			b.hasPosition === a.hasPosition ? b.totalValueLocked - a.totalValueLocked : b.hasPosition ? 1 : -1
		)
		.map(x => x.row);

	return (
		<TableDiv>
			<Table>
				<thead>
					<tr>
						<th style={{ textAlign: 'left' }}>Asset</th>
						<th></th>
						<th>Market Size</th>
						<th>Interest Rate</th>
						<th>
							<Tooltip
								title={
									<ToolText style={{ color: 'white' }}>The yearly YBX return from collateralizing this asset.</ToolText>
								}
								arrow
							>
								<RowItem
									style={{ justifyContent: 'center' }}
									onClick={e => {
										e.stopPropagation();
									}}
								>
									YBX Issuance Rate
									<img src={questionIcon} alt="" style={{ marginLeft: 4 }} />
								</RowItem>
							</Tooltip>
						</th>
						<th>
							<Tooltip
								title={
									<ToolText style={{ color: 'white' }}>
										The percentage of this asset’s collateral effective against liabilities.
									</ToolText>
								}
								arrow
							>
								<RowItem
									style={{ justifyContent: 'center' }}
									onClick={e => {
										e.stopPropagation();
									}}
								>
									Loan-to-Value
									<img src={questionIcon} alt="" style={{ marginLeft: 4 }} />
								</RowItem>
							</Tooltip>
						</th>
						<th>Current Deposit</th>
						<th>Collateral</th>
					</tr>
				</thead>

				<tbody>{sortedRows}</tbody>
			</Table>
		</TableDiv>
	);
};
